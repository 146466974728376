import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import * as styles from 'styles/components/utils/ErrorBoundary.module.scss';
import Analytics from 'utils/AnalyticsTools';
import { Nullable } from 'utils/TypeUtils';

interface ErrorBoundaryState {
    hasError: boolean;
    unlisten?: Nullable<() => void>;
}

class ErrorBoundary extends React.Component<RouteComponentProps<any>, ErrorBoundaryState> {
    public state: ErrorBoundaryState = {
        hasError: false,
        unlisten: null
    };

    public componentDidMount() {
        const { history } = this.props;
        const { unlisten } = this.state;

        if (!unlisten) {
            const newUnlisten = history.listen(() => {
                this.setState({
                    hasError: false
                });
            });

            this.setState({ unlisten: newUnlisten });
        }
    }

    public componentWillUnmount() {
        if (this.state.unlisten) {
            this.state.unlisten();
        }
    }

    public componentDidCatch(error: Error) {
        this.setState({ hasError: true });
        if (error.stack) {
            Analytics.catchException(error.stack);
        }
    }

    public render() {
        const { hasError } = this.state;
        const { children } = this.props;

        if (hasError) {
            return (
                <section className={ styles.errorBoundary }>
                    <div>
                        <span className={ styles.sorryMessage }>Desculpe, tivemos um problema...</span>
                        <span className={ styles.mainText }>Ocorreu um erro inesperado.</span>
                        <span className={ styles.subText }>Se esse problema persistir, entre em contato com o suporte.</span>
                    </div>
                </section>
            );
        }

        return children;
    }
}

export default withRouter(ErrorBoundary);
