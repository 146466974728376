'use strict';

import { Actions } from 'actions/Actions';
import { Site } from 'models/site/Site';
import { EntityAction, EntityReducer } from 'reducers/Entity';
import { EntityState } from 'states/Entity';

class SitesReducer {

    private entityReducer = new EntityReducer<Site>({
        requestList: Actions.RequestSites,
        receiveList: Actions.ReceiveSites,
        requestGet: Actions.RequestSite,
        receiveGet: Actions.ReceiveSite,
        requestCreate: Actions.RequestCreateSite,
        receiveCreate: Actions.ReceiveCreateSite,
        requestEdit: Actions.RequestEditSite,
        receiveEdit: Actions.ReceiveEditSite,
        requestDelete: Actions.RequestDeleteSite,
        receiveDelete: Actions.ReceiveDeleteSite
    });

    reduce = (previousState = new EntityState<Site>(), action: EntityAction<Site>): EntityState<Site> => {
        switch (action.type) {
            case Actions.RequestSites:
            case Actions.ReceiveSites:
            case Actions.RequestSite:
            case Actions.ReceiveSite:
            case Actions.RequestCreateSite:
            case Actions.ReceiveCreateSite:
            case Actions.RequestEditSite:
            case Actions.ReceiveEditSite:
            case Actions.RequestDeleteSite:
            case Actions.ReceiveDeleteSite:
                return this.entityReducer.reduce(previousState, action);

            case Actions.ReceiveCreateSdp:
                return this.addSdpToSite(previousState, action);

            default:
                return previousState;
        }
    };

    private addSdpToSite(previousState: EntityState<Site>, action: EntityAction<Site>): EntityState<Site> {
        const { id, siteId } = action.state.data;
        const site = previousState.getData(siteId) as Site;
        const nextState = new EntityState<Site>(previousState);

        site.sdps.push(id);
        nextState.setStateData(siteId, site);

        return nextState;
    }

}

export { SitesReducer };
