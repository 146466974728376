import { Actions } from 'actions/Actions';
import EnergyBillAlertList from 'models/bills/EnergyBillAlertList';
import { Action } from 'redux';
import { EntityState } from 'states/Entity';
import { DateTime } from 'utils/DateTime';

class EnergyBillAlertsReducer {
    reduce = (previousState = new EntityState<EnergyBillAlertList[]>(), action: Action): EntityState<EnergyBillAlertList[]> => {
        let nextState: EntityState<EnergyBillAlertList[]>;
        switch (action.type) {
            case Actions.RequestEnergyBillAlerts:
                nextState = this.setFetching(previousState, action);
                return nextState;

            case Actions.ReceiveEnergyBillAlerts:
                nextState = this.setReceived(previousState, action);
                return nextState;

            default: return previousState;
        }
    }

    private setFetching(previousState: EntityState<EnergyBillAlertList[]>, action: Action): EntityState<EnergyBillAlertList[]> {
        const state = new EntityState<EnergyBillAlertList[]>(previousState);
        state.addFetching();

        return state;
    }

    private setReceived(previousState: EntityState<EnergyBillAlertList[]>, action: any): EntityState<EnergyBillAlertList[]> {
        const state = new EntityState<EnergyBillAlertList[]>(previousState);

        state.subtractFetching();
        state.total = action.total;
        state.lastUpdated = new DateTime();

        const sdpState = state.getState(action.sdpId);
        sdpState.lastUpdated = new DateTime();
        const billAlertList = sdpState.data && sdpState.data.find(item => item.billId === action.billId);

        if (billAlertList) {
            billAlertList.alerts = action.items;
        } else {
            const newList: EnergyBillAlertList = {
                billId: action.billId,
                alerts: action.items
            };

            const previousData = sdpState.data || [];

            sdpState.data = [
                ...previousData,
                newList
            ];
        }

        state.setState(action.sdpId, sdpState);

        return state;
    }
}

export default EnergyBillAlertsReducer;
