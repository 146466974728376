import { Actions } from 'actions/Actions';
import { ServerAction } from 'actions/ServerAction';
import { Action, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { AppState } from 'states/App';
import { ApiClient } from 'utils/ApiClient';
import { PasswordChangeRequest } from 'utils/PasswordChangeRequest';

class PasswordActions extends ServerAction {

    public static reset(email: string): ThunkAction<void, AppState, any> {
        return (dispatch: Dispatch<Action, AppState>, getState: () => AppState) => {
            const appState = getState();

            const action = this.requestPasswordReset();
            dispatch(action);

            const dispatchRecoverPassword = () => {
                const receiveAction = this.receivePasswordReset();
                dispatch(receiveAction);
            };

            new ApiClient(appState, dispatch)
                .recoverPassword(email)
                .then(this.handleResponse)
                .then(dispatchRecoverPassword);
        };
    }

    public static change(request: PasswordChangeRequest): ThunkAction<void, AppState, any> {
        return (dispatch: Dispatch<Action, AppState>, getState: () => AppState) => {
            const appState = getState();

            const action = this.requestChangePassword();
            dispatch(action);

            const dispatchChangePassword = () => {
                const receiveAction = this.receiveChangePassword();
                dispatch(receiveAction);
            };

            new ApiClient(appState, dispatch)
                .changePassword(request)
                .then(this.handleResponse)
                .then(dispatchChangePassword);
        };
    }

    private static requestPasswordReset = () => ({ type: Actions.RequestPasswordReset });

    private static receivePasswordReset = () => ({ type: Actions.ReceivePasswordReset });

    private static requestChangePassword = () => ({ type: Actions.RequestChangePassword });

    private static receiveChangePassword = () => ({ type: Actions.ReceiveChangePassword });

}

export { PasswordActions };
