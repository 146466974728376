import { SelectFieldOption } from 'components/interfaces/SelectField';
import { getNumericEnumValues } from 'utils/TypeUtils';

enum EnergyKind {
    Telemetry,
    SCDE,
    Both
};

const getEnergyKindName = (energyKind: EnergyKind) => {
    switch (energyKind) {
        case EnergyKind.Telemetry:
            return 'Telemetria';
        case EnergyKind.SCDE:
            return 'SCDE';
        case EnergyKind.Both:
            return 'Telemetria e SCDE';
    }
};

const stateEnergyKindSelectOptions: SelectFieldOption[] = getNumericEnumValues(EnergyKind).map(energyKind => ({
    text: getEnergyKindName(energyKind),
    value: energyKind
}));

export default EnergyKind;
export {stateEnergyKindSelectOptions, getEnergyKindName};
