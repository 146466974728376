import { isServerError, ServerResponse } from 'models/server/ServerResponse';
import { DataState } from 'states/Data';
import { DataRequest } from 'utils/DataRequest';
import { DateTime } from 'utils/DateTime';

interface ServerErrorResponse {
    text: string;
    status: number;
    errors: string[];
}

class ServerAction {
    protected static shouldRequest(
        state = new DataState(),
        request?: DataRequest
    ): boolean {
        return (
            this.canUpdate(state.lastUpdated) ||
            !this.isSameData(state, request)
        );
    }

    protected static canUpdate(lastUpdated?: DateTime): boolean {
        const now = new DateTime();
        const isInterval = now.getMinutes() % 15 === 0;
        return (
            !lastUpdated ||
            (isInterval && now.differenceInMinutes(lastUpdated) > 1) ||
            now.differenceInMinutes(lastUpdated) > 15
        );
    }

    protected static isSameData<T>(
        state: DataState<T>,
        request?: DataRequest
    ): boolean {
        const hasRequest = !!request;

        const isSameStartDate =
            state.startDate &&
            DateTime.sameDate(state.startDate, request.startDate);

        const isSameEndDate =
            state.endDate && DateTime.sameDate(state.endDate, request.endDate);

        return hasRequest && isSameStartDate && isSameEndDate;
    }

    protected static toDataState<T>(data: T) {
        const state = new DataState<T>();
        state.isFetching = false;
        state.data = data;
        state.lastUpdated = new DateTime();
        return state;
    }

    protected static async handleResponse(
        response: Response
    ): Promise<Response> {
        const { status, ok, statusText } = response;
        if (ok) {
            return response;
        }

        const transformedResponse = await response.json();
        const errors = transformedResponse.errors;

        const error = {
            text: statusText,
            status,
            errors
        };

        throw error;
    }

    protected static handleServerResponse<T>(response: ServerResponse<T>) {
        if (isServerError(response)) {
            const error = JSON.stringify(response.errors?.[0], null, '\t');
            throw new Error(error);
        }

        return response;
    }

    protected static transformToJson(response: Response) {
        return response.json();
    }

    protected static transformToText(response: Response) {
        return response.text();
    }
}

export { ServerAction, ServerErrorResponse };
