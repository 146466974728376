class NumberUtils {
    public static almostEqual(a: number, b: number, epsilon: number): boolean {
        return Math.abs(a - b) < epsilon;
    }

    public static isNumberCoercible(potentialNumber: any): boolean {
        return Number.isFinite(potentialNumber);
    }
}

export default NumberUtils;
