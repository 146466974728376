import { Actions } from 'actions/Actions';
import { AggregatedMeasurements } from 'models/aggregatedMeasurements/AggregatedMeasurements';
import { ForecastMeasurements } from 'models/aggregatedMeasurements/ForecastMeasurements';
import { EntityAction, EntityReducer } from 'reducers/Entity';
import { EntityState } from 'states/Entity';

class AggregatedMeasurementsReducer {

    private entityReducer = new EntityReducer<ForecastMeasurements[]>({
        requestGet: Actions.RequestAggregatedMeasurements,
        receiveGet: Actions.ReceiveAggregatedMeasurements
    });

    public reduce = (
        previousState = new EntityState<AggregatedMeasurements[]>(),
        action: EntityAction<AggregatedMeasurements[]>
    ): EntityState<AggregatedMeasurements[]> => {
        switch (action.type) {
            case Actions.RequestAggregatedMeasurements:
            case Actions.ReceiveAggregatedMeasurements:
                return this.entityReducer.reduce(previousState, action);
            default:
                return previousState;
        }
    };
}

export default AggregatedMeasurementsReducer;
