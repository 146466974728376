import { Actions } from 'actions/Actions';
import { Action } from 'redux';
import { DataState } from 'states/Data';
import { DateTime } from 'utils/DateTime';

interface DataActions {
    request: Actions;
    receive: Actions;
}

class DataReducer<T> {

    private actions: DataActions;

    public constructor(actions: DataActions) {
        this.actions = actions;
    }

    public reduce = (previousState = new DataState<T>(), action: Action): DataState<T> => {
        let nextState;
        switch (action.type) {
            case this.actions.request:
                nextState = this.setAsFetching(previousState, action);
                return nextState;
            case this.actions.receive:
                nextState = this.setData(previousState, action);
                return nextState;
            case Actions.LogOut:
                nextState = this.eraseSession();
                return nextState;
            default:
                return previousState;
        }
    };

    private setAsFetching(previousState: DataState<T>, action: any): DataState<T> {
        const { startDate, endDate } = action;

        const state: DataState<T> = {
            ...previousState,
            isFetching: true,
            startDate: startDate ? startDate as DateTime : undefined,
            endDate: endDate ? endDate as DateTime : undefined
        };

        return state;
    }

    private setData(previousState: DataState<T>, action: any): DataState<T> {
        const state: DataState<T> = {
            ...previousState,
            isFetching: false,
            data: action.data,
            lastUpdated: new DateTime()
        };

        return state;
    }

    private eraseSession() {
        return new DataState<T>();
    }

}

export { DataReducer, DataActions };
