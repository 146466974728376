import { Actions } from 'actions/Actions';
import { getRoleFromValue } from 'models/system/Roles';
import { Middleware } from 'redux';
import { AppState } from 'states/App';
import NumberUtils from 'utils/NumberUtils';

const debounce = require('lodash.debounce');

export type StoredState = Pick<AppState, 'dashboard' | 'config' | 'preferences' | 'user' | 'customizableDashboard' | 'comparison'>;

const nameSpace = 'power-hub';
const storageKey = `${nameSpace}.root`;
const clearStorageAction = Actions.LogOut;

const loadState = (): StoredState | null => {
    const storedState = localStorage.getItem(storageKey);

    if (!storedState) {
        return null;
    }

    const state = JSON.parse(storedState) as StoredState;

    if (NumberUtils.isNumberCoercible(state.user.role)) {
        state.user.role = getRoleFromValue(Number(state.user.role));
    }

    return state;
};

const selectState = ({
    user,
    config,
    dashboard,
    preferences,
    customizableDashboard,
    comparison
}: AppState) => {
    if (NumberUtils.isNumberCoercible(user.role)) {
        user.role = getRoleFromValue(Number(user.role));
    }

    return {
        config,
        dashboard,
        preferences,
        user,
        customizableDashboard,
        comparison
    };
};

const storageMiddleware: Middleware<{}, AppState> = ({ getState }) => next => action => {
    const updateStorage = debounce(() => {
        const state = selectState(getState());
        localStorage.setItem(storageKey, JSON.stringify(state));
    }, 2000);

    if (action.type === clearStorageAction) {
        localStorage.removeItem(storageKey);
    } else {
        updateStorage();
    }

    return next(action);
};

export { loadState, storageMiddleware };

