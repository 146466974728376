import { MenuItem as MuiMenuItem } from 'material-ui/Menu';
import * as React from 'react';

interface MenuItemProps {
    style?: React.CSSProperties;
    className?: string;
    innerDivStyle?: React.CSSProperties;
    label: React.ReactNode;
    value?: any;
    leftIcon?: React.ReactElement<any>;
    rightIcon?: React.ReactElement<any>;
    containerElement?: React.ReactElement<any>;
    disabled?: boolean;
    onClick?: () => void;
    onMouseEnter?: () => void;
    onMouseLeave?: () => void;
}

const MenuItemComponent: React.FunctionComponent<MenuItemProps> = ({
    className,
    label,
    ...restProps
}) => {
    const menuItemClassName = `menu-item ${className || ''}`;

    return (
        <MuiMenuItem
            className={menuItemClassName}
            primaryText={label}
            {...restProps}
        />
    );
};

const MenuItem = React.memo<MenuItemProps>(MenuItemComponent);

export { MenuItem, MenuItemProps };
