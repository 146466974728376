import { FontIcon } from 'components/interfaces/FontIcon';
import { themeable, ThemeableProps } from 'components/interfaces/ThemeProvider';
import MuiIconButton from 'material-ui/IconButton';
import * as React from 'react';

const baseTooltipStyles: React.CSSProperties = {
    fontSize: '14px',
    fontWeight: 'bold'
};

type CornersAndCenter =
    | 'bottom-center'
    | 'bottom-left'
    | 'bottom-right'
    | 'top-center'
    | 'top-left'
    | 'top-right';

interface IconButtonProps extends ThemeableProps {
    type?: string;
    disabled?: boolean;
    tooltip?: string | React.ReactElement<any>;
    tooltipPosition?: CornersAndCenter;
    color?: string;
    hoverColor?: string;
    tabIndex?: number;
    ref?: any;
    className?: string;
    onFocus?: (event: React.FocusEvent) => void;
    disableTouchRipple?: boolean;
    disableFocusRipple?: boolean;
    style?: React.CSSProperties;
    tooltipStyles?: React.CSSProperties;
    onClick?: (e: React.MouseEvent<{}>) => void;
}

class IconButton extends React.Component<IconButtonProps> {
    public render(): React.ReactNode {
        const {
            ref,
            onClick,
            disabled,
            tooltip,
            tooltipPosition,
            type,
            className,
            disableTouchRipple,
            disableFocusRipple,
            tooltipStyles,
            style,
            color,
            hoverColor,
            muiTheme,
            ...restProps
        } = this.props;

        const iconColor = color || muiTheme.palette.primary1Color;
        const iconHoverColor = hoverColor || iconColor;

        const totalTooltipStyles = {
            ...baseTooltipStyles,
            ...tooltipStyles
        };

        const icon = (
            <FontIcon
                type={type}
                color={iconColor}
                hoverColor={iconHoverColor}
            />
        );

        return (
            <MuiIconButton
                className={`${className ? className : ''} icon-button`}
                ref={ref}
                onClick={onClick}
                disabled={disabled}
                style={style}
                tooltip={tooltip}
                tooltipPosition={tooltipPosition}
                tooltipStyles={totalTooltipStyles}
                disableTouchRipple={disableTouchRipple}
                disableFocusRipple={disableFocusRipple}
                {...restProps}
            >
                {icon}
            </MuiIconButton>
        );
    }
}

export default themeable()(IconButton);
export { IconButtonProps };
