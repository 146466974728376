import { Roles } from 'models/system/Roles';

class User {
    public id: string;
    public username: string;
    public password?: string;
    public fullName: string;
    public role: Roles;
    public sites: string[] = [];
    public sitesGroups: string[] = [];

    public static getRoleName(user: User) {
        switch (user.role) {
            case Roles.GlobalAdmin:
                return 'Administrador global';
            case Roles.SubscriptionAdmin:
                return 'Administrador';
            case Roles.AdvancedUser:
                return 'Usuário avançado';
            case Roles.User:
                return 'Usuário';
            default:
                return 'Usuário';
        }
    }
}

interface UserSiteGroups {
    sites: string[];
    groups: string[];
    allSites: string[];
}

export { User, UserSiteGroups };
