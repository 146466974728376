import { PasswordActions } from 'actions/Password';
import { PasswordReset, PasswordResetProps } from 'components/utils/PasswordReset';
import { connect } from 'react-redux';
import { Action, Dispatch } from 'redux';
import { AppState } from 'states/App';
import { ReduxConnectProps } from 'utils/TypeUtils';

interface PasswordResetContainerProps {
    open: boolean;
    onClose: () => void;
}

type MapProps = ReduxConnectProps<PasswordResetProps, PasswordResetContainerProps>;

function mapStateToProps(
    {
        user,
        password
    }: AppState,
    ownProps: PasswordResetContainerProps
): MapProps['MapStateProps'] {
    const {
        open,
        onClose
    } = ownProps;

    const passwordReset = password.isReseting;

    return {
        user,
        passwordReset,
        open,
        onClose
    };
}

function mapDispatchToProps(dispatch: Dispatch<Action, AppState>): MapProps['MapDispatchProps'] {
    return {
        onResetPassword(email: string): void {
            const resetPassword = PasswordActions.reset(email);
            dispatch(resetPassword);
        }
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PasswordReset);
