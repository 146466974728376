import PrivateRoute from 'components/base/PrivateRoute';
import { LinearProgress } from 'components/interfaces/LinearProgress';
import App from 'containers/base/AppContainer';
import { getRoleValue, Roles } from 'models/system/Roles';
import * as React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { UserState } from 'states/User';
import { Routes } from 'utils/Routes';

const { LastLocationProvider } = require('react-router-last-location');

function WaitingComponent({ component: Component }: LazyComponentWithPreload<any>) {
    const waitingComponent = (props: any) => (
        <React.Suspense fallback={<LinearProgress />}>
            <Component {...props} />
        </React.Suspense>
    );

    return React.memo(waitingComponent);
}

interface LazyComponentWithPreload<T extends React.ComponentType<any>> {
    preload: () => Promise<{ default: T }>;
    component: React.LazyExoticComponent<T>;
};
const LazyWithPreload = <T extends React.ComponentType<any>>(factory: () => Promise<{ default: T }>): LazyComponentWithPreload<T> => {
    const safeFactory = () => factory()
        .catch(({ message }) => {
            const chunkRegexp = new RegExp(/^loading( css)? chunk \d+ failed/, 'i');
            if (
                window.location.hash !== '#r' &&
                chunkRegexp.test(message)
            ) {
                window.location.hash = '#r';
                location.reload();
            }
            throw new Error(message);
        });

    return {
        preload: safeFactory,
        component: React.lazy(safeFactory)
    };
};

export const LoginView = LazyWithPreload(() =>
    import('containers/views/LoginViewContainer')
);
export const AboutView = LazyWithPreload(() =>
    import('../views/about')
);
export const PasswordRequestView = LazyWithPreload(() =>
    import('components/views/password/Request')
);
export const PasswordSuccessView = LazyWithPreload(() =>
    import('components/views/password/Success')
);
export const PasswordChangeView = LazyWithPreload(() =>
    import('containers/views/password/Change')
);
export const PasswordRecoveryView = LazyWithPreload(() =>
    import('containers/views/password/Recovery')
);
export const SiteDashboardView = LazyWithPreload(() =>
    import('containers/views/dashboards/DashboardSwitcherContainer')
);
export const CustomDashboardsListView = LazyWithPreload(() =>
    import('containers/views/dashboards/customizable/CustomizableDashboardsListViewContainer')
);
export const CustomDashboardView = LazyWithPreload(() =>
    import('containers/views/dashboards/customizable/CustomizableDashboardViewContainer')
);
export const CustomDashboardETLView = LazyWithPreload(() =>
    import('components/views/dashboards/customizable/CustomizableDashboardETLView')
);
export const CustomizableDashboardViewsTabber = LazyWithPreload(() =>
    import('containers/views/dashboards/customizable/CustomizableDashboardViewsTabberContainer')
);
export const SubscriptionsView = LazyWithPreload(() =>
    import('containers/views/SubscriptionsViewContainer')
);
export const BillsView = LazyWithPreload(() =>
    import('containers/views/bills/BillsViewContainer')
);
export const BillsAuditView = LazyWithPreload(() =>
    import('containers/views/bills/BillsAuditViewContainer')
);
export const ThemesView = LazyWithPreload(() =>
    import('containers/views/ThemesViewContainer')
);
const SitesCreateView = LazyWithPreload(() =>
    import('containers/views/sites/SitesCreateContainer')
);
export const SitesViewTabber = LazyWithPreload(() =>
    import('containers/views/sites/SitesViewTabberContainer')
);
export const SitesGroupView = LazyWithPreload(() =>
    import('containers/views/sites/SitesGroupsViewContainer')
);
export const SiteView = LazyWithPreload(() =>
    import('containers/views/sites/SitesViewContainer')
);
export const UsersCreateView = LazyWithPreload(() =>
    import('containers/views/users/UserCreateViewContainer')
);
export const UsersEditView = LazyWithPreload(() =>
    import('containers/views/users/UserEditViewContainer')
);
export const UsersListView = LazyWithPreload(() =>
    import('containers/views/users/UsersListViewContainer')
);
export const AdministrationView = LazyWithPreload(() =>
    import('components/views/administration/AdministrationView')
);
export const LoadProfileCalendarView = LazyWithPreload(() =>
    import('containers/views/LoadProfileCalendarViewContainer')
);
export const AlarmsViewsTabber = LazyWithPreload(() =>
    import('containers/views/alarms/AlarmsViewsTabberContainer')
);
export const AlarmsOccurrencesView = LazyWithPreload(() =>
    import('containers/views/alarms/AlarmsViewContainer')
);
export const DataExportView = LazyWithPreload(() =>
    import('containers/views/DataExportViewContainer')
);
export const ComparisonView = LazyWithPreload(() =>
    import('containers/views/comparison/ComparisonViewContainer')
);
export const PotentialSavingsListView = LazyWithPreload(() =>
    import('components/views/potentialSavings/PotentialSavingsListView')
);
export const PotentialSavingsSiteView = LazyWithPreload(() =>
    import('components/views/potentialSavings/PotentialSavingsSiteView')
);
export const OverView = LazyWithPreload(() =>
    import('components/views/overview/Overview')
);
export const TaxesPowerBiView = LazyWithPreload(() =>
    import('containers/views/TaxesPowerBi/TaxesPowerBiContainer')
);
export const RemoteTrackingPowerBi = LazyWithPreload(() =>
    import('containers/views/RemoteTrackingPowerBi/RemoteTrackingBiContainer')
);


interface RouterProps {
    user: UserState;
    subscription: string;
    onLogout: () => any;
    subscriptionId?: string;
    toggleStateGraphql: boolean;
}

class Router extends React.Component<RouterProps> {
    public render(): React.ReactNode {
        const { user, subscription, onLogout, subscriptionId, toggleStateGraphql } = this.props;

        const { isAuthenticated, token } = user;
        const hasSubscription = !!subscription;
        const auth = { user, isAuthenticated, hasSubscription, token };
        const RedirectView = this.getRedirectView();

        const LogoutRedirect = () => {
            onLogout();
            return <RedirectView />;
        };

        const alarmsRoute = user.isAuthenticated &&
            getRoleValue(Roles.AdvancedUser) <= getRoleValue(user.role) ? (
            <PrivateRoute
                {...auth}
                requiredRole={Roles.AdvancedUser}
                path={[
                    `/${subscription}/${Routes.AlarmsConfigs}`,
                    `/${subscription}/${Routes.Alarms}`
                ]}
                subscription={subscription}
                component={WaitingComponent(AlarmsViewsTabber)}
                subscriptionId={subscriptionId}
                onLogout={onLogout}
                toggleStateGraphql={toggleStateGraphql}
            />
        ) : (
            <PrivateRoute
                {...auth}
                requiredRole={Roles.User}
                path={`/${subscription}/${Routes.Alarms}`}
                subscription={subscription}
                component={WaitingComponent(AlarmsOccurrencesView)}
                subscriptionId={subscriptionId}
                onLogout={onLogout}
                toggleStateGraphql={toggleStateGraphql}
            />
        );


        const customDashboardsRoute = user.isAuthenticated ? (
            <PrivateRoute
                {...auth}
                requiredRole={Roles.User}
                path={[
                    `/${subscription}/${Routes.CustomDashboardETLList}`,
                    `/${subscription}/${Routes.CustomDashboardList}`
                ]}
                subscription={subscription}
                component={WaitingComponent(CustomizableDashboardViewsTabber)}
                subscriptionId={subscriptionId}
                onLogout={onLogout}
                toggleStateGraphql={toggleStateGraphql}
            />
        ) : null;

        return (
            <BrowserRouter>
                <LastLocationProvider>
                    <Switch>
                        {/* Remember to cascade from inner views to main views  */}

                        <Route
                            exact={true}
                            path={`/${Routes.Login}`}
                            component={WaitingComponent(LoginView)}
                        />
                        <Route
                            exact={true}
                            path={`/${Routes.Logout}`}
                            render={LogoutRedirect}
                        />
                        <Route
                            exact={true}
                            path={`/${Routes.PasswordRecovery}`}
                            component={WaitingComponent(PasswordRecoveryView)}
                        />
                        <Route
                            exact={true}
                            path={`/${Routes.PasswordRequest}`}
                            component={WaitingComponent(PasswordRequestView)}
                        />
                        <Route
                            exact={true}
                            path={`/${Routes.PasswordSuccess}`}
                            component={WaitingComponent(PasswordSuccessView)}
                        />
                        <Route
                            exact={true}
                            path={`/${Routes.PasswordChange}`}
                            component={WaitingComponent(PasswordChangeView)}
                        />
                        <PrivateRoute
                            {...auth}
                            requiredRole={Roles.User}
                            path={`/${Routes.Subscriptions}`}
                            subscription={subscription}
                            component={WaitingComponent(SubscriptionsView)}
                            subscriptionId={subscriptionId}
                            onLogout={onLogout}
                            toggleStateGraphql={toggleStateGraphql}
                        />
                        <App>
                            <Switch>
                                <PrivateRoute
                                    {...auth}
                                    requiredRole={Roles.GlobalAdmin}
                                    path={`/${Routes.Administration}`}
                                    subscription={subscription}
                                    component={WaitingComponent(AdministrationView)}
                                    subscriptionId={subscriptionId}
                                    onLogout={onLogout}
                                    toggleStateGraphql={toggleStateGraphql}
                                />
                                {alarmsRoute}
                                <PrivateRoute
                                    {...auth}
                                    requiredRole={Roles.User}
                                    path={`/${subscription}/${Routes.LoadProfileCalendar}`}
                                    subscription={subscription}
                                    component={WaitingComponent(LoadProfileCalendarView)}
                                    subscriptionId={subscriptionId}
                                    onLogout={onLogout}
                                    toggleStateGraphql={toggleStateGraphql}
                                />
                                <PrivateRoute
                                    {...auth}
                                    requiredRole={Roles.GlobalAdmin}
                                    path={`/${subscription}/${Routes.SitesCreate}`}
                                    subscription={subscription}
                                    component={WaitingComponent(SitesCreateView)}
                                    subscriptionId={subscriptionId}
                                    onLogout={onLogout}
                                    toggleStateGraphql={toggleStateGraphql}
                                />
                                <PrivateRoute
                                    {...auth}
                                    requiredRole={Roles.User}
                                    path={`/${subscription}/${Routes.SitesView}`}
                                    subscription={subscription}
                                    component={WaitingComponent(SiteView)}
                                    subscriptionId={subscriptionId}
                                    onLogout={onLogout}
                                    toggleStateGraphql={toggleStateGraphql}
                                />
                                <PrivateRoute
                                    {...auth}
                                    requiredRole={Roles.SubscriptionAdmin}
                                    path={`/${subscription}/${Routes.SitesGroupCreate}`}
                                    subscription={subscription}
                                    component={WaitingComponent(SitesGroupView)}
                                    subscriptionId={subscriptionId}
                                    onLogout={onLogout}
                                    toggleStateGraphql={toggleStateGraphql}
                                />
                                <PrivateRoute
                                    {...auth}
                                    requiredRole={Roles.User}
                                    path={`/${subscription}/${Routes.SitesGroupsView}`}
                                    subscription={subscription}
                                    component={WaitingComponent(SitesGroupView)}
                                    subscriptionId={subscriptionId}
                                    onLogout={onLogout}
                                    toggleStateGraphql={toggleStateGraphql}
                                />
                                <PrivateRoute
                                    {...auth}
                                    requiredRole={Roles.User}
                                    path={[
                                        `/${subscription}/${Routes.SitesList}`,
                                        `/${subscription}/${Routes.SitesGroupsList}`
                                    ]}
                                    subscription={subscription}
                                    component={WaitingComponent(SitesViewTabber)}
                                    subscriptionId={subscriptionId}
                                    onLogout={onLogout}
                                    toggleStateGraphql={toggleStateGraphql}
                                />
                                <PrivateRoute
                                    {...auth}
                                    requiredRole={Roles.User}
                                    path={`/${subscription}/${Routes.Dashboard}`}
                                    subscription={subscription}
                                    component={WaitingComponent(SiteDashboardView)}
                                    subscriptionId={subscriptionId}
                                    onLogout={onLogout}
                                    toggleStateGraphql={toggleStateGraphql}
                                />
                                <PrivateRoute
                                    {...auth}
                                    requiredRole={Roles.User}
                                    path={`/${Routes.AboutPage}`}
                                    component={WaitingComponent(AboutView)}
                                    subscription={subscription}
                                    subscriptionId={subscriptionId}
                                    onLogout={onLogout}
                                    toggleStateGraphql={toggleStateGraphql}
                                />
                                <PrivateRoute
                                    {...auth}
                                    requiredRole={Roles.User}
                                    path={`/${subscription}/${Routes.CustomDashboardETL}`}
                                    subscription={subscription}
                                    component={WaitingComponent(CustomDashboardETLView)}
                                    subscriptionId={subscriptionId}
                                    onLogout={onLogout}
                                    toggleStateGraphql={toggleStateGraphql}
                                />
                                <PrivateRoute
                                    {...auth}
                                    requiredRole={Roles.User}
                                    path={`/${subscription}/${Routes.CustomDashboard}`}
                                    subscription={subscription}
                                    component={WaitingComponent(CustomDashboardView)}
                                    subscriptionId={subscriptionId}
                                    onLogout={onLogout}
                                    toggleStateGraphql={toggleStateGraphql}
                                />
                                {customDashboardsRoute}
                                <PrivateRoute
                                    {...auth}
                                    requiredRole={Roles.User}
                                    path={`/${subscription}/${Routes.Bills}`}
                                    subscription={subscription}
                                    component={WaitingComponent(BillsView)}
                                    subscriptionId={subscriptionId}
                                    onLogout={onLogout}
                                    toggleStateGraphql={toggleStateGraphql}
                                />
                                <PrivateRoute
                                    {...auth}
                                    requiredRole={Roles.SubscriptionAdmin}
                                    path={`/${subscription}/${Routes.BillsAudit}`}
                                    subscription={subscription}
                                    component={WaitingComponent(BillsAuditView)}
                                    subscriptionId={subscriptionId}
                                    onLogout={onLogout}
                                    toggleStateGraphql={toggleStateGraphql}
                                />
                                <PrivateRoute
                                    {...auth}
                                    requiredRole={Roles.User}
                                    path={`/${subscription}/${Routes.DataExport}`}
                                    subscription={subscription}
                                    component={WaitingComponent(DataExportView)}
                                    subscriptionId={subscriptionId}
                                    onLogout={onLogout}
                                    toggleStateGraphql={toggleStateGraphql}
                                />
                                <PrivateRoute
                                    {...auth}
                                    requiredRole={Roles.User}
                                    path={`/${subscription}/${Routes.Comparison}`}
                                    subscription={subscription}
                                    component={WaitingComponent(ComparisonView)}
                                    subscriptionId={subscriptionId}
                                    onLogout={onLogout}
                                    toggleStateGraphql={toggleStateGraphql}
                                />
                                <PrivateRoute
                                    {...auth}
                                    requiredRole={Roles.SubscriptionAdmin}
                                    path={`/${subscription}/${Routes.UsersEdit}`}
                                    subscription={subscription}
                                    component={WaitingComponent(UsersEditView)}
                                    subscriptionId={subscriptionId}
                                    onLogout={onLogout}
                                    toggleStateGraphql={toggleStateGraphql}
                                />
                                <PrivateRoute
                                    {...auth}
                                    requiredRole={Roles.SubscriptionAdmin}
                                    path={`/${subscription}/${Routes.UsersCreate}`}
                                    subscription={subscription}
                                    component={WaitingComponent(UsersCreateView)}
                                    subscriptionId={subscriptionId}
                                    onLogout={onLogout}
                                    toggleStateGraphql={toggleStateGraphql}
                                />
                                <PrivateRoute
                                    {...auth}
                                    requiredRole={Roles.SubscriptionAdmin}
                                    path={`/${subscription}/${Routes.UsersIndex}`}
                                    subscription={subscription}
                                    component={WaitingComponent(UsersListView)}
                                    subscriptionId={subscriptionId}
                                    onLogout={onLogout}
                                    toggleStateGraphql={toggleStateGraphql}
                                />
                                <PrivateRoute
                                    {...auth}
                                    requiredRole={Roles.SubscriptionAdmin}
                                    path={`/${subscription}/${Routes.Themes}`}
                                    subscription={subscription}
                                    component={WaitingComponent(ThemesView)}
                                    subscriptionId={subscriptionId}
                                    onLogout={onLogout}
                                    toggleStateGraphql={toggleStateGraphql}
                                />
                                <PrivateRoute
                                    {...auth}
                                    requiredRole={Roles.SubscriptionAdmin}
                                    subscription={subscription}
                                    path={`/${subscription}/${Routes.PotentialSavingsView}`}
                                    component={WaitingComponent(PotentialSavingsSiteView)}
                                    subscriptionId={subscriptionId}
                                    onLogout={onLogout}
                                    toggleStateGraphql={toggleStateGraphql}
                                />
                                <PrivateRoute
                                    {...auth}
                                    requiredRole={Roles.SubscriptionAdmin}
                                    subscription={subscription}
                                    path={`/${subscription}/${Routes.PotentialSavingsList}`}
                                    component={WaitingComponent(PotentialSavingsListView)}
                                    subscriptionId={subscriptionId}
                                    onLogout={onLogout}
                                    toggleStateGraphql={toggleStateGraphql}
                                />
                                <PrivateRoute
                                    {...auth}
                                    requiredRole={Roles.SubscriptionAdmin || Roles.GlobalAdmin}
                                    path={`/${subscription}/${Routes.RemoteTrackingPowerBi}`}
                                    subscription={subscription}
                                    component={WaitingComponent(RemoteTrackingPowerBi)}
                                    subscriptionId={subscriptionId}
                                    onLogout={onLogout}
                                    toggleStateGraphql={toggleStateGraphql}
                                />
                                <PrivateRoute
                                    {...auth}
                                    requiredRole={Roles.User}
                                    subscription={subscription}
                                    component={RedirectView}
                                    subscriptionId={subscriptionId}
                                    onLogout={onLogout}
                                    toggleStateGraphql={toggleStateGraphql}
                                />
                            </Switch>
                        </App>
                    </Switch>
                </LastLocationProvider>
            </BrowserRouter>
        );
    }

    private getRedirectView(): React.StatelessComponent {
        const { subscription } = this.props;
        return () => <Redirect to={`/${subscription}/${Routes.SitesList}`} />;
    }
}

export { Router, RouterProps };
