import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from 'components/interfaces//IconButton';
import { Paper } from 'components/interfaces/Paper';
import { TextField } from 'components/interfaces/TextField';
import { SiteAutoCompleteContainerProps } from 'containers/utils/SiteAutoCompleteContainer';
import * as React from 'react';
import { FixedSizeList } from 'react-window';
import * as styles from 'styles/components/interfaces/AutoComplete.module.scss';
import EventUtils from 'utils/EventUtils';
import { useInput } from 'utils/hooks/FormHooks';
import { Key } from 'utils/Key';
import StringUtils from 'utils/StringUtils';

interface AutoCompleteProps extends SiteAutoCompleteContainerProps {
    dataSource: DataSourceItem[];
    onSelect: (id: string) => void;
    hintText: string;
    className: string;
    inputContainerClassName?: string;
    searchText: string;
    leftIcon: React.ReactNode;
    subscription: string;
    leftIconOi: React.ReactNode;
    inputContainerOi?: string;
    styleContainerOi?: React.CSSProperties;
}

interface DataSourceItem {
    text: string;
    value: string;
}

interface ListItemProps extends DataSourceItem {
    onSelect: AutoCompleteProps['onSelect'];
    style: React.CSSProperties;
}

interface VirtualizedListProps {
    list: DataSourceItem[];
    onSelectItem: (value: string) => void;
}

const AutoCompleteItem = ({ value, text, onSelect, style}: ListItemProps) => {
    const listItemClasses = {
        root: styles.listItem
    };

    const listItemTextClasses = {
        primary: styles.listItemText
    };

    const boundOnClick = () => {
        onSelect(value);
    };

    const handleKeyUp = (e: React.KeyboardEvent<HTMLElement>) => {
        if (e.key === Key.Enter) {
            boundOnClick();
        }
    };

    return (
        <ListItem
            style={style}
            key={value}
            button={true}
            role="button"
            onKeyUp={handleKeyUp}
            dense={true}
            classes={listItemClasses}
            onMouseDown={boundOnClick}
            ContainerComponent="div"
        >
            <ListItemText title={text} classes={listItemTextClasses} primary={text} />
        </ListItem>
    );
};

const Row = ({
    data: { list, onSelectItem },
    index,
    style
}: {
    data: VirtualizedListProps;
    index: number;
    style: React.CSSProperties;
}) => {
    const { text, value } = list[index];
    return (
        <AutoCompleteItem
            text={text}
            style={style}
            value={value}
            onSelect={onSelectItem}
        />
    );
};

const VirtualizedList = ({ list, onSelectItem }: VirtualizedListProps) => {
    const itemData = { list, onSelectItem };
    const itemHeight = 36;
    const height = itemHeight * list.length;

    const listOuterElementFunction = (props: {
        children: React.ReactNode;
    }, ref: (instance: HTMLDivElement) => void) => (
        <div ref={ref} tabIndex={-1} {...props}/>
    );

    const ListOuterElement = React.forwardRef<HTMLDivElement>(listOuterElementFunction);

    return (
        <Paper className={styles.listDiv}>
            <FixedSizeList
                outerElementType={ListOuterElement}
                className={styles.virtualizedList}
                height={height}
                itemCount={list.length}
                itemSize={itemHeight}
                itemData={itemData}
                width={300}
            >
                {Row}
            </FixedSizeList>
        </Paper>
    );
};

const AutoComplete = ({
    dataSource,
    leftIcon,
    searchText,
    onSelect,
    className,
    inputContainerClassName = '',
    subscription,
    leftIconOi,
    inputContainerOi,
    styleContainerOi
}: AutoCompleteProps) => {
    const {errorText, setError, setValue: setSearchTextProps, ...searchTextProps} = useInput(searchText);
    const oldSearchText = searchText;
    const [focus, setFocus] = React.useState(false);
    const timer = React.useRef<number | null>(null);
    const copyTooltipText = 'Copiar nome do site';
    const [copyTooltip, setCopyTooltip] = React.useState(copyTooltipText);

    React.useEffect(() => {
        setSearchTextProps(searchText);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchText]);

    const handleFocus = (shouldFocus: boolean) => () => {
        if (timer.current) {
            clearTimeout(timer.current);
        }

        if (shouldFocus) {
            if (searchText === searchTextProps.value) {
                setSearchTextProps('');
            }
        } else {
            setSearchTextProps(oldSearchText);
        }
        setFocus(shouldFocus);
    };

    const handleCopy = async () => {
        await navigator.clipboard.writeText(searchText);
        setCopyTooltip('Copiado!');
    };

    const handleTooltipBlur = () => {
        setCopyTooltip(copyTooltipText);
    };

    const renderFilter = (
        <div className={styles.textFieldContainer}>
            <TextField
                className={styles.searchTextField}
                type="text"
                autoFocus={false}
                name="searchText"
                placeholder="Ponto de medição"
                autoComplete="off"
                {...searchTextProps}
            />
            <div
                onClick={handleCopy}
                onBlur={handleTooltipBlur}
            >
                <IconButton
                    tabIndex={-1}
                    onFocus={EventUtils.preventDefault}
                    type="clone"
                    className={styles.copyButton}
                    tooltip={copyTooltip}
                    tooltipPosition="bottom-left"
                />
            </div>
        </div>
    );

    const onSelectItem = (value: string) => {
        setSearchTextProps(value);
        onSelect(value);
    };

    const handleBlur = () => {
        if (timer?.current) {
            clearTimeout(timer.current);
        }
        timer.current = window.setTimeout(() => {
            handleFocus(false)();
        }, 0);
    };

    const list = dataSource.filter(({ text }) => StringUtils.textMatch(text, searchTextProps.value));
    const isSubscriptionOi = subscription === 'oi' || subscription === 'oi-treinamento';

    const shouldRenderList = list.length > 0 && focus;
    const virtualizedList = shouldRenderList ? (
        <VirtualizedList
            list={list}
            onSelectItem={onSelectItem}
        /> ) : null;

    const hideSubscriptionOi = isSubscriptionOi ? (
        <div className={inputContainerOi} style={styleContainerOi}>
            {searchText}
        </div>
    ) : (
        <div
            className={inputContainerClassName}
            onBlur={handleBlur}
            onFocus={handleFocus(true)}
        >
            {renderFilter}
            {virtualizedList}
        </div>
    );

    const iconOi = isSubscriptionOi ? leftIconOi : null;

    return (
        <div className={className}>
            {leftIcon}
            {hideSubscriptionOi}
            {iconOi}
        </div>
    );
};

export default AutoComplete;
export { AutoCompleteProps, DataSourceItem };
