import { User } from 'models/system/User';

class CurrentUser extends User {
    public subscriptionId: string;
    public subscriptionName: string;
    public settings: any;
    public token: string;
}

export { CurrentUser };
