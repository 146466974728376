import { QueryLazyOptions, useMutation, useQuery } from '@apollo/react-hooks';
import NewExperiencePopUp from 'components/base/NewExperiencePopUp';
import TopBar from 'components/base/TopBar';
import WarningPopUp from 'components/base/WarningPopUp';
import NavigationMenu from 'containers/base/NavigationMenuContainer';
import { Site } from 'models/site/Site';
import { Roles } from 'models/system/Roles';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { UserState } from 'states/User';
import * as styles from 'styles/components/base/App.module.scss';
import Analytics from 'utils/AnalyticsTools';
import useSubscription from 'utils/hooks/useSubscription';
import { UsersVariables } from 'utils/mutations/gqlTypes/Users';
import MutationToggleNewExperience from 'utils/mutations/ToggleNewExperienceMutation';
import GetNewExperienceToggle from 'utils/queries/GetNewExperienceToggle';
import GetSubscriptionBasicInfo from 'utils/queries/GetSubscriptionBasicInfo';
import { NewExperienceIsActivated } from 'utils/queries/gqlTypes/NewExperienceIsActivated';
import { SubscriptionBasicInfo, SubscriptionBasicInfoVariables } from 'utils/queries/gqlTypes/SubscriptionBasicInfo';
import StringUtils from 'utils/StringUtils';
import { isNonNullable } from 'utils/TypeUtils';


interface AppProps extends RouteComponentProps<any> {
    user: UserState;
    site: Site | undefined;
    hideMenu: boolean;
    children?: React.ReactNode;
}

interface StateProps {
    state: {
        siteName: '';
    };
}

const ViewPanelRefContext = React.createContext<React.RefObject<HTMLDivElement> | null>(null);

const App = ({ hideMenu, user, location, site, children }: AppProps) => {
    const viewPanelRef = React.useRef(null);
    const subscription = StringUtils.toHyphenCase(user.subscriptionName || '');
    const { hasFinishPHContract } = useSubscription();

    const siteName = site?.name;
    const siteId = site?.id;

    React.useEffect(() => {
        if (isNonNullable(siteId)) {
            const accessedSite = location.pathname.includes(siteId) ? siteName : undefined;
            Analytics.pageview(location.pathname, user, accessedSite);

            if (user) {
                window.gtag('set', 'user_properties', {
                /* eslint-disable @typescript-eslint/camelcase */
                  user_role: user.role,
                  custom_user_id: user.id,
                  subscription: user.subscriptionName,
                  subscription_id: user.subscriptionId
                });
            }

            window.gtag('config', 'G-LBNCZVMLKL', {
                page_path: location.pathname,
                user_id: user.id
            });
        }
    }, [location.pathname, user, siteId, siteName]);

    const siteNameForced = location.state;

    const isAmbientAllowed = process.env.FLUX_TRANSITION_ALLOWED === 'true';

    const { data, loading } = useQuery<NewExperienceIsActivated>(
        GetNewExperienceToggle, {
            variables: {},
            errorPolicy: 'ignore',
            partialRefetch: true,
            skip: !user.subscriptionId || !isAmbientAllowed
        }
    );

    const { data: subBasicInfoData } = useQuery<SubscriptionBasicInfo, SubscriptionBasicInfoVariables>(
        GetSubscriptionBasicInfo, {
            variables: {
                subscriptionId: user.subscriptionId
            },
            errorPolicy: 'ignore',
            partialRefetch: true,
            skip: !user.subscriptionId || !isAmbientAllowed
        }
    );

    const [toggleNewExperienceMutation] = useMutation(MutationToggleNewExperience, {
        refetchQueries: [{
            query: GetNewExperienceToggle,
            variables: {}
        }],
        awaitRefetchQueries: true
    });

    const handleRedirect = async (variables: QueryLazyOptions<UsersVariables>) => {
        const mutationData = await toggleNewExperienceMutation(variables);
        const isToggled = mutationData?.data?.users?.toggleNewExperienceUse ?? false;
        if (isToggled) {
            const currentPath = location.pathname.split('/').filter(Boolean);

            if (currentPath[1] === 'meus-paineis') {
                window.open('/sites', '_self');
            }
        }
    };

    const toggleState = data?.users?.userActivatedNewExperienceUse ?? false;
    const isSubAllowedNewExperience = subBasicInfoData?.subscriptionBasicInfo?.isNewExperiencieAllowed ?? false;
    const subId = user.subscriptionId;
    const isGlobalAdmin = user.role === Roles.GlobalAdmin;
    const hasFinishContract = hasFinishPHContract(user.subscriptionId);

    return (
        <section className={styles.app}>
            <NavigationMenu
                pathname={location.pathname}
                menuHidden={hideMenu}
            />
            <div className={styles.contentArea}>
                <TopBar
                    subscription={subscription}
                    siteNameForced={siteNameForced}
                    handleRedirect={handleRedirect}
                    toggleLoading={loading}
                    toggleState={toggleState}
                    toggleAllowed={isSubAllowedNewExperience}
                    subscriptionId={subId}
                    isGlobalAdmin={isGlobalAdmin}
                />
                {hasFinishContract && <WarningPopUp />}
                {!toggleState && isSubAllowedNewExperience && isAmbientAllowed && !isGlobalAdmin && (
                    <NewExperiencePopUp
                        handleRedirect={handleRedirect}
                        subscriptionId={subId}
                    />
                )}
                <ViewPanelRefContext.Provider value={viewPanelRef}>
                    <div className={styles.viewPanel} ref={viewPanelRef}>
                        {children}
                    </div>
                </ViewPanelRefContext.Provider>
            </div>
        </section>
    );
};

export default App;
export { AppProps, ViewPanelRefContext };

