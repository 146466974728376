import PromiseUtils from 'utils/PromiseUtils';

interface Hotjar {
    globals?: {
        get: (key: string) => string | undefined;
    };
}

declare global {
    interface Window {
        hj: Hotjar;
    }
}

const Hotjar = PromiseUtils.getGlobalProperty('hj');

export default Hotjar;
