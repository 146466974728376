class ObjectUtils {

    static shallowEquals(objA: {[k: string]: any}, objB: {[k: string]: any}): boolean {
        const isEqual = (x: any, y: any): boolean => {
            if (x === y) {
                return x !== 0 || y !== 0 || 1 / x === 1 / y;
            } else {
                return x !== x && y !== y;
            }
        };

        if (objA === objB) {
            return true;
        }

        if (typeof objA !== 'object' || objA === null ||
            typeof objB !== 'object' || objB === null) {
            return false;
        }

        const aKeys = Object.keys(objA);
        const bKeys = Object.keys(objB);

        if (aKeys.length !== bKeys.length) {
            return false;
        }

        for (let key in objA) {
            if (!(objB.hasOwnProperty(key)) ||
                !isEqual(objA[key], objB[key])) {
                return false;
            }
        }

        return true;
    }

    static deepCopy<T>(object: T): T {
        return JSON.parse(JSON.stringify(object));
    }

}

export default ObjectUtils;
