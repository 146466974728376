import App, { AppProps } from 'components/base/App';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { AppState } from 'states/App';

function mapStateToProps(
    appState: AppState,
    ownProps: RouteComponentProps<any>
): AppProps {
    const {
        dashboard,
        preferences,
        sites,
        user
    } = appState;

    const hideMenu = !preferences.menuOpen;
    const site = sites.getData(dashboard.id);

    return {
        user,
        hideMenu,
        site,
        ...ownProps
    };
}

export default withRouter(connect(mapStateToProps)(App));
