'use strict';

import * as React from 'react';

import MuiPaper from 'material-ui/Paper';

export interface PaperProps {
    children?: any;
    depth?: number;
    className?: string;
}

export const Paper = (props: PaperProps) => (
    <MuiPaper className={`paper ${props.className}`} zDepth={props.depth} >
        {props.children}
    </MuiPaper>
);
