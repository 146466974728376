
function useSubscription() {
  const subscriptionsFinishedContract = [
    'aba885b5-73a1-4055-8754-d7155a1ef1a2', // W-Energy
    '937f6a14-2023-4f9e-8d57-970da77eba42' // Santander
  ];

  const hasFinishPHContract = (subscriptionId: string) => {
    const findedSub = subscriptionsFinishedContract.find(item => item === subscriptionId);
    return !!findedSub?.length;
  };

  return {
    hasFinishPHContract
  };

}

export default useSubscription;