import NotificationsActions from 'actions/NotificationsActions';
import NotificationManager, { NotificationManagerProps } from 'components/base/NotificationManager';
import { connect } from 'react-redux';
import { Action, Dispatch } from 'redux';
import { AppState } from 'states/App';


function mapStateToProps(appState: AppState): NotificationManagerProps {
    const notifications = appState.notifications.notifications || [];
    return { notifications };
}

function mapDispatchToProps(dispatch: Dispatch<Action, AppState>): NotificationManagerProps {
    const onNotificationClose = () => {
        const removeLastNotification = NotificationsActions.removeLastNotification();
        dispatch(removeLastNotification);
    };

    return { onNotificationClose };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NotificationManager);
