import { Tooltip } from '@material-ui/core';
import {
    AdministrationView,
    AlarmsOccurrencesView,
    AlarmsViewsTabber,
    BillsAuditView,
    BillsView,
    ComparisonView,
    CustomDashboardView,
    DataExportView,
    LoadProfileCalendarView,
    OverView,
    PotentialSavingsListView,
    RemoteTrackingPowerBi,
    SiteDashboardView,
    SitesViewTabber,
    SubscriptionsView,
    TaxesPowerBiView,
    ThemesView,
    UsersListView
} from 'components/base/Router';
import { Drawer } from 'components/interfaces/Drawer';
import { FontIcon } from 'components/interfaces/FontIcon';
import { Menu } from 'components/interfaces/Menu';
import { MenuItem } from 'components/interfaces/MenuItem';
import { themeable, ThemeableProps } from 'components/interfaces/ThemeProvider';
import InlineSVG from 'components/utils/InlineSVG';
import Profile from 'components/utils/Profile';
import { red300 } from 'material-ui/styles/colors';
import FollowUpIcon from 'material-ui/svg-icons/action/account-balance';
import Dashboard from 'material-ui/svg-icons/action/dashboard';
import Info from 'material-ui/svg-icons/action/info';
import SwapHorizontal from 'material-ui/svg-icons/action/swap-horiz';
import Alert from 'material-ui/svg-icons/alert/warning';
import Monetization from 'material-ui/svg-icons/editor/monetization-on';
import Palette from 'material-ui/svg-icons/image/palette';
import { fade } from 'material-ui/utils/colorManipulator';
import { getRoleValue, Roles } from 'models/system/Roles';
import * as React from 'react';
import posed from 'react-pose';
import { NavLink } from 'react-router-dom';
import { UserState } from 'states/User';
import * as styles from 'styles/components/base/NavigationMenu.module.scss';
import { Routes } from 'utils/Routes';

const auditSVG = require('../../../images/icons/audit.svg');
const domainSVG = require('../../../images/icons/domain.svg');

enum IconType {
    FontAwesome,
    SVGString,
    ReactComponent
}

interface NavigationOption {
    label: string;
    icon: string | React.ComponentType<any>;
    iconType?: IconType;
    accessLevel: Roles;
    allowedSubscriptions?: string[];
    blockedSubscriptions?: string[];
    pathname?: string;
    pathnameAliases?: string[];
    onClick?: () => void;
    onHover?: () => void;
}

interface NavigationMenuProps extends ThemeableProps {
    user: UserState;
    subscription: string;
    pathname: string;
    mobile: boolean;
    open: boolean;
    hasDashboard: boolean;
    hasMultipleSites: boolean;
    dashboardSite: string;
    customizableDashboardId: string;
    menuHidden: boolean;
    onChangeMenu: () => void;
    onChangeSubscription: () => void;
    onLoadSites: () => void;
    onLoadSitesPagination: () => void;
    onClearSelectedBill: () => void;
}

const openWidth = '230px';
const closedWidth = '70px';

const PosedDiv = posed.div({
    open: {
        width: openWidth,
        transition: {
            duration: 70
        },
        flip: true
    },
    closed: {
        width: closedWidth,
        transition: {
            duration: 70
        },
        flip: true
    }
});

class NavigationMenu extends React.Component<NavigationMenuProps> {
    public componentDidMount() {
        const { user, onLoadSites, open, subscription, onLoadSitesPagination } = this.props;

        this.setMenuWidthProperty(open);

        if (user.isAuthenticated) {
            if (subscription === 'oi' || subscription === 'oi-treinamento') {
                onLoadSitesPagination();
            } else {
                onLoadSites();
            }
        }
    }

    public render() {
        const { open, mobile, onChangeMenu, user, menuHidden } = this.props;

        const mainOptions = this.createMainNavigationItems();
        const userOptions = this.createUserNavigationItems();

        const mainMenuItems = mainOptions.map(this.toMenuItem);
        const userMenuItems = userOptions.map(this.toMenuItem);

        const firstAreaStyle: React.CSSProperties = {
            borderBottom: this.getBorderStyle()
        };

        const lastAreaStyle: React.CSSProperties = {
            borderTop: this.getBorderStyle()
        };

        const baseClassName = styles.navigationMenu;
        const hiddenClassName = menuHidden ? styles.menuHidden : '';
        const mobileClassName = mobile ? styles.menuMobile : '';
        const className = `${baseClassName} ${hiddenClassName} ${mobileClassName}`;
        const poseStatus = open ? 'open' : 'closed';

        return (
            <Drawer
                className={className}
                open={open}
                mobile={mobile}
                onRequestChange={onChangeMenu}
            >
                <PosedDiv
                    className={styles.menuContentContainer}
                    pose={poseStatus}
                    onPoseComplete={this.onMenuResize}
                >
                    <header className={styles.header} style={firstAreaStyle}>
                        <Profile user={user} className={styles.profile} />
                    </header>
                    <nav className={styles.main}>
                        <Menu>{mainMenuItems}</Menu>
                    </nav>
                    <footer className={styles.footer} style={lastAreaStyle}>
                        <Menu>{userMenuItems}</Menu>
                    </footer>
                </PosedDiv>
            </Drawer>
        );
    }

    private setMenuWidthProperty(isOpen: boolean) {
        const { mobile } = this.props;

        const width = mobile ? '0px' : isOpen ? openWidth : closedWidth;

        document.documentElement.style.setProperty(
            '--navigation-menu-width',
            width
        );
    }

    private onMenuResize = (pose: 'open' | 'closed') => {
        this.setMenuWidthProperty(pose === 'open');
        dispatchEvent(new Event('resize'));
    };

    private createMainNavigationItems(): NavigationOption[] {
        const {
            subscription,
            hasDashboard,
            dashboardSite,
            customizableDashboardId,
            hasMultipleSites,
            onClearSelectedBill
        } = this.props;

        const items: NavigationOption[] = [
            {
                label: 'Sites/Grupos',
                icon: 'map-marker',
                accessLevel: Roles.User,
                pathname: `/${subscription}/${Routes.SitesList}`,
                pathnameAliases: [`/${subscription}/${Routes.SitesGroupsList}`],
                onClick: () => onClearSelectedBill(),
                onHover: () => {
                    SitesViewTabber.preload();
                }
            }
        ];

        if (hasDashboard) {
            items.push(
                {
                    label: 'Dashboard',
                    icon: 'bar-chart',
                    accessLevel: Roles.User,
                    blockedSubscriptions: ['oi', 'oi-treinamento', 'transpetro'],
                    pathname: `/${subscription}/${Routes.Dashboard.replace(
                        ':id',
                        dashboardSite
                    )}`,
                    onHover: () => {
                        SiteDashboardView.preload();
                    }
                },
                {
                    label: 'Faturas',
                    icon: 'file-text',
                    accessLevel: Roles.User,
                    blockedSubscriptions: ['cmu'],
                    pathname: `/${subscription}/${Routes.Bills.replace(
                        ':id',
                        dashboardSite
                    )}`,
                    onHover: () => {
                        BillsView.preload();
                    }
                },
                {
                    label: 'Auditoria de faturas',
                    icon: auditSVG,
                    iconType: IconType.SVGString,
                    accessLevel: Roles.GlobalAdmin,
                    blockedSubscriptions: ['oi', 'oi-treinamento'],
                    pathname: `/${subscription}/${Routes.BillsAudit}`,
                    onHover: () => {
                        BillsAuditView.preload();
                    }
                },
                {
                    label: 'Exportação de dados',
                    icon: 'download',
                    accessLevel: Roles.User,
                    blockedSubscriptions: ['oi', 'oi-treinamento', 'transpetro'],
                    pathname: `/${subscription}/${Routes.DataExport}`,
                    onHover: () => {
                        DataExportView.preload();
                    }
                },
                {
                    label: 'Perfil calendário',
                    icon: 'calendar',
                    accessLevel: Roles.User,
                    blockedSubscriptions: ['oi', 'oi-treinamento', 'transpetro'],
                    pathname: `/${subscription}/${Routes.LoadProfileCalendar.replace(
                        ':id',
                        dashboardSite
                    )}`,
                    onHover: () => {
                        LoadProfileCalendarView.preload();
                    }
                },
                {
                    label: 'Alarmes',
                    icon: 'bell',
                    accessLevel: Roles.User,
                    blockedSubscriptions: ['oi', 'oi-treinamento', 'transpetro'],
                    pathname: `/${subscription}/${Routes.Alarms.slice(
                        0,
                        Routes.Alarms.lastIndexOf('/:date')
                    )}`,
                    onHover: () => {
                        AlarmsViewsTabber.preload();
                        AlarmsOccurrencesView.preload();
                    }
                },
                {
                    label: 'Meus painéis',
                    icon: Dashboard,
                    iconType: IconType.ReactComponent,
                    accessLevel: Roles.User,
                    blockedSubscriptions: ['oi', 'oi-treinamento', 'transpetro'],
                    pathname: `/${subscription}/${Routes.CustomDashboard.replace(
                        ':id',
                        customizableDashboardId
                    )}`,
                    pathnameAliases: [`/${subscription}/${Routes.CustomDashboardETLList}`],
                    onHover: () => {
                        CustomDashboardView.preload();
                    }
                },
                {
                    label: 'Potenciais de ganho',
                    icon: Monetization,
                    iconType: IconType.ReactComponent,
                    accessLevel: Roles.SubscriptionAdmin,
                    blockedSubscriptions: ['transpetro'],
                    pathname: `/${subscription}/${Routes.PotentialSavingsList}`,
                    onHover: () => {
                        PotentialSavingsListView.preload();
                    }
                },
                {
                    label: 'Acompanhamento de Remotas',
                    icon: FollowUpIcon,
                    iconType: IconType.ReactComponent,
                    accessLevel: Roles.SubscriptionAdmin || Roles.GlobalAdmin,
                    allowedSubscriptions: [
                        'perfil-energia'
                    ],
                    pathname: `/${subscription}/${Routes.RemoteTrackingPowerBi}`,
                    onHover: () => {
                        RemoteTrackingPowerBi.preload();
                    }
                }
            );

            if (hasMultipleSites) {
                items.push({
                    label: 'Comparações',
                    icon: 'line-chart',
                    iconType: IconType.FontAwesome,
                    accessLevel: Roles.User,
                    allowedSubscriptions: ['cencosud', 'demonstracao'],
                    pathname: `/${subscription}/${Routes.Comparison}`,
                    onHover: () => {
                        ComparisonView.preload();
                    }
                });
            }
        }

        return items;
    }

    private createUserNavigationItems(): NavigationOption[] {
        const { subscription, onChangeSubscription } = this.props;

        return [
            {
                label: 'Usuários',
                icon: 'group',
                accessLevel: Roles.SubscriptionAdmin,
                pathname: `/${subscription}/${Routes.UsersIndex}`,
                blockedSubscriptions: ['power-demo'],
                onHover: () => {
                    UsersListView.preload();
                }
            },
            {
                label: 'Temas',
                icon: Palette,
                iconType: IconType.ReactComponent,
                accessLevel: Roles.SubscriptionAdmin,
                pathname: `/${subscription}/${Routes.Themes}`,
                blockedSubscriptions: ['power-demo'],
                onHover: () => {
                    ThemesView.preload();
                }
            },
            {
                label: 'Administração',
                icon: 'cogs',
                accessLevel: Roles.GlobalAdmin,
                pathname: `/${Routes.Administration}`,
                onHover: () => {
                    AdministrationView.preload();
                }
            },
            {
                label: 'Trocar assinatura',
                icon: SwapHorizontal,
                iconType: IconType.ReactComponent,
                accessLevel: Roles.GlobalAdmin,
                pathname: `/${Routes.Subscriptions}`,
                onClick: () => onChangeSubscription(),
                onHover: () => {
                    SubscriptionsView.preload();
                }
            },
            {
                label: 'Sair',
                icon: 'sign-out',
                accessLevel: Roles.User,
                pathname: `/${Routes.Logout}`
            },
            {
                label: 'Sobre a PowerHub',
                icon: Info,
                iconType: IconType.ReactComponent,
                accessLevel: Roles.User,
                pathname: `/${Routes.AboutPage}`
            }
        ];
    }

    private toMenuItem = (navigationItem: NavigationOption, index: number) => {
        if (this.shouldHide(navigationItem)) {
            return false;
        }

        const {accessLevel} = navigationItem;

        const isGlobalAdminItem = accessLevel === Roles.GlobalAdmin;

        const innerDivStyle = this.getMenuInnerDivStyle(navigationItem);

        const iconStyle = this.getFontIconStyle();
        const SVGIconStyle = {
            ...iconStyle,
            fill: innerDivStyle.color
        };

        const handleItemClick = this.handleClick.bind(this, navigationItem);

        let icon: React.ReactElement<any>;

        switch (navigationItem.iconType) {
            case IconType.ReactComponent:
                icon = <navigationItem.icon style={SVGIconStyle} />;
                break;
            case IconType.SVGString:
                icon = (
                    <InlineSVG
                        svgContent={navigationItem.icon as string}
                        style={SVGIconStyle}
                    />
                );
                break;
            case IconType.FontAwesome:
            default:
                icon = (
                    <FontIcon
                        type={navigationItem.icon as string}
                        color={innerDivStyle.color}
                        style={iconStyle}
                        className={styles.fontIcon}
                    />
                );
                break;
        }

        const itemLabel = (
            <span className={styles.text}>{navigationItem.label}</span>
        );

        let adminIcon: React.ReactElement<any> | undefined;

        if (isGlobalAdminItem) {
            const style = {fontSize: '0.8em'};
            const labelStyle = {
                tooltip: styles.adminTooltip
            };

            adminIcon = (
                <Tooltip
                    title="Item disponível apenas para global admins"
                    placement="right"
                    classes={labelStyle}
                >
                    <span className={styles.adminIcon}>
                        <FontIcon
                            type="circle"
                            color={red300}
                            style={style}
                        />
                    </span>
                </Tooltip>
            );
        }

        let hoverTimeout: number;
        const {onHover} = navigationItem;
        const onMouseEnter = () => {
            if (onHover) {
                hoverTimeout = window.setTimeout(onHover, 100);
            }
        };
        const onMouseLeave = () => window.clearTimeout(hoverTimeout);

        const menuItem = (
            <MenuItem
                className={styles.menuItem}
                label={itemLabel}
                value={navigationItem.pathname}
                onClick={handleItemClick}
                leftIcon={icon}
                rightIcon={adminIcon}
                innerDivStyle={innerDivStyle}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
            />
        );

        return (
            <NavLink to={navigationItem.pathname || ''} key={index}>
                {menuItem}
            </NavLink>
        );
    };

    private handleClick(navigationOption: NavigationOption) {
        if (navigationOption.onClick) {
            navigationOption.onClick();
        }
        if (this.props.mobile) {
            this.props.onChangeMenu();
        }
    }

    private getMenuInnerDivStyle(
        navigationItem: NavigationOption
    ): React.CSSProperties {
        const backgroundColor = this.getBackgroundColor(
            navigationItem.pathname,
            navigationItem.pathnameAliases
        );
        const color = this.getTextColor(
            navigationItem.pathname,
            navigationItem.pathnameAliases
        );

        return {
            backgroundColor,
            color,
            lineHeight: '1.5em',
            padding: '0.5em 1em 0.5em 0.5em',
            minHeight: '64px',
            boxSizing: 'border-box',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
        };
    }

    private getFontIconStyle(): React.CSSProperties {
        return {
            width: '24px',
            position: 'relative',
            top: 0,
            left: 0,
            flexShrink: 0
        };
    }

    private shouldHide(navigationItem: NavigationOption) {
        const { user, subscription } = this.props;

        const {
            accessLevel,
            allowedSubscriptions,
            blockedSubscriptions
        } = navigationItem;

        const invalidRole = getRoleValue(accessLevel) > getRoleValue(user.role);

        const notAllowedSubscription =
            allowedSubscriptions &&
            !allowedSubscriptions.some(
                allowedSubscription =>
                    subscription.toUpperCase() ===
                    allowedSubscription.toUpperCase()
            );

        const blockedSubscription =
            blockedSubscriptions?.some(
                invalidSubscription =>
                    subscription.toUpperCase() ===
                    invalidSubscription.toUpperCase()
            );

        return invalidRole || notAllowedSubscription || blockedSubscription;
    }

    private isActive = (itemPathname: string | undefined): boolean => {
        const { pathname } = this.props;

        if (!itemPathname) {
            return false;
        }

        const lowerPathname = pathname.toLowerCase();
        const lowerItemPathname = itemPathname.toLowerCase();

        return lowerPathname.includes(lowerItemPathname);
    };

    private getBorderStyle(): string {
        const color = fade(this.props.muiTheme.palette.primary3Color, 0.5);
        return `solid 1px ${color}`;
    }

    private getTextColor(
        pathname: string | undefined,
        pathnameAliases: string[] = []
    ): string {
        const { muiTheme } = this.props;
        const someActive =
            this.isActive(pathname) || pathnameAliases.some(this.isActive);
        return pathname && someActive
            ? muiTheme.palette.accent2Color
            : muiTheme.palette.alternateMenuTextColor;
    }

    private getBackgroundColor(
        pathname: string | undefined,
        pathnameAliases: string[] = []
    ): string | undefined {
        const someActive =
            this.isActive(pathname) || pathnameAliases.some(this.isActive);
        return pathname && someActive
            ? this.props.muiTheme.palette.primary3Color
            : undefined;
    }
}

export default themeable()(NavigationMenu);

export { NavigationOption, NavigationMenuProps };

