import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { ApolloLink, Observable, Operation } from 'apollo-link';
import { ErrorResponse, onError } from 'apollo-link-error';
import { HttpLink } from 'apollo-link-http';
import { ServerError, ServerParseError } from 'apollo-link-http-common';
import { UserToken } from 'models/system/UserToken';
import { setLocalStorageToken } from 'reducers/UserReducer';
import { StatusCode } from 'utils/StatusCode';

const request = (operation: Operation) => {
    const token = localStorage.getItem('token');
    if (token === null) {
        console.error('Token not found in localStorage');
        return;
    }

    operation.setContext({
        headers: {
            authorization: `Bearer ${token}`
        }
    });
};

const requestLink = new ApolloLink((operation, forward) => new Observable(observer => {
    let handle: ZenObservable.Subscription;
    Promise.resolve(operation)
        .then(request)
        .then(() => {
            handle = forward(operation).subscribe({
                next: observer.next.bind(observer),
                error: observer.error.bind(observer),
                complete: observer.complete.bind(observer)
            });
        })
        .catch(observer.error.bind(observer));

    return () => {
        if (handle) {
            handle.unsubscribe();
        }
    };
}));

const promiseToObservable = (promise: Promise<any>) => new Observable((subscriber: any) => {
    promise.then(
        value => {
            if (subscriber.closed) {
                return;
            }
            subscriber.next(value);
            subscriber.complete();
        },
        err => subscriber.error(err)
    );
});

const onRefreshToken = async (baseUrl: string, operation: Operation) => {
    const {headers} = operation.getContext() || {headers: {}};
    const refreshUrl = `${baseUrl}/api/renew-token`;
    const options: RequestInit = {
        method: 'POST',
        mode: 'cors',
        headers: {
            Authorization: headers,
            'Content-Type': 'application/json'
        }
    };

    const tokenResponse = await fetch(refreshUrl, options);

    if (tokenResponse.ok) {
        const userToken: UserToken = await tokenResponse.json();
        const {token} = userToken;

        setLocalStorageToken(token);
    }
};

const isServerError = (networkError: ErrorResponse['networkError']): networkError is ServerError | ServerParseError =>
    (networkError as ServerError | ServerParseError).statusCode !== undefined;


const createApolloClient = (baseUrl: string, fakeGraphQLUrl?: string) => {
    if (fakeGraphQLUrl) {
        console.warn(`Using fakeGraphQL on ${fakeGraphQLUrl}`);
    }

    const apolloUrl = fakeGraphQLUrl || baseUrl;

    const apolloUri = `${apolloUrl}/graphql`;

    const handleErrors = onError(({ graphQLErrors, networkError, forward, operation }) => {
        if (graphQLErrors) {
            graphQLErrors.forEach(({ message, locations, path }) =>
                console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
            );
        }
        if (networkError) {
            console.error(`[Network error]: ${networkError}`);
            if (isServerError(networkError)) {
                if (networkError.statusCode === StatusCode.Unauthorized) {
                    return promiseToObservable(onRefreshToken(baseUrl, operation)).flatMap(() => forward(operation));
                }
            }
        }
    });

    const httpLink = new HttpLink({ // TODO: Use apollo-link-batch-http when graphql-dotnet releases batching https://github.com/graphql-dotnet/server/pull/241
        uri: apolloUri,
        credentials: 'same-origin'
    });

    const cache = new InMemoryCache();

    return new ApolloClient({
        link: ApolloLink.from([
            handleErrors,
            requestLink,
            httpLink
        ]),
        cache
    });
};

export {createApolloClient};
