import { DateTime } from 'utils/DateTime';

class StringComparers {
    public static lexicalCompare = (a: string, b: string): number =>
        a.localeCompare(b);

    public static dateTimeCompare = (a: string, b: string): number => {
        if (a === '-') {
            return -1;
        }

        const aDateTime = new DateTime(a, 'DD/MM/YYYY HH:mm');
        const bDateTime = new DateTime(b, 'DD/MM/YYYY HH:mm');

        return aDateTime.compareTo(bDateTime);
    };

    public static dateCompare = (a: string, b: string) => {
        const aDateTime = new DateTime(a, 'DD/MM/YYYY');
        const bDateTime = new DateTime(b, 'DD/MM/YYYY');

        return aDateTime.compareTo(bDateTime);
    };

    public static hourCompare = (a: string, b: string): number => {
        const aDateTime = new DateTime(a, 'HH:mm');
        const bDateTime = new DateTime(b, 'HH:mm');

        return aDateTime.compareTo(bDateTime);
    };

    public static formattedNumberCompare = (a: string, b: string): number => {
        function toNumber(formattedNumber: string) {
            return parseFloat(
                formattedNumber
                    .split(' ')[0]
                    .replace(/\./g, '')
                    .replace(/,/g, '.')
            );
        }

        return toNumber(a) - toNumber(b);
    };

    public static numberCompare = (a: string, b: string): number =>
        parseInt(a, 10) - parseInt(b, 10);
}

export default StringComparers;
