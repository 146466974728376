export type Nullable<T> = T | null | undefined;
export type ArrayNullable<T> = T extends any[] ? Nullable<T[any]>[] : never;

export type RequiredNonNullable<T> = T extends object ? { [P in keyof T]-?: NonNullable<T[P]> } : T;

export type FunctionKeys<T> = {
    [K in keyof RequiredNonNullable<T>]: RequiredNonNullable<T>[K] extends Function ? K : never
}[keyof T];

export type WithRequired<T, K extends keyof T> = Required<Pick<T, K>> & Omit<T, K>;

export const isNonNullable = <T>(value: T): value is NonNullable<T> => value !== null && value !== undefined;
export const isNullable = (value: any): value is null | undefined => value === null || value === undefined;

export const getNumericEnumValues = <
    T,
    V extends T extends string ? never : T
>(enumerator: { [s: string]: T }): V[] =>
    Object.values(enumerator).filter((value): value is V => typeof value !== 'string');

export const noop = (): void => undefined;

export type ReduxConnectProps<ComponentProps extends {}, OwnProps extends {} = {}> = ReturnType<<
    FK extends FunctionKeys<Omit<ComponentProps, keyof OwnProps>>
>() => {
    MapStateProps: Omit<ComponentProps, FK>;
    MapDispatchProps: Pick<ComponentProps, FK>;
}>;
