import { themeable, ThemeableProps } from 'components/interfaces/ThemeProvider';
import InlineSVG from 'components/utils/InlineSVG';
import * as React from 'react';
import * as styles from 'styles/components/utils/Logo.module.scss';

const logoSvg = require('../../../images/logo.svg');

interface LogoProps extends ThemeableProps {
    className?: string;
}

class Logo extends React.Component<LogoProps> {
    public render() {
        const { className = ''} = this.props;

        const titleStyle = this.getTitleStyle();
        const SvgStyle = this.getSvgStyle();
        const divClassName = `${styles.logo} ${className}`;

        return (
            <div className={divClassName}>
                <InlineSVG
                    style={SvgStyle}
                    svgContent={logoSvg}
                    className={styles.image}
                />
                <h1
                    style={titleStyle}
                    className={styles.text}
                >
                    PowerHub
                </h1>
            </div>
        );
    }

    private getTitleStyle(): React.CSSProperties {
        return {
            color: this.props.muiTheme.palette.primary1Color
        };
    }

    private getSvgStyle(): React.CSSProperties {
        return {
            fill: this.props.muiTheme.palette.accent2Color,
            width: '32px',
            height: '32px',
        };
    }
}

export default themeable()(Logo);
export { LogoProps };
