'use strict';

import * as React from 'react';

import MuiFontIcon from 'material-ui/FontIcon';

export interface FontIconProps {
    type: string;
    color?: string;
    hoverColor?: string;
    style?: React.CSSProperties;
    className?: string;
}

export const FontIcon = (props: FontIconProps) => (
    <MuiFontIcon
        className={`fa fa-${props.type} ${props.className || ''}`}
        style={props.style}
        color={props.color}
        hoverColor={props.hoverColor}
    />
);
