import PromiseUtils from 'utils/PromiseUtils';

interface ConpassCustomFields {
    userId: string;
    role: string;
    subscription: string;
    ip: any;
}

interface ConpassUser {
    name: string;
    email: string;
    customFields: ConpassCustomFields;
}

interface Conpass {
    init: (user: ConpassUser) => void;
    debug: () => void;
    startFlow: (id: string) => void;
}

declare global {
    interface Window {
        Conpass: Conpass;
    }
}

const Conpass = PromiseUtils.getGlobalProperty('Conpass');

export default Conpass;
