import { EnergyBill } from 'models/bills/EnergyBill';
import { DataState } from 'states/Data';
import { Nullable } from 'utils/TypeUtils';

class ExpensesToolState {
    public sdpId: Nullable<string>;
    public startDate: string;
    public endDate: string;
    public bills = new DataState<EnergyBill[]>();
    public previousBills = new DataState<EnergyBill[]>();

    public bill: DataState<EnergyBill> | undefined = new DataState<EnergyBill>();

    public isUploading = false;
    public uploaded = false;

    public isSimulating = false;
}

export default ExpensesToolState;
