import { ExpensesToolActions } from 'actions/ExpensesToolActions';
import { PreferencesActions } from 'actions/Preferences';
import { SiteAction } from 'actions/SiteActions';
import { UserActions } from 'actions/UserActions';
import NavigationMenu from 'components/base/NavigationMenu';
import { connect } from 'react-redux';
import { Action, Dispatch } from 'redux';
import { AppState } from 'states/App';
import MobileDetector from 'utils/MobileDetector';
import StringUtils from 'utils/StringUtils';
import { ReduxConnectProps } from 'utils/TypeUtils';

interface NavigationMenuContainerProps {
    pathname: string;
    menuHidden: boolean;
}

type MapProps = ReduxConnectProps<React.ComponentProps<typeof NavigationMenu>>;

function mapStateToProps(
    appState: AppState,
    ownProps: NavigationMenuContainerProps
): MapProps['MapStateProps'] {
    const {
        user,
        customizableDashboard,
        dashboard,
        preferences,
        sites
    } = appState;

    const dashboardSite = dashboard.id ?? '';
    const subscriptionName = user.subscriptionName || '';
    const subscription = StringUtils.toHyphenCase(subscriptionName);
    const customizableDashboardId = customizableDashboard
        ? customizableDashboard.id
        : '';
    const open = preferences.menuOpen;
    const mobile = MobileDetector.isMobile();
    const hasDashboard = sites.total > 0 || sites.totalOld > 0;
    const hasMultipleSites = sites.total > 1 || sites.totalOld > 1;

    return {
        ...ownProps,
        user,
        subscription,
        mobile,
        open,
        dashboardSite,
        customizableDashboardId,
        hasDashboard,
        hasMultipleSites
    };
}

function mapDispatchToProps(
    dispatch: Dispatch<Action, AppState>
): MapProps['MapDispatchProps'] {
    return {
        onChangeSubscription(): void {
            const unsetSubscription = UserActions.setSubscription({
                id: null,
                name: null,
                settings: null
            });
            dispatch(unsetSubscription);
        },
        onChangeMenu(): void {
            const toggleMenu = PreferencesActions.toggleMenu();
            dispatch(toggleMenu);
        },
        onLoadSites(): void {
            const getSiteList = SiteAction.list();
            const getSelf = UserActions.getUserSelf();

            dispatch(getSiteList);
            dispatch(getSelf);
        },
        onLoadSitesPagination(): void {
            const getSiteList = SiteAction.listPagination('', 1, 25, 'name', 'asc');
            const getSelf = UserActions.getUserSelf();

            dispatch(getSiteList);
            dispatch(getSelf);
        },
        onClearSelectedBill(): void {
            const clearSelected = ExpensesToolActions.clearSelectedBill();

            dispatch(clearSelected);
        }
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NavigationMenu);
