import 'core-js/stable';
import 'react-hot-loader';
import 'regenerator-runtime/runtime';
import 'whatwg-fetch';

import { ApolloProvider } from '@apollo/react-components';
import AppHolder from 'components/base/AppHolder';
import ThemeProvider from 'containers/base/ThemeProviderContainer';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';
import thunkMiddleware from 'redux-thunk';
import { loadState, storageMiddleware } from 'session';
import { ConfigState } from 'states/Config';
import AppStore from 'store';
import { createApolloClient } from 'utils/ApolloClient';

const appElement = document.getElementById('app');

const { url } = appElement?.dataset || { url: null };

const config = new ConfigState();
const baseUrl = url || process.env.BASE_URL || '';
const fakeGraphQLUrl = process.env.FAKER_GQL_URL;

if (process.env.NODE_ENV === 'development') {
    if (!baseUrl) {
        console.error('No BASE_URL defined in .env or in #app[data-url]');
    }
}

config.baseUrl = baseUrl;

const store = createStore(
    AppStore,
    {
        ...loadState(),
        config
    },
    composeWithDevTools(
        applyMiddleware.apply(this, [thunkMiddleware, storageMiddleware])
    )
);

const apolloClient = createApolloClient(baseUrl, fakeGraphQLUrl);

ReactDOM.render(
    <ApolloProvider client={apolloClient}>
        <ReduxProvider store={store}>
            <ThemeProvider>
                <AppHolder />
            </ThemeProvider>
        </ReduxProvider>
    </ApolloProvider>,
    appElement
);
