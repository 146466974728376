import { DateTime } from 'utils/DateTime';

class DataState<T> {
    public isDeleting = false;
    public isEditing = false;
    public isFetching = true;

    public didInvalidate = false;
    public lastUpdated?: DateTime;
    public startDate?: DateTime;
    public endDate?: DateTime;

    public data: T;

    public constructor(data: T = null) {
        this.data = data;
    }

}

export { DataState };
