import { Actions } from 'actions/Actions';
import { Action } from 'redux';
import { ListMode } from 'utils/ListMode';

interface PreferencesAction extends Action {
    listMode: ListMode;
}

class PreferencesActions {

    public static changeSiteListMode(listMode: ListMode): PreferencesAction {
        return { type: Actions.ChangeSiteListMode, listMode };
    }

    public static changeUserListMode(listMode: ListMode): PreferencesAction {
        return { type: Actions.ChangeUserListMode, listMode };
    }

    public static toggleMenu(): Action {
        return { type: Actions.ToggleMenu };
    }

}

export {
    PreferencesAction,
    PreferencesActions
};
