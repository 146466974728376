import { SelectFieldOption } from 'components/interfaces/SelectField';

enum TimePeriod {
    Day = 'day',
    Month = 'month',
    Year = 'year'
}

const timePeriodToList = (includeYear = false): TimePeriod[] => {
    const list = [TimePeriod.Day, TimePeriod.Month];
    if (includeYear) {
        list.push(TimePeriod.Year);
    }

    return list;
};

const getTimePeriodLabel = (type: TimePeriod): string => {
    switch (type) {
        case TimePeriod.Day:
            return 'Diário';
        case TimePeriod.Month:
            return 'Mensal';
        case TimePeriod.Year:
            return 'Anual';
        default:
            console.error(`TimePeriod.getTimePeriodLabel: TimePeriod ${type} is unknown`);
            return '';
    }
};

const timePeriodToOptions = (includeYear = false): SelectFieldOption[] =>
    timePeriodToList(includeYear).map(timePeriodToSelectFieldOption);

const timePeriodToSelectFieldOption = (period: TimePeriod): SelectFieldOption =>
    ({ text: getTimePeriodLabel(period), value: period });

export {
    TimePeriod,
    timePeriodToOptions,
    timePeriodToSelectFieldOption,
    getTimePeriodLabel
};

