import { Actions } from 'actions/Actions';
import { Action } from 'redux';
import { UserState } from 'states/User';

function setLocalStorageToken(token: string): void {
    localStorage.setItem('token', token);
}

function unsetLocalStorageToken(): void {
    localStorage.removeItem('token');
}

class UserReducer {
    public reduce = (previousState = new UserState(), action: Action): UserState => {
        switch (action.type) {
            case Actions.LoadSession:
                setLocalStorageToken((action as any).state.user.token);
                return this.loadSession(action);
            case Actions.RequestLogIn:
                return this.setLogging(previousState);
            case Actions.ReceiveUserSelf:
                return this.setUserSettings(previousState, action);
            case Actions.ReceiveLogIn:
                setLocalStorageToken((action as any).user.token);
                return this.setCurrentUser(previousState, action);
            case Actions.FailLogIn:
                return this.setError(previousState);
            case Actions.SetUserToken:
                setLocalStorageToken((action as any).token);
                return this.setUserToken(previousState, action);
            case Actions.SetUserSubscription:
                return this.setSubscription(previousState, action);
            case Actions.ReceiveUserColorPalette:
                return this.setColorPalette(previousState, action);
            case Actions.LogOut:
                unsetLocalStorageToken();
                return this.eraseSession();
            default:
                return previousState;
        }
    };

    private loadSession(action: any): UserState {
        return action.state.user || new UserState();
    }

    private eraseSession(): UserState {
        return new UserState();
    }

    private setLogging(previousState: UserState): UserState {
        const state: UserState = {
            ...previousState,
            isLogging: true,
            hasError: false
        };

        return state;
    }

    private setCurrentUser(previousState: UserState, action: any): UserState {
        const state: UserState = {
            ...previousState,
            ...action.user,
            isLogging: false,
            isAuthenticated: true,
            hasError: false
        };

        return state;
    }

    private setUserSettings(previousState: UserState, action: any): UserState {
        const actionSettings = action.user.settings;
        const state: UserState = {
            ...previousState,
            settings: actionSettings || previousState.settings
        };

        return state;
    }

    private setError(previousState: UserState): UserState {
        const state: UserState = {
            ...previousState,
            hasError: true,
            isLogging: false
        };

        return state;
    }

    private setUserToken(previousState: UserState, action: any): UserState {
        const state: UserState = {
            ...previousState,
            token: action.token
        };

        return state;
    }

    private setSubscription(previousState: UserState, action: any): UserState {
        const state: UserState = {
            ...previousState,
            subscriptionId: action.subscriptionId,
            subscriptionName: action.subscriptionName,
            settings: action.settings
        };

        return state;
    }

    private setColorPalette(previousState: UserState, action: any): UserState {
        const state: UserState = {
            ...previousState,
            settings: {
                colorPalette: action.colorPalette
            }
        };

        return state;
    }

}

export { UserReducer, setLocalStorageToken, unsetLocalStorageToken };
