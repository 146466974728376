import { MuiTheme } from 'material-ui/styles';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import muiThemeable from 'material-ui/styles/muiThemeable';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import * as React from 'react';

interface ThemeProviderProps {
    theme: MuiTheme;
    children: React.ReactNode;
}

const ThemeProvider = ({ theme, children }: ThemeProviderProps) => (
    <MuiThemeProvider muiTheme={theme}>{children}</MuiThemeProvider>
);

interface Palette {
    primary1Color: string;
    primary2Color: string;
    primary3Color: string;
    primary4Color: string;
    accent1Color: string;
    accent2Color: string;
    accent3Color: string;
    textColor: string;
    secondaryTextColor: string;
    alternateTextColor: string;
    alternateMenuTextColor: string;
    canvasColor: string;
    borderColor: string;
    disabledColor: string;
    pickerHeaderColor: string;
    clockCircleColor: string;
    shadowColor: string;
    topBarColor: string;
}

interface Theme extends MuiTheme {
    name: string;
    palette: Palette;
}

interface ThemeableProps {
    muiTheme: Theme;
}

type ThemeableComponent<T extends ThemeableProps> = React.ComponentType<T>;
type ThemedComponent<T extends ThemeableProps> = React.ComponentType<Omit<T, keyof ThemeableProps>>;

const themeable = muiThemeable as () => <T extends ThemeableProps>(component: ThemeableComponent<T>) => ThemedComponent<T>;

const createTheme = (name: string, palette: Palette) => {
    const {
        primary1Color,
        primary2Color,
        primary4Color,
        accent1Color,
        accent2Color,
        alternateTextColor,
        canvasColor
    } = palette;

    const muiThemeOptions: Theme = {
        name,
        palette,
        drawer: {
            width: 230,
            color: primary2Color
        },
        flatButton: {
            textColor: primary1Color
        },
        toggle: {
            thumbOffColor: primary1Color,
            thumbOnColor: accent1Color,
            trackOffColor: primary4Color,
            trackOnColor: primary2Color
        },
        datePicker: {
            headerColor: primary1Color
        }
    };

    document.documentElement.style.setProperty(
        '--theme-primary-1-color',
        primary1Color
    );
    document.documentElement.style.setProperty(
        '--theme-primary-2-color',
        primary2Color
    );
    document.documentElement.style.setProperty(
        '--theme-accent-1-color',
        accent1Color
    );
    document.documentElement.style.setProperty(
        '--theme-accent-2-color',
        accent2Color
    );
    document.documentElement.style.setProperty(
        '--theme-alternate-text-color',
        alternateTextColor
    );
    document.documentElement.style.setProperty(
        '--theme-canvas-color',
        canvasColor
    );

    return getMuiTheme(muiThemeOptions);
};

export {
    ThemeProviderProps,
    ThemeProvider,
    Palette,
    Theme,
    ThemeableProps,
    themeable,
    createTheme
};
