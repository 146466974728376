import { DataState } from 'states/Data';
import { DateTime } from 'utils/DateTime';

interface StateWithId<T> {
    id: string;
    state: DataState<T>;
}

interface Entity {
    id: string;
}

class EntityState<T> {
    public total = 0;
    public totalOld = 0;
    public isCreating = false;
    public hasError = false;
    public lastUpdated?: DateTime | null = null;
    public pageIndex = 0;
    public pageSize = 0;

    private didInvalidate = false;
    private states: { [id: string]: DataState<T> } = {};
    private howManyFetching = 0;

    public constructor(previousState?: EntityState<T>) {
        if (previousState) {
            this.isCreating = previousState.isCreating;
            this.howManyFetching = previousState.howManyFetching;
            this.didInvalidate = previousState.didInvalidate;
            this.lastUpdated = previousState.lastUpdated;
            this.states = previousState.states;
            this.total = previousState.total;
            this.pageIndex = previousState.pageIndex;
            this.pageSize = previousState.pageSize;
            this.totalOld = previousState.totalOld;
        }

        this.hasError = false;
    }

    public isFetching(): boolean {
        return this.howManyFetching > 0;
    }

    public addFetching(): number {
        return ++this.howManyFetching;
    }

    public subtractFetching(): number {
        this.howManyFetching = Math.max(0, --this.howManyFetching);
        return this.howManyFetching;
    }

    public listData = (): T[] => Object.keys(this.states).map(key => this.states[key].data);

    public getData = (id: string): T | null => this.states[id] ? this.states[id].data : null;

    public listStates(): StateWithId<T>[] {
        return Object.keys(this.states).map(key => ({
            id: key,
            state: this.states[key]
        }));
    }

    public getState(id: string): DataState<T> {
        return this.states[id] || new DataState<T>();
    }

    public removeState(id: string): void {
        delete this.states[id];
    }

    public setStates(items: DataState<T & Entity>[]): void {
        const oldStates = this.states;
        this.states = {};

        items.forEach(
            item =>
                (this.states[item.data.id] = {
                    ...oldStates[item.data.id],
                    ...item
                })
        );
    }

    public addStates(items: DataState<T & Entity>[]): void {
        const newStates = {
            ...this.states
        };

        items.forEach(
            item =>
                (newStates[item.data.id] = {
                    ...this.states[item.data.id],
                    ...item
                })
        );

        this.states = newStates;
    }

    public setState(id: string, item: DataState<T>): void {
        this.states[id] = item;
    }

    public setStateAsFetching(id: string): void {
        this.checkState(id);
        this.states[id].isDeleting = false;
        this.states[id].isEditing = false;
        this.states[id].isFetching = true;
    }

    public setStateAsDeleting(id: string): void {
        this.checkState(id);
        this.states[id].isDeleting = true;
        this.states[id].isEditing = false;
        this.states[id].isFetching = false;
    }

    public setStateAsEditing(id: string, data: T): void {
        const oldData = this.getData(id);

        const newData =
            oldData instanceof Array
                ? ([...(oldData as any[]), ...(data as any)] as any)
                : { ...(oldData as {}), ...(data as {}) };

        this.checkState(id);
        this.setStateData(id, newData);

        this.states[id].isDeleting = false;
        this.states[id].isEditing = true;
        this.states[id].isFetching = false;
    }

    public setStateAsDone(id: string): void {
        this.checkState(id);
        this.states[id].isDeleting = false;
        this.states[id].isEditing = false;
        this.states[id].isFetching = false;
    }

    public setStateData(id: string, data: T): void {
        this.states[id].data = data;
    }

    private checkState(id: string): void {
        const doesntHaveState = !this.states[id];

        if (doesntHaveState) {
            this.states[id] = new DataState<T>();
        }
    }
}

export { EntityState, StateWithId, Entity };
