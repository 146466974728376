import MuiFlatButton from 'material-ui/FlatButton';
import * as React from 'react';

interface FlatButtonProps {
    type: string;
    icon?: React.ReactNode;
    style?: React.CSSProperties;
    label?: React.ReactNode;
    labelStyle?: React.CSSProperties;
    labelPosition?: 'after' | 'before';
    disabled?: boolean;
    onClick?: any;
    backgroundColor?: string;
    hoverColor?: string;
    className?: string;
}

class FlatButton extends React.Component<FlatButtonProps> {
    public render(): React.ReactNode {
        const {
            type,
            style,
            icon,
            label,
            labelStyle,
            labelPosition,
            disabled,
            onClick,
            backgroundColor,
            hoverColor,
            className
        } = this.props;

        return (
            <MuiFlatButton
                className={`${className ? className : ''} flat-button`}
                type={type}
                style={style}
                icon={icon}
                label={label}
                labelStyle={labelStyle}
                labelPosition={labelPosition}
                disabled={disabled}
                onClick={onClick}
                backgroundColor={backgroundColor}
                hoverColor={hoverColor}
            />
        );
    }
}

export { FlatButton, FlatButtonProps };
