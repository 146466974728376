import { UserActions } from 'actions/UserActions';
import { ServerAggregatedMeasurements } from 'models/aggregatedMeasurements/ServerAggregatedMeasurements';
import { ServerForecastMeasurement } from 'models/aggregatedMeasurements/ServerForecastMeasurements';
import { AlarmConfigPatch, AlarmConfigPost } from 'models/alarms/AlarmConfigModel';
import { ServerDashboardTemplate } from 'models/DashboardOptions';
import { DEVECPost, DEVECPut } from 'models/DEVEC';
import { EnergyProviderTaxPost } from 'models/EnergyProviderTax';
import { ExportOptionsPost } from 'models/ExportOptions';
import { ConsumptionGoalPatch, ConsumptionGoalPost } from 'models/sdp/ConsumptionGoal';
import { DemandContract } from 'models/sdp/DemandContractPost';
import { EnergyContractPost } from 'models/sdp/EnergyContract';
import { Metering } from 'models/sdp/Metering';
import { SdpCreate, SdpEdit } from 'models/sdp/Sdp';
import { TariffFramework } from 'models/sdp/TariffFrameworks';
import { SiteCreate, SiteEdit } from 'models/site/Site';
import { SitesGroupCreate, SitesGroupEdit } from 'models/site/SitesGroup';
import { SiteStateTaxPost } from 'models/SiteStateTax';
import { StateTaxPost } from 'models/StateTax';
import { SuppliedVoltageType } from 'models/SuppliedVoltageType';
import { User } from 'models/system/User';
import { UserToken } from 'models/system/UserToken';
import { TaxType } from 'models/TaxTypes';
import { Action, Dispatch } from 'redux';
import { AppState } from 'states/App';
import { UserState } from 'states/User';
import { DataRequest } from 'utils/DataRequest';
import { DateTime } from 'utils/DateTime';
import { FetchOptions } from 'utils/FetchOptions';
import { LoginRequest } from 'utils/LoginRequest';
import { PasswordChangeRequest } from 'utils/PasswordChangeRequest';
import { StatusCode } from 'utils/StatusCode';
import { Nullable, WithRequired } from 'utils/TypeUtils';

class ApiClient {
    public baseUrl: string;
    public authToken: string | null;
    public subscriptionId: string | null;
    public dispatch: Dispatch<Action, AppState> | undefined;

    public constructor(
        appState: AppState,
        dispatch?: Dispatch<Action, AppState>,
        token?: string,
        subscription?: string
    ) {
        this.baseUrl = appState.config.baseUrl;
        this.dispatch = dispatch;
        this.authToken = token || this.getAuthToken(appState.user);
        this.subscriptionId = subscription || this.getSubscriptionId(appState.user);
    }

    public logIn(request: LoginRequest): Promise<Response> {
        const body = new FormData();

        body.append('username', request.username);
        body.append('password', request.password);

        const url = this.getUrl('/api/login');
        const options = this.createPostOptions(body);

        return this.fetch(url, options);
    }

    public about(): Promise<Response> {
        const url = this.getUrl('/api/about');
        const options = this.createGetOptions();
        return this.fetch(url, options);
    }

    public recoverPassword(email: string): Promise<Response> {
        const url = this.getUrl(`/api/account/password-reset?email=${email}`);
        const options = this.createPostOptions();

        return this.fetch(url, options);
    }

    public changePassword(request: PasswordChangeRequest): Promise<Response> {
        const formData = new FormData();
        formData.append('TempToken', request.token);
        formData.append('NewPassword', request.password);

        const url = this.getUrl('/api/account/change-password');
        const options = this.createPostOptions(formData);

        return this.fetch(url, options);
    }

    public listSubscriptions(): Promise<Response> {
        const url = this.getUrl('/api/subscriptions');
        const options = this.createGetOptions();

        return this.fetch(url, options);
    }

    public updateSubscriptionSettings(colorPalette: string): Promise<Response> {
        const url = this.getUrl(
            `/api/${this.subscriptionId}/settings?colorPalette=${colorPalette}`
        );
        const options = this.createPatchOptions();

        return this.fetch(url, options);
    }

    public getUserSelf(): Promise<Response> {
        const url = this.getUrl('/api/users/me');
        const options = this.createGetOptions();

        return this.fetch(url, options);
    }

    public listUsers(): Promise<Response> {
        const url = this.getUrl(`/api/${this.subscriptionId}/users`);
        const options = this.createGetOptions();

        return this.fetch(url, options);
    }

    public getUser(id: string): Promise<Response> {
        const url = this.getUrl(`/api/${this.subscriptionId}/users/${id}`);
        const options = this.createGetOptions();

        return this.fetch(url, options);
    }

    public createUser(user: User): Promise<Response> {
        const url = this.getUrl(`/api/${this.subscriptionId}/users`);
        const body = JSON.stringify(user);
        const options = this.createPostOptions(body);

        return this.fetch(url, options);
    }

    public editUser(user: Partial<User>): Promise<Response> {
        const url = this.getUrl(`/api/${this.subscriptionId}/users/${user.id}`);
        const body = JSON.stringify(user);
        const options = this.createPutOptions(body);

        return this.fetch(url, options);
    }

    public deleteUser(id: string): Promise<Response> {
        const url = this.getUrl(`/api/${this.subscriptionId}/users/${id}`);
        const options = this.createDeleteOptions();

        return this.fetch(url, options);
    }

    public getUserSites(id: string): Promise<Response> {
        const url = this.getUrl(`/api/${this.subscriptionId}/user-sites/${id}`);
        const options = this.createGetOptions();

        return this.fetch(url, options);
    }

    public getUserSitesAndGroups(id: string): Promise<Response> {
        const url = this.getUrl(
            `/api/${this.subscriptionId}/user-sites-groups/${id}`
        );
        const options = this.createGetOptions();

        return this.fetch(url, options);
    }

    public setUserSites(id: string, sitesId: string[]): Promise<Response> {
        const url = this.getUrl(`/api/${this.subscriptionId}/user-sites/${id}`);
        const body = JSON.stringify(sitesId);
        const options = this.createPutOptions(body);

        return this.fetch(url, options);
    }

    public setUserSitesGroups(
        id: string,
        sitesIds: string[],
        groupsIds: string[]
    ): Promise<Response> {
        const url = this.getUrl(
            `/api/${this.subscriptionId}/user-sites-groups/${id}`
        );

        const bodyObject = {
            sites: sitesIds,
            groups: groupsIds
        };

        const body = JSON.stringify(bodyObject);
        const options = this.createPutOptions(body);

        return this.fetch(url, options);
    }

    public listConsumptionGoals(sdpId: string): Promise<Response> {
        const url = this.getUrl(
            `/api/${this.subscriptionId}/sdps/${sdpId}/consumption-goals`
        );
        const options = this.createGetOptions();

        return this.fetch(url, options);
    }

    public createConsumptionGoal(
        sdpId: string,
        consumptionGoal: ConsumptionGoalPost
    ): Promise<Response> {
        const url = this.getUrl(
            `/api/${this.subscriptionId}/sdps/${sdpId}/consumption-goals`
        );
        const body = JSON.stringify(consumptionGoal);
        const options = this.createPostOptions(body);

        return this.fetch(url, options);
    }

    public editConsumptionGoal(
        sdpId: string,
        id: number,
        consumptionGoal: ConsumptionGoalPatch
    ): Promise<Response> {
        const url = this.getUrl(
            `/api/${this.subscriptionId}/sdps/${sdpId}/consumption-goals/${id}`
        );
        const body = JSON.stringify(consumptionGoal);
        const options = this.createPatchOptions(body);

        return this.fetch(url, options);
    }

    public deleteConsumptionGoal(sdpId: string, id: number): Promise<Response> {
        const url = this.getUrl(
            `/api/${this.subscriptionId}/sdps/${sdpId}/consumption-goals/${id}`
        );
        const options = this.createDeleteOptions();

        return this.fetch(url, options);
    }

    public listSites(): Promise<Response> {
        const url = this.getUrl(`/api/${this.subscriptionId}/sites`);
        const options = this.createGetOptions();

        return this.fetch(url, options);
    }

    public listSitesPagination(searchString: string, pageSize: number, pageIndex: number, orderByField: string, sortDirection: string): Promise<Response> {
        const searchStringUrl = searchString && `searchString=${searchString}&`;
        const orderByFieldUrl = orderByField && `&orderByField=${orderByField}`;
        const sortDirectionUrl = sortDirection && `&sortDirection=${sortDirection}`;
        const url = this.getUrl(
            `/api/${
                this.subscriptionId
            }/sites/paged?${
                searchStringUrl
            }pageSize=${
                pageSize
            }&pageIndex=${
                pageIndex
            }${
                orderByFieldUrl
            }${
                sortDirectionUrl
            }
            `
        );

        const options = this.createGetOptions();

        return this.fetch(url, options);
    }

    public getSite(id: string): Promise<Response> {
        const url = this.getUrl(`/api/${this.subscriptionId}/sites/${id}`);
        const options = this.createGetOptions();

        return this.fetch(url, options);
    }

    public createSite(site: SiteCreate): Promise<Response> {
        const url = this.getUrl(`/api/${this.subscriptionId}/sites`);
        const body = JSON.stringify(site);
        const options = this.createPostOptions(body);

        return this.fetch(url, options);
    }

    public editSite(id: string, site: SiteEdit): Promise<Response> {
        const url = this.getUrl(`/api/${this.subscriptionId}/sites/${id}`);
        const body = JSON.stringify(site);
        const options = this.createPatchOptions(body);

        return this.fetch(url, options);
    }

    public deleteSite(id: string): Promise<Response> {
        const url = this.getUrl(`/api/${this.subscriptionId}/sites/${id}`);
        const options = this.createDeleteOptions();

        return this.fetch(url, options);
    }

    public listSitesGroups(): Promise<Response> {
        const url = this.getUrl(`/api/${this.subscriptionId}/groups`);
        const options = this.createGetOptions();

        return this.fetch(url, options);
    }

    public listGroupSites(id: string): Promise<Response> {
        const url = this.getUrl(`/api/${this.subscriptionId}/groups/${id}`);
        const options = this.createGetOptions();

        return this.fetch(url, options);
    }

    public getSitesGroup(id: string): Promise<Response> {
        const url = this.getUrl(`/api/${this.subscriptionId}/groups/${id}`);
        const options = this.createGetOptions();

        return this.fetch(url, options);
    }

    public createSitesGroup(sitesGroup: SitesGroupCreate): Promise<Response> {
        const url = this.getUrl(`/api/${this.subscriptionId}/groups`);
        const body = JSON.stringify(sitesGroup);
        const options = this.createPostOptions(body);

        return this.fetch(url, options);
    }

    public deleteSitesGroup(id: string): Promise<Response> {
        const url = this.getUrl(`/api/${this.subscriptionId}/groups/${id}`);
        const options = this.createDeleteOptions();

        return this.fetch(url, options);
    }

    public editSitesGroup(
        id: string,
        siteGroups: SitesGroupEdit
    ): Promise<Response> {
        const url = this.getUrl(`/api/${this.subscriptionId}/groups/${id}`);
        const body = JSON.stringify(siteGroups);
        const options = this.createPatchOptions(body);

        return this.fetch(url, options);
    }

    public listSdps(): Promise<Response> {
        const url = this.getUrl(`/api/${this.subscriptionId}/sdps`);
        const options = this.createGetOptions();

        return this.fetch(url, options);
    }

    public getSdp(sdpId: string): Promise<Response> {
        const url = this.getUrl(`/api/${this.subscriptionId}/sdps/${sdpId}`);
        const options = this.createGetOptions();

        return this.fetch(url, options);
    }

    public createSdp(sdp: SdpCreate): Promise<Response> {
        const url = this.getUrl(`/api/${this.subscriptionId}/sdps`);
        const body = JSON.stringify(sdp);
        const options = this.createPostOptions(body);

        return this.fetch(url, options);
    }

    public editSdp(id: string, sdp: SdpEdit): Promise<Response> {
        const url = this.getUrl(`/api/${this.subscriptionId}/sdps/${id}`);
        const body = JSON.stringify(sdp);
        const options = this.createPatchOptions(body);

        return this.fetch(url, options);
    }

    public deleteSdp(id: string): Promise<Response> {
        const url = this.getUrl(`/api/${this.subscriptionId}/sdps/${id}`);
        const options = this.createDeleteOptions();

        return this.fetch(url, options);
    }

    public editSdpMetering(id: string, metering: Metering): Promise<Response> {
        const url = this.getUrl(
            `/api/${this.subscriptionId}/sdps/${id}/metering`
        );
        const body = JSON.stringify(metering);
        const options = this.createPatchOptions(body);

        return this.fetch(url, options);
    }

    public listEnergyProviders(): Promise<Response> {
        const url = this.getUrl('/api/billing/energy-providers');
        const options = this.createGetOptions();

        return this.fetch(url, options);
    }

    public listDemandContractTemplates(): Promise<Response> {
        const url = this.getUrl('/api/billing/energy-contract-templates');
        const options = this.createGetOptions();

        return this.fetch(url, options);
    }

    public listDemandContracts(request: DataRequest): Promise<Response> {
        const { sdpId, startDate, endDate } = this.getRequestParameters(
            request
        );

        const queryParameters = this.buildQueryParameters([
            ['start', startDate],
            ['end', endDate]
        ]);
        const url = this.getUrl(
            `/api/billing/${
                this.subscriptionId
            }/sdps/${sdpId}/energy-contracts?${queryParameters}`
        );
        const options = this.createGetOptions();

        return this.fetch(url, options);
    }

    public createDemandContract(
        sdpId: string,
        contract: DemandContract
    ): Promise<Response> {
        const url = this.getUrl(
            `/api/billing/${this.subscriptionId}/sdps/${sdpId}/energy-contracts`
        );
        const body = JSON.stringify(contract);
        const options = this.createPostOptions(body);

        return this.fetch(url, options);
    }

    public deleteDemandContract(
        sdpId: string,
        contractId: number
    ): Promise<Response> {
        const url = this.getUrl(
            `/api/billing/${
                this.subscriptionId
            }/sdps/${sdpId}/energy-contracts/${contractId}`
        );
        const options = this.createDeleteOptions();

        return this.fetch(url, options);
    }

    public createEnergyContract(
        sdpId: string,
        contract: EnergyContractPost
    ): Promise<Response> {
        const url = this.getUrl(
            `/api/billing/${this.subscriptionId}/sdps/${sdpId}/acl-energy-contracts`
        );
        const body = JSON.stringify(contract);
        const options = this.createPostOptions(body);

        return this.fetch(url, options);
    }

    public editEnergyContract(
        sdpId: string,
        contractId: string,
        contract: EnergyContractPost
    ): Promise<Response> {
        const url = this.getUrl(
            `/api/billing/${this.subscriptionId}/sdps/${sdpId}/acl-energy-contracts/${
                contractId
            }`
        );

        const body = JSON.stringify(contract);
        const options = this.createPutOptions(body);

        return this.fetch(url, options);
    }

    public listEnergyContracts(sdpId: string): Promise<Response> {
        const url = this.getUrl(
            `/api/billing/${this.subscriptionId}/sdps/${sdpId}/acl-energy-contracts`
        );
        const options = this.createGetOptions();

        return this.fetch(url, options);
    }

    public deleteEnergyContract(
        sdpId: string,
        contractId: string
    ): Promise<Response> {
        const url = this.getUrl(
            `/api/billing/${
                this.subscriptionId
            }/sdps/${sdpId}/acl-energy-contracts/${contractId}`
        );
        const options = this.createDeleteOptions();

        return this.fetch(url, options);
    }

    public getDailyDemand(request: DataRequest): Promise<Response> {
        const { sdpId, startDate, endDate } = this.getRequestParameters(
            request
        );

        const url = this.getUrl(
            `/api/${
                this.subscriptionId
            }/sdps/${sdpId}/daily-max-demands?dayStart=${startDate}&dayEnd=${endDate}`
        );
        const options = this.createGetOptions();

        return this.fetch(url, options);
    }

    public getDemandReport(request: DataRequest): Promise<Response> {
        const { sdpId } = this.getRequestParameters(request);
        const url = this.getUrl(
            `/api/${this.subscriptionId}/sdps/${sdpId}/demand-report`
        );
        const options = this.createGetOptions();

        return this.fetch(url, options);
    }

    public getIntraDayDemand(request: DataRequest): Promise<Response> {
        const { sdpId, startDate, endDate } = this.getRequestParameters(
            request
        );

        const url = this.getUrl(
            `/api/${
                this.subscriptionId
            }/sdps/${sdpId}/intra-day-demand?dayStart=${startDate}&dayEnd=${endDate}`
        );
        const options = this.createGetOptions();

        return this.fetch(url, options);
    }

    public getMaxDemands(request: DataRequest): Promise<Response> {
        const { sdpId, startDate, endDate } = this.getRequestParameters(
            request
        );

        const url = this.getUrl(
            `/api/${
                this.subscriptionId
            }/sdps/${sdpId}/max-demands?dayStart=${startDate}&dayEnd=${endDate}`
        );
        const options = this.createGetOptions();

        return this.fetch(url, options);
    }

    public getCurrentDemand(request: DataRequest): Promise<Response> {
        const { sdpId } = this.getRequestParameters(request);
        const url = this.getUrl(
            `/api/${this.subscriptionId}/sdps/${sdpId}/current-demand`
        );
        const options = this.createGetOptions();

        return this.fetch(url, options);
    }

    public getIntraDayConsumption(request: DataRequest): Promise<Response> {
        const { sdpId, startDate, endDate } = this.getRequestParameters(
            request
        );

        const url = this.getUrl(
            `/api/${
                this.subscriptionId
            }/sdps/${sdpId}/intra-day-consumption?dayStart=${startDate}&dayEnd=${endDate}`
        );
        const options = this.createGetOptions();

        return this.fetch(url, options);
    }

    public getDailyConsumption(request: DataRequest): Promise<Response> {
        const { sdpId, startDate, endDate } = this.getRequestParameters(
            request
        );

        const url = this.getUrl(
            `/api/${
                this.subscriptionId
            }/sdps/${sdpId}/daily-consumption?dayStart=${startDate}&dayEnd=${endDate}`
        );
        const options = this.createGetOptions();

        return this.fetch(url, options);
    }

    public getTotalConsumption(request: DataRequest): Promise<Response> {
        const { sdpId, startDate, endDate } = this.getRequestParameters(
            request
        );

        const url = this.getUrl(
            `/api/${
                this.subscriptionId
            }/sdps/${sdpId}/total-consumption?dayStart=${startDate}&dayEnd=${endDate}`
        );
        const options = this.createGetOptions();

        return this.fetch(url, options);
    }

    public getConsumptionRelation(request: DataRequest): Promise<Response> {
        const { sdpId, startDate, endDate } = this.getRequestParameters(
            request
        );

        const url = this.getUrl(
            `/api/${
                this.subscriptionId
            }/sdps/${sdpId}/consumption-relation?dayStart=${startDate}&dayEnd=${endDate}`
        );
        const options = this.createGetOptions();

        return this.fetch(url, options);
    }

    public getCurrentPowerFactor(request: DataRequest): Promise<Response> {
        const { sdpId } = this.getRequestParameters(request);
        const url = this.getUrl(
            `/api/${this.subscriptionId}/sdps/${sdpId}/current-power-factor`
        );
        const options = this.createGetOptions();

        return this.fetch(url, options);
    }

    public getIntraDayPowerFactor(request: DataRequest): Promise<Response> {
        const { sdpId, startDate, endDate } = this.getRequestParameters(
            request
        );

        const url = this.getUrl(
            `/api/${
                this.subscriptionId
            }/sdps/${sdpId}/intra-day-power-factor?dayStart=${startDate}&dayEnd=${endDate}`
        );
        const options = this.createGetOptions();

        return this.fetch(url, options);
    }

    public getDailyPowerFactor(request: DataRequest): Promise<Response> {
        const { sdpId, startDate, endDate } = this.getRequestParameters(
            request
        );

        const url = this.getUrl(
            `/api/${
                this.subscriptionId
            }/sdps/${sdpId}/daily-power-factor?dayStart=${startDate}&dayEnd=${endDate}`
        );
        const options = this.createGetOptions();

        return this.fetch(url, options);
    }

    public getConsumptionReport(request: DataRequest): Promise<Response> {
        const { sdpId } = this.getRequestParameters(request);
        const url = this.getUrl(
            `/api/${this.subscriptionId}/sdps/${sdpId}/consumption-report`
        );
        const options = this.createGetOptions();

        return this.fetch(url, options);
    }

    public listEnergyBills(request: WithRequired<DataRequest, 'endDate'>): Promise<Response> {
        const { sdpId, startDate } = this.getRequestParameters(request);

        const endDate = request.endDate.clone();
        const endDateYear = endDate.getYear();
        const endOfYear = new DateTime().setYear(endDateYear).endOfYear();

        if (!endDate.isSameMonth(endOfYear)) {
            endDate.addMonths(1);
        }

        const queryParameters = this.buildQueryParameters([
            ['monthStart', startDate],
            ['monthEnd', endDate.format('YYYY-MM-DDTHH:mm')]
        ]);

        const url = this.getUrl(
            `/api/billing/${
                this.subscriptionId
            }/sdps/${sdpId}/energy-bills?${queryParameters}`
        );
        const options = this.createGetOptions();

        return this.fetch(url, options);
    }

    public getEnergyBillAlerts(
        sdpId: string,
        billId: number
    ): Promise<Response> {
        const queryParams = this.buildQueryParameters([
            ['billId', billId.toString()]
        ]);

        const url = this.getUrl(
            `/api/billing/${
                this.subscriptionId
            }/sdps/${sdpId}/energy-bill-alerts?${queryParams}`
        );
        const options = this.createGetOptions();

        return this.fetch(url, options);
    }

    public getEnergyBill(sdpId: string, billId: number): Promise<Response> {
        const url = this.getUrl(
            `/api/billing/${this.subscriptionId}/sdps/${sdpId}/energy-bills/${billId}`
        );
        const options = this.createGetOptions();

        return this.fetch(url, options);
    }

    public getSimulatedEnergyBill(request: DataRequest, saveBill = false): Promise<Response> {
        const { sdpId, startDate, endDate } = request;

        const start = startDate?.format('YYYY-MM-DDTHH:mm');
        const end = endDate?.format('YYYY-MM-DDTHH:mm');

        const queryString = this.buildQueryParameters([
            ['Start', start],
            ['End', end],
            ['SaveBill', saveBill.toString()]
        ]);

        const url = this.getUrl(
            `/api/billing/${
                this.subscriptionId
            }/sdps/${sdpId}/energy-bill-calculation-requests?${queryString}`
        );
        const options = this.createPostOptions();

        return this.fetch(url, options);
    }

    public uploadEnergyBillFiles(
        sdpId: string,
        files: FileList
    ): Promise<Response> {
        const formData = new FormData();

        for (let index = 0; index < files.length; index++) {
            const file = files.item(index);
            if (file !== null) {
                formData.append('file', file, file.name);
            }
        }

        const url = this.getUrl(
            `/api/billing/${
                this.subscriptionId
            }/sdps/${sdpId}/energy-bill-parse/`
        );

        const options = this.createPostOptions(formData);

        return this.fetch(url, options);
    }

    public getEnergyBillDownloadUrl(
        sdpId: string,
        billId: number
    ): Promise<Response> {
        const url = this.getUrl(
            `/api/billing/${
                this.subscriptionId
            }/sdps/${sdpId}/energy-bills/${billId}/file`
        );
        const options = this.createGetOptions();

        return this.fetch(url, options);
    }

    public listDEVECTaxes(
        sdpId: string
    ): Promise<Response> {
        const url = this.getUrl(
            `/api/billing/${
                this.subscriptionId
            }/sdps/${sdpId}/devec-tax`
        );
        const options = this.createGetOptions();

        return this.fetch(url, options);
    }

    public createDEVECTaxes(sdpId: string, stateTaxes: DEVECPost[]): Promise<Response> {
        const url = this.getUrl(
            `/api/billing/${
                this.subscriptionId
            }/sdps/${sdpId}/devec-tax`
        );
        const body = JSON.stringify(stateTaxes);
        const options = this.createPostOptions(body);

        return this.fetch(url, options);
    }

    public updateDEVECTax(sdpId: string, id: number, DEVEC: DEVECPut): Promise<Response> {
        const url = this.getUrl(
            `/api/billing/${
                this.subscriptionId
            }/sdps/${sdpId}/devec-tax/${id}`
        );
        const body = JSON.stringify(DEVEC);
        const options = this.createPutOptions(body);

        return this.fetch(url, options);
    }

    public listSdpStateTaxes(
        sdpId: string
    ): Promise<Response> {
        const url = this.getUrl(
            `/api/billing/${
                this.subscriptionId
            }/sdps/${sdpId}/business-segment`
        );
        const options = this.createGetOptions();

        return this.fetch(url, options);
    }

    public createSdpStateTaxes(sdpId: string, stateTaxes: SiteStateTaxPost[]): Promise<Response> {
        const url = this.getUrl(
            `/api/billing/${
                this.subscriptionId
            }/sdps/${sdpId}/business-segment`
        );
        const body = JSON.stringify(stateTaxes);
        const options = this.createPostOptions(body);

        return this.fetch(url, options);
    }

    public deleteSdpStateTax(sdpId: string, stateTaxId: string): Promise<Response> {
        const url = this.getUrl(
            `/api/billing/${
                this.subscriptionId
            }/sdps/${sdpId}/business-segment/${stateTaxId}`
        );

        const options = this.createDeleteOptions();

        return this.fetch(url, options);
    }

    public listStatesTaxes(
        taxType: TaxType,
        startDate?: DateTime,
        endDate?: DateTime
    ): Promise<Response> {
        const start = startDate || new DateTime().setYear(1900).startOfYear();
        const end = endDate || new DateTime().setYear(9999).startOfYear();

        const queryString = this.buildQueryParameters([
            ['start', start.format('YYYY-MM-DD')],
            ['end', end.format('YYYY-MM-DD')]
        ]);

        const url = this.getUrl(
            `/api/billing/taxes/by-state/br/${taxType}?${queryString}`
        );
        const options = this.createGetOptions();

        return this.fetch(url, options);
    }

    public createStateTaxes(
        stateTaxes: StateTaxPost[],
        taxType: TaxType
    ): Promise<Response> {
        const url = this.getUrl(`/api/billing/taxes/by-state/br/${taxType}`);
        const body = JSON.stringify(stateTaxes);
        const options = this.createPostOptions(body);

        return this.fetch(url, options);
    }

    public deleteStateTax(
        stateTaxId: string,
        taxType: TaxType
    ): Promise<Response> {
        const url = this.getUrl(
            `/api/billing/taxes/by-state/br/${taxType}/${stateTaxId}`
        );
        const options = this.createDeleteOptions();

        return this.fetch(url, options);
    }

    public listEnergyProviderTaxes(
        energyProvider: string,
        startDate?: DateTime,
        endDate?: DateTime
    ): Promise<Response> {
        const start = startDate || new DateTime().setYear(1900).startOfYear();
        const end = endDate || new DateTime().setYear(9999).startOfYear();

        const queryString = this.buildQueryParameters([
            ['start', start.format('YYYY-MM-DD')],
            ['end', end.format('YYYY-MM-DD')]
        ]);

        const url = this.getUrl(
            `/api/billing/taxes/by-energy-provider/${energyProvider}?${queryString}`
        );
        const options = this.createGetOptions();

        return this.fetch(url, options);
    }

    public createEnergyProviderTaxes(
        energyProviderTaxes: EnergyProviderTaxPost[],
        energyProvider: string
    ): Promise<Response> {
        const url = this.getUrl(
            `/api/billing/taxes/by-energy-provider/${energyProvider}`
        );
        const body = JSON.stringify(energyProviderTaxes);
        const options = this.createPostOptions(body);

        return this.fetch(url, options);
    }

    public deleteEnergyProviderTax(
        energyProviderTaxId: string,
        energyProvider: string
    ): Promise<Response> {
        const url = this.getUrl(
            `/api/billing/taxes/by-energy-provider/${energyProvider}/${energyProviderTaxId}`
        );
        const options = this.createDeleteOptions();

        return this.fetch(url, options);
    }

    public listEnergyTariffs(
        energyProviderId: number,
        tariffType: TariffFramework,
        voltageType: SuppliedVoltageType,
        startDate?: DateTime,
        endDate?: DateTime
    ) {
        const start = startDate || new DateTime().setYear(1900).startOfYear();
        const end = endDate || new DateTime().setYear(9999).startOfYear();

        const queryString = this.buildQueryParameters([
            ['tariffTypeName', tariffType],
            ['suppliedVoltageTypeName', voltageType],
            ['dayStart', start.format('YYYY-MM-DD')],
            ['dayEnd', end.format('YYYY-MM-DD')]
        ]);

        const url = this.getUrl(
            `/api/billing/energy-providers/${energyProviderId}/tariffs?${queryString}`
        );
        const options = this.createGetOptions();

        return this.fetch(url, options);
    }

    public uploadEnergyTariffFile(
        energyProviderId: number,
        file: File,
        validSince: DateTime
    ): Promise<Response> {
        const formData = new FormData();
        formData.append('formFile', file, file.name);

        const validSinceString = validSince.format('YYYY-MM-DD');
        const queryString = `?validSince=${validSinceString}`;

        const url = this.getUrl(
            `/api/billing/energy-providers/${energyProviderId}/tariffs-file${queryString}`
        );

        const options = this.createPostOptions(formData);

        return this.fetch(url, options);
    }

    public listEnergyTariffFlags(
        monthStart: DateTime,
        monthEnd: DateTime
    ): Promise<Response> {
        const queryParams = this.buildQueryParameters([
            ['monthStart', monthStart.format('YYYY-MM')],
            ['monthEnd', monthEnd.format('YYYY-MM')]
        ]);

        const url = this.getUrl(
            `/api/billing/energy-providers/tariff-flags?${queryParams}`
        );
        const options = this.createGetOptions();

        return this.fetch(url, options);
    }

    public listAlarmOccurrences(
        startDate: DateTime,
        endDate: DateTime,
        sdpIds: string[]
    ): Promise<Response> {
        const url = this.getUrl(`/api/v2/alarms/occurrences-request/${this.subscriptionId}`);

        const body = JSON.stringify({
            start: startDate.format('YYYY-MM-DDTHH:mm'),
            end: endDate.format('YYYY-MM-DDTHH:mm'),
            sdpIds
        });
        const options = this.createPostOptions(body);

        return this.fetch(url, options);
    }

    public listAlarmsConfigs(sdpIds: string[]): Promise<Response> {
        const url = this.getUrl(`/api/v2/alarms/list-request/${ this.subscriptionId }`);

        const body = JSON.stringify({
            sdpIds
        });
        const options = this.createPostOptions(body);

        return this.fetch(url, options);
    }

    public createAlarmConfig(alarmConfig: AlarmConfigPost): Promise<Response> {
        const url = this.getUrl(`/api/v2/alarms/${ this.subscriptionId }`);

        const body = JSON.stringify(alarmConfig);
        const options = this.createPostOptions(body);

        return this.fetch(url, options);
    }

    public editAlarmConfig(
        alarmConfigId: string,
        alarmConfigUpdate: AlarmConfigPatch
    ): Promise<Response> {
        const url = this.getUrl(`/api/v2/alarms/${this.subscriptionId}/${alarmConfigId}`);

        const body = JSON.stringify(alarmConfigUpdate);
        const options = this.createPatchOptions(body);

        return this.fetch(url, options);
    }

    public deleteAlarmConfig(alarmConfigId: string): Promise<Response> {
        const url = this.getUrl(`/api/v2/alarms/${this.subscriptionId}/${alarmConfigId}`);
        const options = this.createDeleteOptions();

        return this.fetch(url, options);
    }

    public getExportEnergyDataUrl = (
        sdpIds: string[],
        exportOptions: ExportOptionsPost
    ): Promise<Response> => {
        const url = this.getUrl(`/api/v2/storage/${this.subscriptionId}/export`);
        const body = JSON.stringify({
            ...exportOptions,
            sdpIds
        });
        const options = this.createPostOptions(body);

        return this.fetch(url, options);
    };

    public getExportBillDataUrl = (
        sdpIds: string[],
        exportOptions: ExportOptionsPost
    ): Promise<Response> => {
        const url = this.getUrl(
            `/api/billing/${this.subscriptionId}/energy-bills/export`
        );
        const body = JSON.stringify({
            ...exportOptions,
            sdpIds
        });
        const options = this.createPostOptions(body);

        return this.fetch(url, options);
    };

    public getExportWaterDataUrl = (
        sdpIds: string[],
        exportOptions: ExportOptionsPost
    ): Promise<Response> => {
        const url = this.getUrl(
            `/api/v2/storage/${this.subscriptionId}/water/export`
        );
        const body = JSON.stringify({
            ...exportOptions,
            sdpIds
        });
        const options = this.createPostOptions(body);

        return this.fetch(url, options);
    };

    public getRenewedToken(): Promise<Response> {
        const url = this.getUrl('/api/renew-token');
        const options = this.createPostOptions();

        return fetch(url, options);
    }

    public getDashboardTemplates(): Promise<Response> {
        const url = this.getUrl(
            `/api/${this.subscriptionId}/dashboard-templates`
        );
        const options = this.createGetOptions();

        return this.fetch(url, options);
    }

    public getDashboardTemplate(id: string): Promise<Response> {
        const url = this.getUrl(
            `/api/${this.subscriptionId}/dashboard-templates/${id}`
        );
        const options = this.createGetOptions();

        return this.fetch(url, options);
    }

    public createDashboardTemplate(template: string): Promise<Response> {
        const url = this.getUrl(
            `/api/${this.subscriptionId}/dashboard-templates`
        );
        const body = JSON.stringify({
            template
        });
        const options = this.createPostOptions(body);

        return this.fetch(url, options);
    }

    public deleteDashboardTemplate(id: string): Promise<Response> {
        const url = this.getUrl(
            `/api/${this.subscriptionId}/dashboard-templates/${id}`
        );
        const options = this.createDeleteOptions();

        return this.fetch(url, options);
    }

    public editDashboardTemplate(
        dashboardTemplate: Partial<ServerDashboardTemplate>
    ): Promise<Response> {
        const { id } = dashboardTemplate;
        const url = this.getUrl(
            `/api/${this.subscriptionId}/dashboard-templates/${id}`
        );
        const body = JSON.stringify(dashboardTemplate);
        const options = this.createPutOptions(body);

        return this.fetch(url, options);
    }

    public getAggregatedForecastMeasurements(
        forecastMeasurements: ServerForecastMeasurement,
        signal?: AbortSignal
    ): Promise<Response> {
        const url = this.getUrl(
            `/api/v2/storage/${this.subscriptionId}/sdps/aggregate-forecast-measurements`
        );

        const body = JSON.stringify(forecastMeasurements);
        const options = this.createPostOptions(body);

        return this.fetch(url, {
            ...options,
            signal
        });
    }

    public getAggregatedMeasurements(
        serverMeasurements: ServerAggregatedMeasurements,
        signal?: AbortSignal
    ): Promise<Response> {
        const url = this.getUrl(
            `/api/v2/storage/${this.subscriptionId}/sdps/aggregate-measurements`
        );

        const body = JSON.stringify(serverMeasurements);
        const options = this.createPostOptions(body);

        return this.fetch(url, {
            ...options,
            signal
        });
    }

    // FAZER TODOS OS METODOS DEPOIS COM BODY E TAL
    public async Invoke(
        httpMethod: string,
        url: string
    ): Promise<Response> {

        const urlT = this.getUrl(url);

        switch (httpMethod) {
            case 'GET': {
                const options = this.createGetOptions();
                return this.fetch(urlT, options);
            }
            default:
                throw new Error('Not implemented');
        }
    }

    private getHeaders(formData = false): HeadersInit {
        if (!this.authToken) {
            return {};
        }

        if (formData) {
            return { Authorization: `Bearer ${this.authToken}` };
        }

        return {
            Authorization: `Bearer ${this.authToken}`,
            'Content-Type': 'application/json'
        };
    }

    private getSubscriptionId(user: UserState): string | null {
        return user ? user.subscriptionId : null;
    }

    private getAuthToken(user: UserState): string | null {
        return user ? user.token : null;
    }

    private getUrl(url: string): string {
        return `${this.baseUrl}${url}`;
    }

    private buildQueryParameters(parameters: [NonNullable<string>, Nullable<string>][]) {
        const values = [];
        for (const [key, value] of parameters) {
            if (value === null || value === undefined) {
                continue;
            }
            values.push(`${key}=${value}`);
        }
        return values.join('&');
    }

    private getRequestParameters(request: DataRequest) {
        const sdpId = request.sdpId;
        const startDate = request.startDate
            ? request.startDate.format('YYYY-MM-DD')
            : null;
        const endDate = request.endDate
            ? request.endDate.format('YYYY-MM-DD')
            : null;

        return { sdpId, startDate, endDate };
    }

    private createOptions(
        method: string,
        body?: FormData | string
    ): FetchOptions {
        const headers = this.getHeaders(body instanceof FormData);

        return {
            method,
            mode: 'cors',
            body,
            headers
        };
    }

    private createDeleteOptions(): FetchOptions {
        return this.createOptions('DELETE');
    }

    private createGetOptions(): FetchOptions {
        return this.createOptions('GET');
    }

    private createPatchOptions(body?: FormData | string): FetchOptions {
        return this.createOptions('PATCH', body);
    }

    private createPostOptions(body?: FormData | string): FetchOptions {
        return this.createOptions('POST', body);
    }

    private createPutOptions(body?: any): FetchOptions {
        return this.createOptions('PUT', body);
    }

    private async fetch(
        input: RequestInfo,
        init: RequestInit
    ): Promise<Response> {
        const baseResponse = await fetch(input, init);

        if (baseResponse.status === StatusCode.Unauthorized) {
            const renewalResponse = await this.getRenewedToken();

            if (renewalResponse.ok) {
                const userToken: UserToken = await renewalResponse.json();
                const tokenString = userToken.token;

                const tokenAction = UserActions.setToken(tokenString);
                this.dispatch?.(tokenAction);
                this.authToken = tokenString;

                const newHeaders = new Headers(init.headers);
                newHeaders.set('Authorization', `Bearer ${this.authToken}`);
                init.headers = newHeaders;

                return fetch(input, init);
            }
        }

        return Promise.resolve(baseResponse);
    }
}

export { ApiClient };
