import { Action } from 'redux';
import { ConfigState } from 'states/Config';

class ConfigReducer {

    reduce = (previousState = new ConfigState(), action: Action) => {
        return previousState;
    }

}

export { ConfigReducer };
