import { useQuery } from '@apollo/react-hooks';
import NotificationManager from 'containers/base/NotificationManagerContainer';
import Router from 'containers/base/Router';
import * as React from 'react';
import { setConfig } from 'react-hot-loader';
import { hot } from 'react-hot-loader/root';
import GetNewExperienceToggle from 'utils/queries/GetNewExperienceToggle';
import { NewExperienceIsActivated } from 'utils/queries/gqlTypes/NewExperienceIsActivated';

setConfig({ ErrorOverlay: () => null });

const AppHolder = () => {
    const token = localStorage.getItem('token');
    const isAmbientAllowed = process.env.FLUX_TRANSITION_ALLOWED === 'true';

    const { data } = useQuery<NewExperienceIsActivated>(
        GetNewExperienceToggle, {
            variables: {},
            errorPolicy: 'ignore',
            partialRefetch: true,
            skip: !token || !isAmbientAllowed
        }
    );

    const toggleStateGraphql = data?.users?.userActivatedNewExperienceUse ?? false;

    return (
        <>
            <Router toggleStateGraphql={toggleStateGraphql} />
            <NotificationManager />
        </>
    );
};

export default hot(AppHolder);
