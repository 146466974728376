import { Actions } from 'actions/Actions';
import { EnergyProviderTariff } from 'models/EnergyProviderTariff';
import { Action } from 'redux';
import EnergyProviderTariffsState from 'states/EnergyProviderTariffsState';

class EnergyProviderTariffsReducer {
    reduce = (previousState = new EnergyProviderTariffsState(), action: Action): EnergyProviderTariffsState => {
        let nextState: EnergyProviderTariffsState;
        switch (action.type) {
            case Actions.RequestEnergyProviderTariffs:
                nextState = this.setFetching(previousState, action);
                return nextState;

            case Actions.ReceiveEnergyProviderTariffs:
                nextState = this.setReceived(previousState, action);
                return nextState;

            default: return previousState;
        }
    }

    private setFetching(previousState: EnergyProviderTariffsState, action: Action): EnergyProviderTariffsState {
        const { tariffs } = previousState;

        return {
            tariffs,
            isFetching: true
        };
    }

    private setReceived(previousState: EnergyProviderTariffsState, action: any): EnergyProviderTariffsState {
        const { tariffs } = previousState;
        const data = action.data;

        if (!data || !data.items) {
            return previousState;
        }

        const baseTariffs = [...(tariffs || [])];
        data.items.forEach((tariff: EnergyProviderTariff) => {
            baseTariffs[tariff.id] = tariff;
        });

        return {
            tariffs:  baseTariffs,
            isFetching: false
        };
    }
}

export default EnergyProviderTariffsReducer;
