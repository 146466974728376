import * as React from 'react';
import { DateTime } from 'utils/DateTime';
import { Nullable } from 'utils/TypeUtils';

interface DateInput {
    value: Nullable<DateTime>;
    setValue: React.Dispatch<React.SetStateAction<Nullable<DateTime>>>;
    errorText: string;
    setError: React.Dispatch<React.SetStateAction<string>>;
    onChange: (date: Date) => void;
}
interface HookInput {
    value: string;
    setValue: React.Dispatch<React.SetStateAction<string>>;
    errorText: string;
    setError: React.Dispatch<React.SetStateAction<string>>;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const debounce = require('lodash.debounce');

const useToggle = (initialValue = false): [boolean, () => void] => {
    const [value, setValue] = React.useState(initialValue);

    const toggle = () => {
        setValue(prevValue => !prevValue);
    };
    return [value, toggle];
};

const useInput = (initialValue: string): HookInput => {
    const [value, setValue] = React.useState(initialValue);
    const [errorText, setError] = React.useState('');

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setError('');
        setValue(event.target.value);
    };

    return {
        value,
        onChange: handleChange,
        setError,
        setValue,
        errorText
    };
};

const useDateInput = (initialValue: Nullable<DateTime>): DateInput => {
    const [value, setValue] = React.useState(initialValue);
    const [errorText, setError] = React.useState('');

    const handleChange = (date: Date) => {
        setError('');
        setValue(new DateTime(date));
    };

    return {
        value,
        onChange: handleChange,
        setError,
        setValue,
        errorText
    };
};

interface DropdownInput<T> {
    value: Nullable<T>;
    setValue: React.Dispatch<React.SetStateAction<Nullable<T>>>;
    onChange: (
        event: React.ChangeEvent<HTMLSelectElement>,
        index: number,
        dropdownValue: T
    ) => void;
    errorText: string;
    setError: React.Dispatch<React.SetStateAction<string>>;
};

const useDropdown = <T extends {}>(initialValue: Nullable<T>): DropdownInput<T> => {
    const [value, setValue] = React.useState<Nullable<T>>(initialValue);
    const [errorText, setError] = React.useState('');

    const handleChange: DropdownInput<T>['onChange'] = (
        event,
        index,
        dropdownValue
    ) => {
        setValue(dropdownValue);
    };

    const setValueErrorReset: DropdownInput<T>['setValue'] = (newValue) => {
        setError('');
        setValue(newValue);
    };

    return {
        value,
        setValue: setValueErrorReset,
        onChange: handleChange,
        errorText,
        setError
    };
};

const useStateDebounced = <T extends {}>(
    initialValue: T,
    wait: number,
    options: { leading?: boolean } = {}
): [T, React.Dispatch<React.SetStateAction<T>>] => {
    const [value, setValue] = React.useState<T>(initialValue);
    const debouncedSetValue = React.useRef(
        debounce(
            (newValue: T) => {
                setValue(newValue);
            },
            wait,
            options
        )
    );

    React.useEffect(() => {
        debouncedSetValue.current = debounce(
            (newValue: T) => setValue(newValue),
            wait,
            options
        );
    }, [wait]);

    return [value, debouncedSetValue.current];
};

export {
    useToggle,
    useInput,
    useStateDebounced,
    useDropdown,
    useDateInput,
    HookInput,
    DateInput,
    DropdownInput
};
