import { Actions } from 'actions/Actions';
import PagedList from 'models/server/PagedList';
import { Action } from 'redux';
import { DataState } from 'states/Data';
import { Entity, EntityState } from 'states/Entity';
import { DateTime } from 'utils/DateTime';

interface EntityAction<T> extends Action {
    id?: string;
    data?: T;
    state?: DataState<T>;
}

interface EntityActions {
    requestList?: Actions;
    receiveList?: Actions;
    receiveListAdditive?: Actions;
    requestGet?: Actions;
    receiveGet?: Actions;
    requestCreate?: Actions;
    receiveCreate?: Actions;
    failCreate?: Actions;
    requestEdit?: Actions;
    receiveEdit?: Actions;
    requestDelete?: Actions;
    receiveDelete?: Actions;
    requestClear?: Actions;
}

class EntityReducer<T> {
    public actions: EntityActions;

    public constructor(actions: EntityActions) {
        this.actions = actions;
    }

    public reduce = (
        previousState = new EntityState<T>(),
        action: EntityAction<T>
    ): EntityState<T> => {
        switch (action.type) {
            case this.actions.requestList:
                return this.setAsFetching(previousState);
            case this.actions.receiveList:
                return this.setStates(previousState, (action as any).data);
            case this.actions.receiveListAdditive:
                return this.setStatesAdditive(
                    previousState,
                    (action as any).data
                );
            case this.actions.requestGet:
                return this.setStateAsFetching(previousState, action);
            case this.actions.receiveGet:
                return this.setStateData(previousState, action);
            case this.actions.requestCreate:
                return this.setAsCreating(previousState);
            case this.actions.receiveCreate:
                return this.addToStates(previousState, action);
            case this.actions.failCreate:
                return this.setError(previousState);
            case this.actions.requestEdit:
                return this.setStateAsEditing(previousState, action);
            case this.actions.receiveEdit:
                return this.setStateData(previousState, action);
            case this.actions.requestDelete:
                return this.setStateAsDeleting(previousState, action);
            case this.actions.receiveDelete:
                return this.deleteState(previousState, action);
            case Actions.LogOut:
            case this.actions.requestClear:
                return this.eraseState();
            default:
                return previousState;
        }
    };

    private setAsFetching(previousState: EntityState<T>): EntityState<T> {
        const state = new EntityState<T>(previousState);
        state.addFetching();
        return state;
    }

    private setStates(
        previousState: EntityState<T>,
        data: PagedList<DataState<T & Entity>>
    ): EntityState<T> {
        const state = new EntityState<T>(previousState);

        if (data.pageIndex) {
            state.pageIndex = data.pageIndex;
        }

        if (data.pageSize) {
            state.pageSize = data.pageSize;
        }

        if (state.totalOld === 0) {
            state.totalOld = data.total;
        }

        state.setStates(data.items);
        state.total = data.total;
        state.subtractFetching();
        state.lastUpdated = new DateTime();

        return state;
    }

    private setStatesAdditive(
        previousState: EntityState<T>,
        data: PagedList<DataState<T & Entity>>
    ): EntityState<T> {
        const state = new EntityState<T>(previousState);

        state.addStates(data.items);
        state.total = state.listData().length;
        state.subtractFetching();
        state.lastUpdated = new DateTime();

        return state;
    }

    private setStateAsFetching(
        previousState: EntityState<T>,
        action: EntityAction<T>
    ): EntityState<T> {
        const state = new EntityState<T>(previousState);
        state.setStateAsFetching(action.id);
        return state;
    }

    private setStateData(
        previousState: EntityState<T>,
        action: EntityAction<T>
    ) {
        const state = new EntityState<T>(previousState);
        state.setStateData(action.id, action.data);
        state.setStateAsDone(action.id);
        state.lastUpdated = new DateTime();
        return state;
    }

    private setAsCreating(
        previousState: EntityState<T>
    ): EntityState<T> {
        const state = new EntityState<T>(previousState);
        state.isCreating = true;
        return state;
    }

    private addToStates(
        previousState: EntityState<T>,
        action: EntityAction<T>
    ): EntityState<T> {
        const state = new EntityState<T>(previousState);

        state.setState(action.id, action.state);
        state.isCreating = false;
        state.total += 1;

        return state;
    }

    private setError(previousState: EntityState<T>) {
        const state = new EntityState<T>(previousState);
        state.isCreating = false;
        state.subtractFetching();
        state.hasError = true;
        return state;
    }

    private setStateAsDeleting(
        previousState: EntityState<T>,
        action: EntityAction<T>
    ): EntityState<T> {
        const state = new EntityState<T>(previousState);
        state.setStateAsDeleting(action.id);
        return state;
    }

    private deleteState(
        previousState: EntityState<T>,
        action: EntityAction<T>
    ): EntityState<T> {
        const state = new EntityState<T>(previousState);
        state.removeState(action.id);
        return state;
    }

    private setStateAsEditing(
        previousState: EntityState<T>,
        action: EntityAction<T>
    ): EntityState<T> {
        const state = new EntityState<T>(previousState);
        state.setStateAsEditing(action.id, action.data);
        return state;
    }

    private eraseState() {
        return new EntityState<T>();
    }
}

export { EntityReducer, EntityAction, EntityActions };
