import { Actions } from 'actions/Actions';
import { Action } from 'redux';
import { PasswordState } from 'states/Password';

class PasswordReducer {

    reduce = (previousState = new PasswordState(), action: Action): PasswordState => {
        let nextState;

        switch (action.type) {
            case Actions.RequestPasswordReset:
                nextState = this.setAsRecovering(previousState);
                break;
            case Actions.ReceivePasswordReset:
                nextState = this.setAsRecovered(previousState);
                break;
            case Actions.RequestChangePassword:
                nextState = this.setAsChanging(previousState);
                break;
            case Actions.ReceiveChangePassword:
                nextState = this.setAsChanged(previousState);
                break;
            default: return previousState;
        }

        return nextState;
    }

    private setAsRecovering(previousState: PasswordState): PasswordState {
        return {
            ...previousState,
            isReseting: true
        };
    }

    private setAsRecovered(previousState: PasswordState): PasswordState {
        return {
            ...previousState,
            isReseting: false
        };
    }

    private setAsChanging(previousState: PasswordState): PasswordState {
        return {
            ...previousState,
            isChanging: true
        };
    }

    private setAsChanged(previousState: PasswordState): PasswordState {
        return {
            ...previousState,
            isChanging: false
        };
    }

}

export { PasswordReducer };
