import { Actions } from 'actions/Actions';

class DashboardActions {

    public static selectSite(id: string) {
        return { type: Actions.SelectDashboardSite, id };
    }

}

export { DashboardActions };
