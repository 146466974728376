import { Actions } from 'actions/Actions';
import { Action } from 'redux';
import CustomizableDashboardState from 'states/CustomizableDashboardState';

class CustomizableDashboardReducer {
    public reduce = (
        previousState = new CustomizableDashboardState(),
        action: Action
    ): CustomizableDashboardState => {
        switch (action.type) {
            case Actions.LoadSession:
                return this.loadSession(action);
            case Actions.SelectCustomizableDashboard:
                return this.selectId(action);
            case Actions.LogOut:
                return this.eraseSession();
            default:
                return previousState;
        }
    }

    private loadSession(action: any): CustomizableDashboardState {
        return (
            action.state.customizableDashboard ||
            new CustomizableDashboardState()
        );
    }

    private eraseSession(): CustomizableDashboardState {
        return new CustomizableDashboardState();
    }

    private selectId(action: any): CustomizableDashboardState {
        return {
            id: action.id
        };
    }
}

export default CustomizableDashboardReducer;
