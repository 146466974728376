import { Dialog } from 'material-ui';
import * as React from 'react';
import * as styles from 'styles/components/base/WarningPopUp.module.scss';

const ModalContent = ({ onClose }: { onClose: () => void }) => (
    <div className={styles.disclaimerContainer}>
        <div className={styles.containerModal}>
            <p>Seu licenciamento para a PowerHub irá expirar no dia 31/01/2024. Após este prazo, não será mais possível acessar a plataforma.</p>
        </div>
        <div className={styles.closeModal}>
            <button
                type="button"
                onClick={onClose}
            >
                OK
            </button>
        </div>
    </div>
);

const getDialogContentStyle = (): React.CSSProperties => ({
    background: '#607D8B',
    color: '#fff'
});

const WarningPopUp = () => {
    const [isOpen, setIsOpen] = React.useState(true);

    const handleClose = () => setIsOpen(!isOpen);
    const titleStyle = getDialogContentStyle();

    return (
        <Dialog
            open={isOpen}
            titleStyle={titleStyle}
            title="Atenção! Sua licença vai expirar"
        >
            <ModalContent onClose={handleClose} />
        </Dialog>
    );
};

export default WarningPopUp;
