enum Actions {
    LoadSession = 'LoadSession',

    // Authentication
    RequestLogIn = 'RequestLogIn',
    ReceiveLogIn = 'ReceiveLogIn',
    FailLogIn = 'FailLogIn',
    LogOut = 'LogOut',
    SetUserToken = 'SetUserToken',
    RequestUserSelf = 'RequestUserSelf',
    ReceiveUserSelf = 'ReceiveUserSelf',

    // Password
    RequestPasswordReset = 'RequestPasswordReset',
    ReceivePasswordReset = 'ReceivePasswordReset',
    RequestChangePassword = 'RequestChangePassword',
    ReceiveChangePassword = 'ReceiveChangePassword',

    // Subscriptions
    RequestSubscriptions = 'RequestSubscriptions',
    ReceiveSubscriptions = 'ReceiveSubscriptions',

    // User config
    SetUserSubscription = 'SetUserSubscription',
    ReceiveUserColorPalette = 'ReceiveUserColorPalette',

    // Preferences
    ToggleMenu = 'ToggleMenu',
    ChangeSiteListMode = 'ChangeSiteListMode',
    ChangeUserListMode = 'ChangeUserListMode',

    // Users
    RequestUsers = 'RequestUsers',
    ReceiveUsers = 'ReceiveUsers',
    RequestUser = 'RequestUser',
    ReceiveUser = 'ReceiveUser',
    RequestCreateUser = 'RequestCreateUser',
    ReceiveCreateUser = 'ReceiveCreateUser',
    FailCreateUser = 'FailCreateUser',
    RequestEditUser = 'RequestEditUser',
    ReceiveEditUser = 'ReceiveEditUser',
    RequestDeleteUser = 'RequestDeleteUser',
    ReceiveDeleteUser = 'ReceiveDeleteUser',

    // Sites
    RequestSites = 'RequestSites',
    ReceiveSites = 'ReceiveSites',
    RequestSite = 'RequestSite',
    ReceiveSite = 'ReceiveSite',
    RequestCreateSite = 'RequestCreateSite',
    ReceiveCreateSite = 'ReceiveCreateSite',
    RequestEditSite = 'RequestEditSite',
    ReceiveEditSite = 'ReceiveEditSite',
    RequestDeleteSite = 'RequestDeleteSite',
    ReceiveDeleteSite = 'ReceiveDeleteSite',

    // Sites Groups
    RequestSitesGroups = 'RequestSitesGroups',
    ReceiveSitesGroups = 'ReceiveSitesGroups',
    RequestSitesGroup = 'RequestSitesGroup',
    ReceiveSitesGroup = 'ReceiveSitesGroup',
    RequestCreateSitesGroup = 'RequestCreateSitesGroup',
    ReceiveCreateSitesGroup = 'ReceiveCreateSitesGroup',
    RequestEditSitesGroup = 'RequestEditSitesGroup',
    ReceiveEditSitesGroup = 'ReceiveEditSitesGroup',
    RequestDeleteSitesGroup = 'RequestDeleteSitesGroup',
    ReceiveDeleteSitesGroup = 'ReceiveDeleteSitesGroup',

    // Sdps
    RequestSdps = 'RequestSdps',
    ReceiveSdps = 'ReceiveSdps',
    RequestSdp = 'RequestSdp',
    ReceiveSdp = 'ReceiveSdp',
    RequestCreateSdp = 'RequestCreateSdp',
    ReceiveCreateSdp = 'ReceiveCreateSdp',
    RequestEditSdp = 'RequestEditSdp',
    ReceiveEditSdp = 'ReceiveEditSdp',
    RequestDeleteSdp = 'RequestDeleteSdp',
    ReceiveDeleteSdp = 'ReceiveDeleteSdp',

    // Demand Contracts
    RequestDemandContracts = 'RequestDemandContracts',
    ReceiveDemandContracts = 'ReceiveDemandContracts',
    RequestCreateDemandContract = 'RequestCreateDemandContract',
    ReceiveCreateDemandContract = 'ReceiveCreateDemandContract',
    FailCreateDemandContract = 'FailCreateDemandContract',
    RequestDeleteDemandContract = 'RequestDeleteDemandContract',
    ReceiveDeleteDemandContract = 'ReceiveDeleteDemandContract',

    // Contract Templates
    RequestContractTemplates = 'RequestContractTemplates',
    ReceiveContractTemplates = 'ReceiveContractTemplates',

    // Energy Contracts
    RequestEnergyContracts = 'RequestEnergyContracts',
    ReceiveEnergyContracts = 'ReceiveEnergyContracts',
    RequestCreateEnergyContract = 'RequestCreateEnergyContract',
    ReceiveCreateEnergyContract = 'ReceiveCreateEnergyContract',
    FailCreateEnergyContract = 'FailCreateEnergyContract',
    RequestEditEnergyContract = 'RequestEditEnergyContract',
    ReceiveEditEnergyContract = 'ReceiveEditEnergyContract',
    RequestDeleteEnergyContract = 'RequestDeleteEnergyContract',
    ReceiveDeleteEnergyContract = 'ReceiveDeleteEnergyContract',

    // Energy Providers
    RequestEnergyProviders = 'RequestEnergyProviders',
    ReceiveEnergyProviders = 'ReceiveEnergyProviders',

    // Consumption Goals
    RequestConsumptionGoals = 'RequestConsumptionGoals',
    ReceiveConsumptionGoals = 'ReceiveConsumptionGoals',
    RequestEditConsumptionGoal = 'RequestEditConsumptionGoal',
    ReceiveEditConsumptionGoal = 'ReceiveEditConsumptionGoal',
    RequestCreateConsumptionGoal = 'RequestCreateConsumptionGoal',
    ReceiveCreateConsumptionGoal = 'ReceiveCreateConsumptionGoal',
    FailCreateConsumptionGoal = 'FailCreateConsumptionGoal',
    RequestDeleteConsumptionGoal = 'RequestDeleteConsumptionGoal',
    ReceiveDeleteConsumptionGoal = 'ReceiveDeleteConsumptionGoal',

    // Demand TODO: Remove when metering tool is using graphql
    ReceiveCurrentDemand = 'ReceiveCurrentDemand',
    ReceiveIntraDayDemand = 'ReceiveIntraDayDemand',
    ReceiveDailyDemand = 'ReceiveDailyDemand',
    ReceiveMonthlyDemand = 'ReceiveMonthlyDemand',
    ReceiveMaxDemands = 'ReceiveMaxDemands',

    // Energy TODO: Remove when metering tool is using graphql
    ReceiveIntraDayConsumption = 'ReceiveIntraDayConsumption',
    ReceiveDailyConsumption = 'ReceiveDailyConsumption',
    ReceiveMonthlyConsumption = 'ReceiveMonthlyConsumption',
    ReceiveTotalConsumption = 'ReceiveTotalConsumption',
    ReceiveConsumptionRelation = 'ReceiveConsumptionRelation',

    // Power Factor TODO: Remove when metering tool is using graphql
    ReceiveCurrentPowerFactor = 'ReceiveCurrentPowerFactor',
    ReceiveIntraDayPowerFactor = 'ReceiveIntraDayPowerFactor',
    ReceiveDailyPowerFactor = 'ReceiveDailyPowerFactor',
    ReceiveMonthlyPowerFactor = 'ReceiveMonthlyPowerFactor',

    // Energy Bills
    ReceiveEnergyBills = 'ReceiveEnergyBills',
    ReceiveEnergyBill = 'ReceiveEnergyBill',
    RequestAllEnergyBills = 'RequestAllEnergyBills',
    ReceiveAllEnergyBills = 'ReceiveAllEnergyBills',
    RequestUploadBill = 'RequestUploadBill',
    ReceiveUploadBill = 'ReceiveUploadBill',
    RequestSimulatedBill = 'RequestSimulatedBill',
    ReceiveSimulatedBill = 'ReceiveSimulatedBill',
    FailSimulatedBill = 'FailSimulatedBill',

    // Energy Bill Alerts
    RequestEnergyBillAlerts = 'RequestEnergyBillAlerts',
    ReceiveEnergyBillAlerts = 'ReceiveEnergyBillAlerts',

    // Site Dashboard
    SelectDashboardSite = 'SelectDashboardSite',

    // Metering Tool TODO: Remove when metering tool is using graphql
    RequestMeteringToolReport = 'RequestMeteringToolReport',
    ReceiveMeteringToolReport = 'ReceiveMeteringToolReport',
    RequestMeteringToolStatistics = 'RequestMeteringToolStatistics',
    ReceiveMeteringToolStatistics = 'ReceiveMeteringToolStatistics',

    // Expenses Tool TODO: Remove when expenses tool is using graphql
    RequestExpensesToolData = 'RequestExpensesToolData',
    RequestExpensesToolDetails = 'RequestExpensesToolDetails',
    ClearSelectedBill = 'ClearSelectedBill',
    ClearSelectedSdpId = 'ClearSelectedSdpId',

    // Notifications
    AddNotification = 'AddNotification',
    RemoveLastNotification = 'RemoveLastNotification',

    // State Taxes
    RequestStateTaxes = 'RequestStateTaxes',
    ReceiveStateTaxes = 'ReceiveStateTaxes',
    RequestCreateStateTax = 'RequestCreateStateTax',
    ReceiveCreateStateTax = 'ReceiveCreateStateTax',
    RequestDeleteStateTax = 'RequestDeleteStateTax',
    ReceiveDeleteStateTax = 'ReceiveDeleteStateTax',

    // Energy Provider Taxes
    RequestEnergyProviderTaxes = 'RequestEnergyProviderTaxes',
    ReceiveEnergyProviderTaxes = 'ReceiveEnergyProviderTaxes',
    RequestCreateEnergyProviderTax = 'RequestCreateEnergyProviderTax',
    ReceiveCreateEnergyProviderTax = 'ReceiveCreateEnergyProviderTax',
    RequestDeleteEnergyProviderTax = 'RequestDeleteEnergyProviderTax',
    ReceiveDeleteEnergyProviderTax = 'ReceiveDeleteEnergyProviderTax',

    // Energy Provider Tariffs
    RequestEnergyProviderTariffs = 'RequestEnergyProviderTariffs',
    ReceiveEnergyProviderTariffs = 'ReceiveEnergyProviderTariffs',

    // Energy Tariff Flags
    RequestEnergyTariffFlags = 'RequestEnergyTariffFlags',
    ReceiveEnergyTariffFlags = 'ReceiveEnergyTariffFlags',

    // Alarms
    RequestAlarms = 'RequestAlarms',
    ReceiveAlarms = 'ReceiveAlarms',

    // Alarms Configs
    RequestAlarmsConfigs = 'RequestAlarmsConfigs',
    ReceiveAlarmsConfigs = 'ReceiveAlarmsConfigs',
    RequestAlarmsConfig = 'RequestAlarmsConfig',
    ReceiveAlarmsConfig = 'ReceiveAlarmsConfig',
    RequestCreateAlarmsConfig = 'RequestCreateAlarmsConfig',
    ReceiveCreateAlarmsConfig = 'ReceiveCreateAlarmsConfig',
    RequestEditAlarmsConfig = 'RequestEditAlarmsConfig',
    ReceiveEditAlarmsConfig = 'ReceiveEditAlarmsConfig',
    RequestDeleteAlarmsConfig = 'RequestDeleteAlarmsConfig',
    ReceiveDeleteAlarmsConfig = 'ReceiveDeleteAlarmsConfig',

    // Dashboard Templates
    RequestDashboardTemplates = 'RequestDashboardTemplates',
    ReceiveDashboardTemplates = 'ReceiveDashboardTemplates',
    RequestDashboardTemplate = 'RequestDashboardTemplate',
    ReceiveDashboardTemplate = 'ReceiveDashboardTemplate',
    RequestCreateDashboardTemplate = 'RequestCreateDashboardTemplate',
    ReceiveCreateDashboardTemplate = 'ReceiveCreateDashboardTemplate',
    RequestEditDashboardTemplate = 'RequestEditDashboardTemplate',
    ReceiveEditDashboardTemplate = 'ReceiveEditDashboardTemplate',
    RequestDeleteDashboardTemplate = 'RequestDeleteDashboardTemplate',
    ReceiveDeleteDashboardTemplate = 'ReceiveDeleteDashboardTemplate',

    // Customizable Dashboard
    SelectCustomizableDashboard = 'SelectCustomizableDashboard',

    // Comparison
    SelectComparison = 'SelectComparison',
    ChangeComparisonStartDate = 'ChangeComparisonStartDate',
    ChangeComparisonEndDate = 'ChangeComparisonEndDate',

    // Site State Taxes
    RequestSiteStateTaxes = 'RequestSiteStateTaxes',
    ReceiveSiteStateTaxes = 'ReceiveSiteStateTaxes',
    RequestSiteCreateStateTax = 'RequestSiteCreateStateTax',
    ReceiveSiteCreateStateTax = 'ReceiveSiteCreateStateTax',
    RequestSiteDeleteStateTax = 'RequestSiteDeleteStateTax',
    ReceiveSiteDeleteStateTax = 'ReceiveSiteDeleteStateTax',

    // Site DEVEC Taxes
    RequestSiteDEVECTaxes = 'RequestSiteDEVECTaxes',
    ReceiveSiteDEVECTaxes = 'ReceiveSiteDEVECTaxes',
    RequestEditSiteDEVECTax = 'RequestEditSiteDEVECTax',
    ReceiveEditSiteDEVECTax = 'ReceiveEditSiteDEVECTax',

    // Forecast measurements
    RequestForecastMeasurements = 'RequestForecastMeasurements',
    ReceiveForecastMeasurements = 'ReceiveForecastMeasurements',

    // Aggregated measurements
    RequestAggregatedMeasurements = 'RequestAggregatedMeasurements',
    ReceiveAggregatedMeasurements = 'ReceiveAggregatedMeasurements',
}

export { Actions };
