import { Actions } from 'actions/Actions';
import { Action } from 'redux';
import { DataRequest } from 'utils/DataRequest';
import { DateTime } from 'utils/DateTime';

interface ExpensesToolAction extends Action {
    sdpId?: string;
    billId?: number;
    startDate?: DateTime;
    endDate?: DateTime;
    uploaded?: boolean;
}

class ExpensesToolActions {

    public static requestBills = ({sdpId, startDate, endDate}: DataRequest): ExpensesToolAction => ({
        type: Actions.RequestExpensesToolData,
        sdpId,
        startDate,
        endDate
    });

    public static requestBill = (sdpId: string, billId: number, startDate: DateTime, endDate: DateTime): ExpensesToolAction => ({
        type: Actions.RequestExpensesToolDetails,
        sdpId,
        billId,
        startDate,
        endDate
    });

    public static clearSelectedBill = (): ExpensesToolAction => ({
        type: Actions.ClearSelectedBill
    });

    public static clearSelectedSdpId = (): ExpensesToolAction => ({
        type: Actions.ClearSelectedSdpId
    });
}

export {
    ExpensesToolAction,
    ExpensesToolActions
};
