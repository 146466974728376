import * as MobileDetect from 'mobile-detect';

class MobileDetector {
    mobileDetect: MobileDetect;

    constructor() {
        this.mobileDetect = new MobileDetect(window.navigator.userAgent);
    }

    isMobile(): boolean {
        return this.mobileDetect.mobile() !== null
            && this.mobileDetect.tablet() === null;
    }
}

export default new MobileDetector();
export { MobileDetector };
