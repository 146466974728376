import { Nullable } from 'utils/TypeUtils';

const EventUtils = {
    handleClickOutside({
        event,
        elementRef,
        onClickOutside
    }: {
        event: MouseEvent;
        elementRef: React.MutableRefObject<Nullable<HTMLElement>>;
        onClickOutside: () => void;
    }) {
        if (elementRef && elementRef.current) {
            const {
                top,
                bottom,
                left,
                right
            } = elementRef.current.getBoundingClientRect();

            const { clientX: x, clientY: y } = event;

            const isOutside = x < left || x > right || y < top || y > bottom;

            if (isOutside) {
                event.preventDefault();
                onClickOutside();
            }
        }
    },
    preventDefault(event: React.SyntheticEvent<any, any>) {
        event.preventDefault();
        event.stopPropagation();
    }
};

export default EventUtils;
