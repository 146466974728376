import { QueryLazyOptions } from '@apollo/react-hooks';
import { themeable, ThemeableProps } from 'components/interfaces/ThemeProvider';
import Logo from 'components/utils/Logo';
import MenuToggle from 'containers/base/MenuToggle';
import SiteAutoComplete from 'containers/utils/SiteAutoCompleteContainer';
import { Toggle } from 'material-ui';
import * as React from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';
import * as styles from 'styles/components/base/TopBar.module.scss';
import useSubscription from 'utils/hooks/useSubscription';
import MobileDetector from 'utils/MobileDetector';
import { UsersVariables } from 'utils/mutations/gqlTypes/Users';
import { Routes } from 'utils/Routes';

interface TopBarProps extends ThemeableProps {
    subscription: string;
    subscriptionId: string;
    handleRedirect: (variables: QueryLazyOptions<UsersVariables>) => Promise<void>;
    siteNameForced?: any;
    toggleState?: boolean;
    toggleLoading?: boolean;
    toggleAllowed?: boolean;
    isGlobalAdmin: boolean;
}

const TopBar = ({
    muiTheme,
    subscription,
    siteNameForced,
    toggleState,
    toggleLoading,
    handleRedirect,
    toggleAllowed,
    subscriptionId,
    isGlobalAdmin
}: TopBarProps) => {
    const [hasClicked, setHasClicked] = React.useState(false);
    const { hasFinishPHContract } = useSubscription();

    const style: React.CSSProperties = { backgroundColor: muiTheme.palette.topBarColor };
    const styleOi: React.CSSProperties = { color: muiTheme.palette.primary2Color };

    const isMobile = MobileDetector.isMobile();

    const siteAutoComplete = (props: RouteComponentProps<any>) => (
        <SiteAutoComplete
            {...props}
            className={styles.autoComplete}
            iconClassName={styles.iconClassName}
            inputContainerClassName={styles.inputContainer}
            inputContainerOi={styles.inputContainerOi}
            styleContainerOi={styleOi}
            siteNameForced={siteNameForced}
        />
    );

    const paths = [
        `/${subscription}/${Routes.Dashboard}`,
        `/${subscription}/${Routes.Bills}`,
        `/${subscription}/${Routes.LoadProfileCalendar}`,
        `/${subscription}/${Routes.PotentialSavingsView}`
    ];

    const routes = paths.map(path => (
        <Route
            path={path}
            component={siteAutoComplete}
            key={path}
        />
    ));

    const className = `${styles.topBar} ${isMobile ? styles.mobileBar : ''}`;

    const toggleRedirect = () => {
        if (!toggleState) {
            setHasClicked(true);
        }
        handleRedirect({
            variables: {
                subscriptionId
            }
        });
    };

    const deadLineMessage = (
        <span className={styles.deadLineMessage}>Atenção! Sua licença expira em 31/01/2024.</span>
    );

    const isAmbientAllowed = process.env.FLUX_TRANSITION_ALLOWED === 'true';
    const hasFinishContract = hasFinishPHContract(subscriptionId);

    return (
        <header
            className={className}
            style={style}
        >
           <div style={{ display: 'flex', alignItems: 'baseline' }}>
                <MenuToggle/>
                {hasFinishContract && (deadLineMessage)}
               {(toggleAllowed && isAmbientAllowed && !isGlobalAdmin) && <div style={{ marginLeft: '20px', whiteSpace: 'nowrap'}}>
                    <Toggle
                        label="Nova PowerHub"
                        toggled={toggleState}
                        disabled={toggleLoading || hasClicked}
                        onToggle={toggleRedirect}
                        id="new-powerhub"
                    />
               </div>}
            </div>
            <Logo className={styles.logo}/>
            {routes}
        </header>
    );
};

export default themeable()(TopBar);
export { TopBarProps };

