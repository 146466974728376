/* eslint-disable @typescript-eslint/camelcase */
import { CurrentUser } from 'models/system/CurrentUser';
import { Roles } from 'models/system/Roles';
import { User } from 'models/system/User';
import Perfume from 'perfume.js';
import * as ReactGA from 'react-ga';
import Conpass from 'utils/Conpass';
import Hotjar from 'utils/Hotjar';
import StringUtils from 'utils/StringUtils';
import { isNonNullable } from 'utils/TypeUtils';

const amplitude = require('amplitude-js');

const AMPLITUDE_TOKEN = '46c1b613c8d8d21ff9e73b13ccd11504';

enum Categories {
    Panels = 'Meus painéis',
    Bills = 'Faturas',
    Widgets = 'Widgets',
    Alarms = 'Alarmes',
    Notification = 'Notification',
    API = 'API',
    EnergyContracts = 'Contratos ACL',
    DemandContracts = 'Contratos de demanda',
    Dashboard = 'Dashboard',
    ConsumptionGoals = 'Metas de consumo',
    DataExport = 'Exportação de dados',
    Users = 'Usuários',
    LoadProfileCalendar = 'Perfil calendário'
}

enum Actions {
    Edit = 'Edição',
    Created = 'Criação',
    Deleted = 'Deleção',
    Read = 'Leitura',
    Error = 'Erro',
    Upload = 'Upload',
    View = 'Visualização',
    Download = 'Download',
    Calc = 'Calcular',
    SaveSimulation = 'Salvar simulação',
    Export = 'Exportar',
    Comparison = 'Comparações',
    Configuration = 'Configuração',
    Details = 'Detalhes',
    DailySearch = 'Pesquisa diária',
    PeriodSearch = 'Pesquisa por período',
    PageView = 'Page view',
    ExpandedView = 'Visão expandida',
}

interface Event {
    category: Categories;
    action: Actions;
    value?: number;
    qualifier?: string;
    options?: { [key: string]: string };
}

const identifyInAmplitude = (user: CurrentUser) => {
    const {
        subscriptionName,
        subscriptionId,
        role: userRole,
        id: userId
    } = user;

    amplitude.getInstance().init(AMPLITUDE_TOKEN, userId);
    amplitude.getInstance().setGroup('subscriptionId', subscriptionId);
    amplitude.getInstance().setUserProperties({
        userId,
        subscriptionId,
        subscriptionName,
        role: userRole
    });
};

const identifyInGA = (user: CurrentUser) => {
    const {
        subscriptionName,
        subscriptionId,
        id: userId
    } = user;

    const userRole = User.getRoleName(user);

    ReactGA.ga(() => {
        ReactGA.set({
            dimension5: userId,
            dimension6: subscriptionId
        });
    });

    ReactGA.set({
        userId,
        dimension1: subscriptionName,
        dimension2: userRole
    });
};

const identifyInConpass = async (user: CurrentUser, ip: any) => {
    const {
        subscriptionName: subscription,
        id: userId,
        username,
        fullName
    } = user;

    const role = User.getRoleName(user);

    (await Conpass)?.init({
        name: fullName,
        email: username,
        customFields: {
            userId,
            role,
            subscription,
            ip
        }
    });
};

const identifyInHotjar = () => {
    const tryIdentifyHotjar = async () => {
        const hotjarId = (await Hotjar)?.globals?.get('userId');
        if (hotjarId === undefined) {
            window.setTimeout(tryIdentifyHotjar, 500);
        }

        const dimension3 = hotjarId?.split('-')[0];

        if (dimension3) {
            ReactGA.set({ dimension3 });
        }
    };
    tryIdentifyHotjar();
};

const AnalyticsTools = {
    init() {
        ReactGA.initialize('UA-143607914-1');
        new Perfume({
            logging: false,
            largestContentfulPaint: true,
            analyticsTracker: ({metricName, duration}) => {
                if (duration) {
                    ReactGA.timing({
                        category: 'Page load',
                        variable: metricName,
                        value: duration
                    });
                }
            }
        });
    },

    identify(user: CurrentUser, ip: any) {
        identifyInHotjar();
        identifyInGA(user);
        identifyInConpass(user, ip);
        identifyInAmplitude(user);
    },

    async event({ category, action, value, qualifier, options }: Event, user?: CurrentUser) {
        const optionsLabel = options ? Object.entries(options).map(([key, option]) =>
        ` ${key}: ${option}`
        ) : '';

        const label = `${category} - ${action} ${qualifier || ''}`;
        const analyticsLabel = `${label} ${optionsLabel}`;

        const formattedOptions = options ?
            Object.entries(options).reduce<{ [key: string]: string } >(
                (newOptions, [key, val]) => {
                    newOptions[StringUtils.removeAccents(key)] = val;
                    return newOptions;
                }
            , {}) :
            undefined;

        ReactGA.event({
            category,
            action,
            label: analyticsLabel,
            value
        });

        amplitude.getInstance().logEvent(label, {
            category,
            action,
            value,
            ...formattedOptions
        });
    },

    async pageview(location: string, user: CurrentUser, siteName?: string) {
        const {
            id,
            subscriptionId,
            subscriptionName,
            role
        } = user;

        if (role !== Roles.GlobalAdmin) {
            const title = isNonNullable(siteName) ? `Site: ${siteName}` : '';

            ReactGA.pageview(location, undefined, title);

            const module = getModuleName(subscriptionName, location);
            const label = `${Actions.PageView} - ${module}`;

            amplitude.getInstance().logEvent(Actions.PageView, {
                page: location,
                'siteName': title,
                'userId': id,
                subscriptionId
            });
        }
    },

    catchException(error: string) {
        ReactGA.exception({ description: error });
    }
};

const getModuleName = (subscription: string, url: string) => {
    const formattedSub = StringUtils.toHyphenCase(subscription);
    const moduleUrl = url.substr(formattedSub.length + 2, url.length);

    const regex = new RegExp(/\/[a-f\d-]{36}/, 'g');
    const hasGuid = regex.test(moduleUrl);

    if (hasGuid) {
        return moduleUrl.replace(regex, '');
    }

    return moduleUrl;
};

AnalyticsTools.init();

export default AnalyticsTools;
export { Actions, Categories };

