import gql from 'graphql-tag';

const ListColorPalettes = gql`
    query ListColorPalettesQuery {
        themes {
            items {
                label
                name
                palette
            }
        }
    }
`;

export default ListColorPalettes;
