import SnackBar from 'components/interfaces/SnackBar';
import { FlatButton } from 'material-ui';
import { amber500, green500, indigo500, red500 } from 'material-ui/styles/colors';
import ActionCheckCircle from 'material-ui/svg-icons/action/check-circle';
import ActionInfo from 'material-ui/svg-icons/action/info';
import AlertError from 'material-ui/svg-icons/alert/error';
import AlertWarning from 'material-ui/svg-icons/alert/warning';
import NavigationClose from 'material-ui/svg-icons/navigation/close';
import * as React from 'react';
import NotificationType from 'utils/NotificationTypes';

interface NotificationProps {
    message: string;
    type: NotificationType;
    open: boolean;
    onNotificationClose?: () => void;
    closeNotification?: () => void;
}

interface NotificationState {}

class Notification extends React.Component<NotificationProps, NotificationState> {
    render() {
        const { message, type, open, onNotificationClose } = this.props;
        const style = {
            backgroundColor: this.getColor(type),
            height: 'auto',
            lineHeight: '1em',
            padding: '1em 1.5em',
            transition: 'transform 400ms cubic-bezier(0.23, 1, 0.32, 1) 0ms',
            transform: open ? '' : 'translate(0, 100%)',
            maxWidth: '100vw'
        };
        const containerStyle = {
            display: 'flex',
            alignItems: 'center',
        };
        const messageStyle = {
            flexGrow: 1,
            whiteSpace: 'pre-wrap' as any,
        };
        const icon = this.getIcon(type);
        const closeButton = this.getCloseButton();

        return (
            <SnackBar
                style={style}
                message={
                    <div style={containerStyle}>
                        { icon }
                        <span style={messageStyle}>
                            { message }
                        </span>
                        { closeButton }
                    </div>
                }
                open={open}
                onRequestClose={onNotificationClose}
            />
        );
    }

    private getColor(type: NotificationType): string {
        switch (type) {
            case NotificationType.Alert: return amber500;
            case NotificationType.Success: return green500;
            case NotificationType.Error: return red500;
            case NotificationType.Info: return indigo500;
        }
    }

    private getIcon(type: NotificationType): React.ReactNode {
        const style = {
            color: '#fff',
            marginRight: '1em',
            flexShrink: 0,
            opacity: 0.9,
        };

        switch (type) {
            case NotificationType.Alert: return <AlertWarning style={style}/>;
            case NotificationType.Success: return <ActionCheckCircle style={style}/>;
            case NotificationType.Error: return <AlertError style={style}/>;
            case NotificationType.Info: return <ActionInfo style={style}/>;
        }
    }

    private getCloseButton(): React.ReactNode {
        const { closeNotification } = this.props;
        const style = {
            color: '#fff',
            minWidth: 'auto',
            width: '3em',
            height: '3em',
            borderRadius: '50%',
            flexShrink: 0,
            marginLeft: '1em'
        };

        return (
            <FlatButton
                onClick={closeNotification}
                rippleColor="#fff"
                icon={<NavigationClose/>}
                style={style}
            />
        );
    }
}

export default Notification;
export { NotificationProps };
