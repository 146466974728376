import { Actions } from 'actions/Actions';
import { Action } from 'redux';
import ComparisonState from 'states/ComparisonState';

class ComparisonReducer {
    public reduce = (
        previousState = new ComparisonState(),
        action: Action
    ): ComparisonState => {
        switch (action.type) {
            case Actions.LoadSession:
                return this.loadSession(action);
            case Actions.SelectComparison:
                return this.select(action, previousState);
            case Actions.ChangeComparisonStartDate:
                return this.changeStartDate(action, previousState);
            case Actions.ChangeComparisonEndDate:
                return this.changeEndDate(action, previousState);
            case Actions.LogOut:
                return this.eraseSession();
            default:
                return previousState;
        }
    };

    private loadSession(action: any): ComparisonState {
        return (
            action.state.comparison || new ComparisonState()
        );
    }

    private eraseSession(): ComparisonState {
        return new ComparisonState();
    }

    private changeStartDate({startDate}: any, state: ComparisonState): ComparisonState {
        return {
            ...state,
            startDate
        };
    }

    private changeEndDate({endDate}: any, state: ComparisonState): ComparisonState {
        return {
            ...state,
            endDate
        };
    }

    private select({selected}: any, state: ComparisonState): ComparisonState {
        return {
            ...state,
            selected
        };
    }
}

export default ComparisonReducer;
