import { Actions } from 'actions/Actions';
import NotificationModel from 'models/NotificationModel';
import { Action } from 'redux';
import Analytics, { Actions as GAActions, Categories } from 'utils/AnalyticsTools';
import NotificationType from 'utils/NotificationTypes';

interface NotificationsAction extends Action {
    notification: NotificationModel;
}

class NotificationsActions {
    public static notifyError(message: string): NotificationsAction {
        Analytics.event({
            category: Categories.Notification,
            action: GAActions.Error,
            options: {
                'Erro': message
            }
        });

        return {
            type: Actions.AddNotification,
            notification: {
                message,
                notificationType: NotificationType.Error
            }
        };
    }

    public static notifyInfo(message: string): NotificationsAction {
        return {
            type: Actions.AddNotification,
            notification: {
                message,
                notificationType: NotificationType.Info
            }
        };
    }

    public static notifyAlert(message: string): NotificationsAction {
        return {
            type: Actions.AddNotification,
            notification: {
                message,
                notificationType: NotificationType.Alert
            }
        };
    }

    public static notifySuccess(message: string): NotificationsAction {
        return {
            type: Actions.AddNotification,
            notification: {
                message,
                notificationType: NotificationType.Success
            }
        };
    }

    public static removeLastNotification(): Action {
        return {
            type: Actions.RemoveLastNotification
        };
    }
}

export default NotificationsActions;
export { NotificationsAction };
