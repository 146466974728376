interface CancelablePromise<T> {
    promise: Promise<{isCanceled: true} | T>;
    cancel: () => void;
}

class PromiseUtils {
    public static makeCancelable<T>(promise: Promise<T>): CancelablePromise<T> {
        let hasCanceled = false;

        const wrappedPromise = new Promise<T>((resolve, reject) => {
            promise.then(
                val => hasCanceled ? reject({isCanceled: true}) : resolve(val),
                error => hasCanceled ? reject({isCanceled: true}) : reject(error)
            );
        });

        return {
            promise: wrappedPromise,
            cancel() {
                hasCanceled = true;
            }
        };
    }

    public static getGlobalProperty = async <T extends keyof Window>(
        property: T,
        interval = 300,
        maxTries = 100
    ): Promise<Window[T] | null> =>
        new Promise(resolve => {
            if (property in window) {
                resolve(window[property]);
            }

            let tryCount = 0;

            const intervalId = window.setInterval(() => {
                tryCount++;
                if (property in window) {
                    window.clearInterval(intervalId);
                    resolve(window[property]);
                }
                if (tryCount >= maxTries) {
                    window.clearInterval(intervalId);
                    resolve(null);
                }
            }, interval);
        });
}

export default PromiseUtils;
export {CancelablePromise};
