import { Actions } from 'actions/Actions';
import { EnergyBillAction } from 'actions/EnergyBillActions';
import { ExpensesToolAction } from 'actions/ExpensesToolActions';
import { EnergyBill } from 'models/bills/EnergyBill';
import { DataReducer } from 'reducers/Data';
import { Action } from 'redux';
import ExpensesToolState from 'states/ExpensesToolState';
import { DateTime } from 'utils/DateTime';
import { Nullable } from 'utils/TypeUtils';

class ExpensesToolReducer {

    private readonly billsReducer = new DataReducer<EnergyBill[]>({
        request: Actions.RequestExpensesToolData,
        receive: Actions.ReceiveEnergyBills
    });

    private readonly billReducer = new DataReducer<EnergyBill>({
        request: Actions.RequestExpensesToolDetails,
        receive: Actions.ReceiveEnergyBill
    });

    private readonly simulatedBillReducer = new DataReducer<EnergyBill>({
        request: Actions.RequestSimulatedBill,
        receive: Actions.ReceiveSimulatedBill
    });

    private readonly previousBillsReducer = new DataReducer<EnergyBill[]>({
        request: Actions.RequestExpensesToolDetails,
        receive: Actions.ReceiveEnergyBills
    });

    public reduce = (previousState = new ExpensesToolState(), action: Action): ExpensesToolState => {
        switch (action.type) {
            case Actions.RequestExpensesToolData:
                return this.setDataRequest(previousState, action as ExpensesToolAction);

            case Actions.ReceiveEnergyBills:
                const nextState = this.setBills(previousState, action as ExpensesToolAction);
                return this.setPreviousBills(nextState, action as ExpensesToolAction);

            case Actions.RequestExpensesToolDetails:
                return this.setDetailsRequest(previousState, action as ExpensesToolAction);

            case Actions.ReceiveEnergyBill:
                return this.setBill(previousState, action as ExpensesToolAction);

            case Actions.RequestUploadBill:
                return this.setUploading(previousState, action as EnergyBillAction);

            case Actions.ReceiveUploadBill:
                return this.setUploaded(previousState, action as EnergyBillAction);

            case Actions.RequestSimulatedBill:
                return this.setSimulating(previousState);

            case Actions.ReceiveSimulatedBill:
                return this.setSimulated(previousState, action as EnergyBillAction);

            case Actions.FailSimulatedBill:
                return this.failSimulated(previousState);

            case Actions.ClearSelectedBill:
                return this.clearBill(previousState);

            case Actions.ClearSelectedSdpId:
                return this.clearSelectedSdpId(previousState);

            default:
                return previousState;
        }
    };

    private setDataRequest(previousState: ExpensesToolState, action: ExpensesToolAction): ExpensesToolState {
        return {
            ...previousState,
            sdpId: action.sdpId,
            startDate: this.toTimeStamp(action.startDate),
            endDate: this.toTimeStamp(action.endDate),
            bills: this.billsReducer.reduce(previousState.bills, action)
        };
    }

    private setBills(previousState: ExpensesToolState, action: ExpensesToolAction): ExpensesToolState {
        return this.shouldUpdate(previousState, action) ? {
            ...previousState,
            sdpId: action.sdpId,
            startDate: this.toTimeStamp(action.startDate),
            endDate: this.toTimeStamp(action.endDate),
            bills: this.billsReducer.reduce(previousState.bills, action)
        } : previousState;
    }

    private setDetailsRequest(previousState: ExpensesToolState, action: ExpensesToolAction): ExpensesToolState {
        const newAction = { type: action.type, sdpId: action.sdpId };
        return {
            ...previousState,
            bill: this.billReducer.reduce(previousState.bill, newAction),
            previousBills: this.previousBillsReducer.reduce(previousState.previousBills, action)
        };
    }

    private setBill(previousState: ExpensesToolState, action: ExpensesToolAction): ExpensesToolState {
        return this.sdpIdChangeIsValid(previousState.sdpId, action.sdpId) ? {
            ...previousState,
            sdpId: action.sdpId,
            bill: this.billReducer.reduce(previousState.bill, action),
            previousBills: previousState.previousBills
        } : previousState;
    }

    private setPreviousBills(previousState: ExpensesToolState, action: ExpensesToolAction): ExpensesToolState {
        return this.sdpIdChangeIsValid(previousState.sdpId, action.sdpId) ? {
            ...previousState,
            sdpId: action.sdpId,
            previousBills: this.previousBillsReducer.reduce(previousState.previousBills, action)
        } : previousState;
    }

    private setUploading(previousState: ExpensesToolState, action: ExpensesToolAction): ExpensesToolState {
        return this.sdpIdChangeIsValid(previousState.sdpId, action.sdpId) ? {
            ...previousState,
            isUploading: true,
            uploaded: false
        } : previousState;
    }

    private setUploaded(previousState: ExpensesToolState, action: ExpensesToolAction): ExpensesToolState {
        return this.sdpIdChangeIsValid(previousState.sdpId, action.sdpId) ? {
            ...previousState,
            isUploading: false,
            uploaded: action.uploaded || false
        } : previousState;
    }

    private clearBill(previousState: ExpensesToolState): ExpensesToolState {
        return {
            ...previousState,
            bill: undefined
        };
    }

    private clearSelectedSdpId(previousState: ExpensesToolState): ExpensesToolState {
        return {
            ...previousState,
            sdpId: null
        };
    }

    private setSimulating(previousState: ExpensesToolState): ExpensesToolState {
        return {
            ...previousState,
            isSimulating: true
        };
    }

    private setSimulated(previousState: ExpensesToolState, action: EnergyBillAction): ExpensesToolState {
        return {
            ...previousState,
            bill: this.simulatedBillReducer.reduce(previousState.bill, action),
            isSimulating: false
        };
    }

    private failSimulated(previousState: ExpensesToolState): ExpensesToolState {
        return {
            ...previousState,
            isSimulating: false
        };
    }

    private shouldUpdate(previousState: ExpensesToolState, action: ExpensesToolAction): boolean {
        return this.sdpIdChangeIsValid(previousState.sdpId, action.sdpId) &&
               previousState.startDate === this.toTimeStamp(action.startDate) &&
               previousState.endDate === this.toTimeStamp(action.endDate);
    }

    private sdpIdChangeIsValid(previousSdpId: Nullable<string>, nextSdpId: Nullable<string>) {
        return !previousSdpId || previousSdpId === nextSdpId;
    }

    private toTimeStamp(date: Nullable<string | DateTime>): string {
        if (!date) {
            return '';
        }

        return DateTime.isDateTime(date) ?
            (date as DateTime).toISOString() :
            date as string;
    }

}

export { ExpensesToolReducer };
