import MuiMenu from 'material-ui/Menu';
import * as React from 'react';

const Menu: React.FunctionComponent = ({
    children
}) => (
    <MuiMenu autoWidth={true}>
        {children}
    </MuiMenu>
);

export { Menu };
