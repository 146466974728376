import { SelectFieldOption } from 'components/interfaces/SelectField';

enum Measurement {
    Demand = 'demand',
    Energy = 'energy',
    PowerFactor = 'power-factor'
}

const getName = (measurement: Measurement) => {
    switch (measurement) {
        case Measurement.Demand:
            return 'Demanda';
        case Measurement.Energy:
            return 'Consumo';
        case Measurement.PowerFactor:
            return 'Fator de potência';
        default:
            return null;
    }
};

const measurementToSelectFieldOption = (type: Measurement): SelectFieldOption => ({
    text: getName(type),
    value: type
});

const consumptionSelectFieldOptions: SelectFieldOption[] = [
    Measurement.Energy
].map(measurement => ({
    text: getName(measurement),
    value: measurement
})).filter(({ text }) => text !== null);


const measurementsSelectFieldOptions: SelectFieldOption[] = Object.values(Measurement)
    .map(measurement => ({
        text: getName(measurement),
        value: measurement
    }))
    .filter(option => option.text !== null);

export {
    Measurement,
    consumptionSelectFieldOptions,
    measurementsSelectFieldOptions,
    measurementToSelectFieldOption
};
