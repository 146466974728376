import { QueryLazyOptions } from '@apollo/react-hooks';
import { FontIcon } from 'components/interfaces/FontIcon';
import { Dialog } from 'material-ui';
import * as React from 'react';
import * as styles from 'styles/components/base/PopUpBar.module.scss';
import { UsersVariables } from 'utils/mutations/gqlTypes/Users';

interface ModalProps {
    isModalOpen: boolean;
    handleRedirect: (variables: QueryLazyOptions<UsersVariables>) => Promise<void>;
    closeModal: () => void;
    subscriptionId: string;
}

interface ModalContentProps {
    handleRedirect: (variables: QueryLazyOptions<UsersVariables>) => Promise<void>;
    subscriptionId: string;
}

const ModalContent = ({ handleRedirect, subscriptionId }: ModalContentProps) => {
    const [hasClicked, setHasClicked] = React.useState(false);
    const toggleRedirect = () => {
        setHasClicked(true);
        handleRedirect({
            variables: {
                subscriptionId
            }
        });
    };

    return (
        <div className={styles.modalContainer}>
            <img alt="ad" src="../../../../images/redirectAD.svg" />
            <div className={styles.disclaimer}>
                <h1>
                    Descubra a nova PowerHub
                </h1>
                <h2 className={styles.subText}>
                    <p>Novos recursos e as funcionalidades mais utilizadas já estão disponíveis na nova PowerHub.</p>
                    <br />
                    <p>Agora, as atualizações serão mais constantes, tornando a sua experiência ainda mais completa.</p>
                    <br />
                    <p>Acesse já e venha conferir!</p>
                </h2>
                <button
                    type="button"
                    disabled={hasClicked}
                    className={styles.redirectButtonModal}
                    onClick={toggleRedirect}
                    id="get-powerhub"
                >
                    OBTER A NOVA POWERHUB
                </button>
            </div>
        </div>
    );
};

const PopUpModal = ({ isModalOpen, handleRedirect, closeModal, subscriptionId }: ModalProps) => (
    <Dialog
        open={isModalOpen}
    >
        <header className={styles.closeModal}>
            <button type="button" onClick={closeModal}>
                <FontIcon type="close" color="black" />
            </button>
        </header>
        {<ModalContent handleRedirect={handleRedirect} subscriptionId={subscriptionId} />}
    </Dialog>
);


function NewExperiencePopUp(
    {
        handleRedirect,
        subscriptionId
    }: {
        handleRedirect: (variables: QueryLazyOptions<UsersVariables>) => Promise<void>;
        subscriptionId: string;
    }
) {
    const [isOpen, setIsOpen] = React.useState(true);
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const closeBar = () => setIsOpen(false);
    const topBarStyles = `${styles.topBar} ${isOpen ? '' : styles.close}`;
    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    return (
        <header className={topBarStyles}>
            <div className={styles.disclaimerContainer}>
                <p>
                    <span className={styles.spanText}>
                        Chegou a nova PowerHub
                    </span>, mais rápida e com a aparência atualizada
                </p>
                <button className={styles.redirectButtonBar} type="button" onClick={openModal}>
                    Entrar na nova <span className={styles.spanText}>PowerHub</span>
                </button>
            </div>
            <div className={styles.closeContainer}>
                <button className={styles.closeButton} type="button" onClick={closeBar}>
                    <FontIcon type="close" color="black" />
                </button>
            </div>
            <PopUpModal
                isModalOpen={isModalOpen}
                handleRedirect={handleRedirect}
                closeModal={closeModal}
                subscriptionId={subscriptionId}
            />
        </header>
    );
}
export default NewExperiencePopUp;
