import Fade from '@material-ui/core/Fade';
import MuiLinearProgress from 'material-ui/LinearProgress';
import { blueGrey100 } from 'material-ui/styles/colors';
import * as React from 'react';

const style: React.CSSProperties = {
    backgroundColor: blueGrey100,
    width: '75%',
    margin: '0 auto',
    top: '50%',
    marginTop: -2
};

const fadeStyle: React.CSSProperties = {
    transitionDelay: '500ms'
};

interface LinearProgressProps {
    style?: React.CSSProperties;
}

const LinearProgress = (props: LinearProgressProps) => (
    <div className="linear-progress">
        <Fade
            in={true}
            style={fadeStyle}
            unmountOnExit={true}
        >
            <MuiLinearProgress
                mode="indeterminate"
                style={{ ...style, ...props.style }}
            />
        </Fade>
    </div>
);

export { LinearProgress, LinearProgressProps };
