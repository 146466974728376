import { themeable, ThemeableProps } from 'components/interfaces/ThemeProvider';
import MuiDialog from 'material-ui/Dialog';
import * as React from 'react';

interface DialogProps extends ThemeableProps {
    open: boolean;
    onRequestClose: () => void;
    titleStyle?: React.CSSProperties;
    contentStyle?: React.CSSProperties;
    bodyStyle?: React.CSSProperties;
    style?: React.CSSProperties;
    modal?: boolean;
    className?: string;
    title?: React.ReactNode;
    actions: React.ReactElement[];
    children: React.ReactNode;
}

const Dialog: React.FunctionComponent<DialogProps> = ({
    muiTheme,
    titleStyle,
    bodyStyle,
    children,
    modal = false,
    ...restProps
}) => {
    const { palette } = muiTheme;

    const headerColors: React.CSSProperties = {
        backgroundColor: palette.primary1Color,
        color: palette.alternateTextColor
    };

    const autoScrollY: React.CSSProperties = {
        overflowY: 'auto'
    };

    return (
        <MuiDialog
            titleStyle={ { ...headerColors, ...titleStyle } }
            bodyStyle={ { ...autoScrollY, ...bodyStyle } }
            modal={ modal }
            { ...restProps }
        >
            { children }
        </MuiDialog>
    );
};

export default themeable()(Dialog);
