import { remove as removeAccents } from 'remove-accents';
import { isNullable, Nullable } from 'utils/TypeUtils';

const memoizeFormatConstructor = require('intl-format-cache');

class StringUtils {

    private static memoizedFormatter = memoizeFormatConstructor(
        Intl.NumberFormat
    );

    public static toFixed(
        value: Nullable<number>,
        precision: Nullable<number> = 2,
        minimumFractionDigits?: Nullable<number>,
        maximumFractionDigits?: Nullable<number>
    ): string {
        if (isNullable(value) || isNaN(value)) {
            return '';
        }

        if (precision === 0) {
            value = Math.round(value);
        }

        const numberFormatter = this.memoizedFormatter('pt-BR', {
            minimumFractionDigits: minimumFractionDigits !== undefined ? minimumFractionDigits : precision,
            maximumFractionDigits: maximumFractionDigits !== undefined ? maximumFractionDigits : precision
        });

        return numberFormatter.format(value);
    }

    public static toMaxPrecisionFixed(value: number | null) {
        return StringUtils.toFixed(value, null, 0, 20);
    }

    public static toPowerFactorScale(value: number): string {
        if (!value) {
            return '';
        }

        if (Math.abs(value) === 1) {
            return '1';
        }

        const unity = value > 0 ? 'Ind' : 'Cap';
        const absoluteValue = Math.abs(value);
        return `${StringUtils.toFixed(absoluteValue, 3)} ${unity}`;
    }

    public static textMatch(text: Nullable<string>, searchText: string): boolean {
        if (isNullable(text)) {
            return false;
        }
        const fixedSearchText = this.removeAccents(searchText).toLowerCase();
        const fixedText = this.removeAccents(text).toLowerCase();
        return fixedText.includes(fixedSearchText);
    }

    public static toHyphenCase(text: string): string {
        return this.removeAccents(text)
            .split(' ')
            .join('-')
            .toLowerCase();
    }

    public static capitalize(text: string): string {
        if (text?.length) {
            return text.replace(/(^|\s)\S/g, l => l.toUpperCase());
        }
        return text;
    }

    public static compare(stringA: string, stringB: string): number {
        return stringA.toLowerCase().localeCompare(stringB.toLowerCase());
    }

    public static removeAccents(text: string): string {
        return removeAccents(text);
    }

    public static formattedToFloat(value: Nullable<string>) {
        if (isNullable(value) || value === '') {
            return null;
        }

        return parseFloat(value.toString().replace(/\./g, '').replace(',', '.'));
    }

    public static isJson(value: string) {
        try {
            JSON.parse(value);
        } catch {
            return false;
        }
        return true;
    }
}

export default StringUtils;
