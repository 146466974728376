import MuiTextField from 'material-ui/TextField';
import * as React from 'react';
import ObjectUtils from 'utils/ObjectUtils';
import { isNonNullable } from 'utils/TypeUtils';

interface TextFieldProps {
    type: string;
    name?: string;

    label?: string;
    hintText?: string;
    errorText?: string;
    placeholder?: string;

    fixedLabel?: boolean;

    className?: string;

    value?: string;
    disabled?: boolean;
    onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onBlur?: React.FocusEventHandler<{}>;

    style?: React.CSSProperties;
    inputStyle?: React.CSSProperties;
    errorStyle?: React.CSSProperties;
    underlineShow?: boolean;

    min?: number;
    max?: number;

    autoComplete?: string;
    autoFocus?: boolean;
}

class TextField extends React.Component<TextFieldProps> {
    public static defaultProps: Partial<TextFieldProps> = {
        className: ''
    };

    private textFieldRef = React.createRef<MuiTextField>();

    public shouldComponentUpdate(nextProps: TextFieldProps): boolean {
        const {
            label,
            hintText,
            errorText,
            fixedLabel,
            className,
            value,
            disabled,
            placeholder,
            style = {},
            inputStyle = {},
            errorStyle = {}
        } = this.props;

        return (
            label !== nextProps.label ||
            hintText !== nextProps.hintText ||
            errorText !== nextProps.errorText ||
            fixedLabel !== nextProps.fixedLabel ||
            className !== nextProps.className ||
            value !== nextProps.value ||
            disabled !== nextProps.disabled ||
            placeholder !== nextProps.placeholder ||
            ((!!style || !!nextProps.style) &&
                ObjectUtils.shallowEquals(style, nextProps.style || {})) ||
            ((!!inputStyle || !!nextProps.inputStyle) &&
                ObjectUtils.shallowEquals(inputStyle, nextProps.inputStyle || {})) ||
            ((!!errorStyle || !!nextProps.errorStyle) &&
                ObjectUtils.shallowEquals(errorStyle, nextProps.errorStyle || {}))
        );
    }

    public render() {
        const {
            fixedLabel,
            label,
            style,
            className,
            ...restProps
        } = this.props;

        const elementStyle = { width: '100%' };

        const textFieldClass = `text-field ${className}`;

        return (
            <MuiTextField
                className={textFieldClass}
                ref={this.textFieldRef}
                floatingLabelFixed={fixedLabel}
                floatingLabelText={label}
                style={{ ...elementStyle, ...style }}
                {...restProps}
            />
        );
    }

    public clearInput = (): void => {
        if (isNonNullable(this.textFieldRef.current)) {
            const input = this.textFieldRef.current.getInputNode();
            input.value = '';
        }
    };

    public getInputNode = (): (HTMLInputElement | null) => {
        if (isNonNullable(this.textFieldRef.current)) {
            return this.textFieldRef.current.getInputNode();
        }
        return null;
    };

    public focusInput = (): void => {
        if (isNonNullable(this.textFieldRef.current)) {
            const input = this.textFieldRef.current.getInputNode();
            input.focus();
        }
    };
}

export { TextField, TextFieldProps };
