import { QueryResult } from '@apollo/react-common';
import { Query } from '@apollo/react-components';
import { LinearProgress } from 'components/interfaces/LinearProgress';
import { createTheme, ThemeProvider } from 'components/interfaces/ThemeProvider';
import { fallbackPalette } from 'palette';
import * as React from 'react';
import { connect } from 'react-redux';
import { AppState } from 'states/App';
import { ListColorPalettesQuery } from 'utils/queries/gqlTypes/ListColorPalettesQuery';
import ListColorPalettes from 'utils/queries/ListColorPalettes';
import StringUtils from 'utils/StringUtils';
import { isNullable } from 'utils/TypeUtils';

interface MapQueryToComponentProps {
    themeName: string;
}

function mapStateToProps(
    { user }: AppState,
    { children }: { children: React.ReactNode }
): React.PropsWithChildren<MapQueryToComponentProps> {
    const themeName = user.settings?.colorPalette;

    return {
        themeName,
        children
    };
}

const MapQueryToComponent: React.FunctionComponent<MapQueryToComponentProps> = ({
    themeName,
    children
}) => (
    <Query
        query={ListColorPalettes}
        errorPolicy="ignore"
        partialRefetch={true}
    >
        {({data, loading}: QueryResult<ListColorPalettesQuery>) => {
            const items = data?.themes?.items;

            const colorPalette = (
                loading || isNullable(items)
            ) ? null : items.find(item => item?.name === themeName);

            let palette = fallbackPalette;
            let paletteName = 'temp';

            if (colorPalette && StringUtils.isJson(colorPalette.palette)) {
                palette = JSON.parse(colorPalette.palette);
                paletteName = themeName;
            }

            const theme = createTheme(paletteName, palette);
            const content = loading ? <LinearProgress/> : children;

            return (
                <ThemeProvider theme={theme}>
                    {content}
                </ThemeProvider>
            );
        }}
    </Query>
);

export default connect(mapStateToProps)(MapQueryToComponent);
