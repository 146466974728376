import gql from 'graphql-tag';

const GetSubscriptionBasicInfo = gql`
  query SubscriptionBasicInfo($subscriptionId: String!) {
    subscriptionBasicInfo (subscriptionId: $subscriptionId) {
      id
      imageUrl
      isNewExperiencieAllowed
      name
      styleKit {
        menuBgColor
        menuTextColor
        primaryBgColor
        primaryTextColor
        applyToTopBar
      }
    }
  }
`;

export default GetSubscriptionBasicInfo;