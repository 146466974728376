enum Routes {
    AboutPage = 'sobre',
    Administration = 'administracao',
    Bills = 'faturas/:id',
    BillsAudit = 'auditoria',
    DataExport = 'exportacao',
    Themes = 'temas',
    CustomDashboardList = 'meus-paineis',
    CustomDashboard = 'meus-paineis/:id',
    CustomDashboardETLList = 'paineis-integrados',
    CustomDashboardETL = 'paineis-integrados/:reportId/:groupId',
    Dashboard = 'dashboard/:id',
    LoadProfileCalendar = 'calendario/:id',
    Login = 'login',
    Logout = 'logout',
    Alarms = 'alarmes/:date(\\d{4}-\\d{2}-\\d{2})?/:endDate(\\d{4}-\\d{2}-\\d{2})?',
    AlarmsConfigs = 'alarmes/configuracoes',
    PasswordRecovery = 'password-recovery',
    PasswordRequest = 'password-recovery/success',
    PasswordChange = 'password-reset/:token',
    PasswordSuccess = 'password-reset/success',
    SitesList = 'sites',
    SitesCreate = 'sites/criar',
    SitesView = 'sites/:id/:tab?/:subTab?',
    SitesGroupsList = 'grupos',
    SitesGroupsView = 'grupos/:id',
    SitesGroupCreate = 'grupos/criar',
    Subscriptions = 'assinaturas',
    UsersIndex = 'usuarios',
    UsersCreate = 'usuarios/criar',
    UsersEdit = 'usuarios/editar/:id',
    Comparison = 'comparacoes',
    PotentialSavingsList = 'potenciais-de-ganho',
    PotentialSavingsView = 'potenciais-de-ganho/:id/:tab?',
    RemoteTrackingPowerBi = 'acompanhamento-remotas'
}

export { Routes };
