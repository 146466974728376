import { themeable, ThemeableProps } from 'components/interfaces/ThemeProvider';
import PasswordReset from 'containers/utils/PasswordReset';
import { CurrentUser } from 'models/system/CurrentUser';
import * as React from 'react';
import * as styles from 'styles/components/utils/Profile.module.scss';
import Analytics from 'utils/AnalyticsTools';

const publicIp = require('public-ip');

interface ProfileProps extends ThemeableProps {
    user: CurrentUser;
    className?: string;
}

interface ProfileState {
    passwordReset: boolean;
    hideImage: boolean;
}

class Profile extends React.Component<ProfileProps, ProfileState> {
    public state: ProfileState = {
        passwordReset: false,
        hideImage: true
    };

    public async componentDidMount() {
        const ip = await publicIp.v4({
            fallbackUrls: [ 'https://ifconfig.co/ip' ]
        });
        Analytics.identify(this.props.user, ip);
    }

    public render(): React.ReactNode {
        const { user, muiTheme, className } = this.props;

        const { passwordReset, hideImage } = this.state;

        const { fullName, subscriptionName: userSubscriptionName, settings } = user;

        const profileClassName = `${styles.profile} ${className || ''}`;
        const pictureClassName = `${styles.picture} ${
            hideImage ? styles.hidden : ''
        }`;

        const subscriptionName = userSubscriptionName || '';
        const formattedSubscriptionName = subscriptionName.toUpperCase();

        const logoUrl = settings?.imageUrl;

        const infoStyle: React.CSSProperties = {
            color: muiTheme.palette.alternateMenuTextColor
        };

        return (
            <div className={ profileClassName }>
                <img
                    className={ pictureClassName }
                    src={ logoUrl }
                    alt={ subscriptionName }
                    onLoad={ this.handleImageLoaded }
                />
                <div className={ styles.info } style={ infoStyle }>
                    <h2 className={ styles.subscriptionName }>
                        { formattedSubscriptionName }
                    </h2>
                    <span className={ styles.userName }>{ fullName }</span>
                    <a
                        onClick={ this.openPasswordReset }
                        className={ styles.changePasswordLink }
                    >
                        Alterar senha
                    </a>
                </div>
                <PasswordReset
                    open={ passwordReset }
                    onClose={ this.closePasswordReset }
                />
            </div>
        );
    }

    private openPasswordReset = (): void => {
        this.setState({ passwordReset: true });
    };

    private closePasswordReset = (): void => {
        this.setState({ passwordReset: false });
    };

    private handleImageLoaded = (): void => {
        this.setState({ hideImage: false });
    };
}

export default themeable()(Profile);
export { ProfileProps };
