import {
    MeasurementReport,
    MeasurementStatistics
} from 'models/timeseries/MeasurementReport';
import { DataState } from 'states/Data';
import MeteringToolDataState from 'states/MeteringToolDataState';

class MeteringToolState {
    public sdpId: string;
    public report = new DataState<MeasurementReport>();
    public statistics = new DataState<MeasurementStatistics>();
    public data: { [sdpId: string]: MeteringToolDataState } = {};
}

export default MeteringToolState;
