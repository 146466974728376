import * as React from 'react';

interface InlineSvgProps {
    svgContent: string;
    className?: string;
    style?: React.CSSProperties;
}

interface InlineSvgState {}

class InlineSVG extends React.Component<InlineSvgProps, InlineSvgState> {
  render() {
    const {style, svgContent, className} = this.props;

    return (
        <svg
            className={className}
            style={style}
            dangerouslySetInnerHTML={{ __html: svgContent }}
        />
    );
  }
}

export default InlineSVG;
export { InlineSvgProps };
