import Dialog from 'components/interfaces/Dialog';
import { FlatButton } from 'components/interfaces/FlatButton';
import { CurrentUser } from 'models/system/CurrentUser';
import * as React from 'react';

interface PasswordResetProps {
    user?: CurrentUser;
    open?: boolean;
    passwordReset?: boolean;
    onResetPassword?: (email: string) => void;
    onClose?: () => void;
}

interface PasswordResetState {
    reseted: boolean;
}

class PasswordReset extends React.Component<PasswordResetProps, PasswordResetState> {

    componentWillMount(): void {
        this.setState({ reseted: false });
    }

    componentWillReceiveProps(nextProps: PasswordResetProps): void {
        if (nextProps.passwordReset) {
            this.setState({ reseted: true });
        }
    }

    render(): React.ReactElement<any> | false {
        const {
            open,
            onClose
        } = this.props;

        const message = this.getMessage();
        const actionButtons = this.getActionButtons();

        return (
            <Dialog
                className="password-reset"
                title="Alteração de senha"
                open={open}
                actions={actionButtons}
                onRequestClose={() => this.closeDialog()}
            >
                {message}
            </Dialog>
        );
    }

    private getMessage(): React.ReactNode {
        return this.state.reseted ?
            <p>As instruções para alteração da senha foram enviadas para o seu e-mail cadastrado.</p> :
            <p>Caso deseje alterar a sua senha, enviaremos um e-mail com as instruções para a alteração da senha para você. Tem certeza que deseja alterar?</p>;
    }

    private getActionButtons(): React.ReactElement<any>[] {
        return this.state.reseted ?
            [
                <FlatButton
                    type="button"
                    label="OK"
                    onClick={() => this.closeDialog()}
                />
            ] :
            [
                <FlatButton
                    type="button"
                    label="Cancelar"
                    onClick={() => this.closeDialog()}
                />,
                <FlatButton
                    type="button"
                    label="Alterar"
                    onClick={() => this.resetPassword()}
                />
            ];
    }

    private resetPassword(): void {
        this.props.onResetPassword(this.props.user.username);
    }

    private closeDialog() {
        this.setState({ reseted: false });
        this.props.onClose();
    }

}

export {
    PasswordReset,
    PasswordResetProps
};
