enum Roles {
    User = 'user',
    SubscriptionAdmin = 'subscriptionadmin',
    GlobalAdmin = 'globaladmin',
    AdvancedUser = 'advanceduser'
}

const roleValues: {[role in Roles]: number} = {
    [Roles.User]: 0,
    [Roles.AdvancedUser]: 1,
    [Roles.SubscriptionAdmin]: 2,
    [Roles.GlobalAdmin]: 3
};

const getRoleValue = (role: Roles): number => roleValues[role];

const getRoleFromValue = (roleValue: number) => (
    (Object.keys(roleValues) as (keyof typeof roleValues)[]).find(
        key => roleValues[key] === roleValue
    )
);

export { Roles, getRoleValue, getRoleFromValue };
