import PagedList from 'models/server/PagedList';
import ServerError from 'models/server/ServerError';

type ServerResponse<T> = T | ServerError;
type ServerPagedResponse<T> = ServerResponse<PagedList<T>>;

function isServerError(response: ServerResponse<any>): response is ServerError {
    return (
        (response as ServerError).errors !== undefined ||
        (response as ServerError).Errors !== undefined
    );
}

export { ServerResponse, ServerPagedResponse, isServerError };
