import { Actions } from 'actions/Actions';
import { Action } from 'redux';
import { DashboardState } from 'states/Dashboard';

class DashboardReducer {

    public reduce = (previousState = new DashboardState(), action: Action): DashboardState => {
        switch (action.type) {
            case Actions.LoadSession:
                return this.loadSession(action);
            case Actions.SelectDashboardSite:
                return this.selectId(previousState, action);
            case Actions.LogOut:
                return this.eraseSession();
            default:
                return previousState;
        }
    };

    private loadSession(action: any): DashboardState {
        return action.state.dashboard || new DashboardState();
    }

    private eraseSession(): DashboardState {
        return new DashboardState();
    }

    private selectId(previousState: DashboardState, action: any): DashboardState {
        return {
            ...previousState,
            id: action.id
        };
    }
}

export { DashboardReducer };
