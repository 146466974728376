import Notification from 'components/utils/Notification';
import NotificationModel from 'models/NotificationModel';
import * as React from 'react';
import NotificationType from 'utils/NotificationTypes';

interface NotificationManagerProps {
    notifications?: NotificationModel[];
    onNotificationClose?: () => void;
}
interface NotificationManagerState {
    currentType: NotificationType;
    currentText: string;
    open: boolean;
}

class NotificationManager extends React.Component<NotificationManagerProps, NotificationManagerState> {
    componentWillReceiveProps(nextProps: NotificationManagerProps) {
        const { notifications } = nextProps;
        if (notifications.length > 0) {
            const latest = notifications[notifications.length - 1];

            this.setState({
                currentType: latest.notificationType,
                currentText: latest.message,
                open: true
            });
        }
        else {
            this.setState({
                open: false
            });
        }
    }

    render() {
        if (!this.state) {
            return false;
        }
        const { notifications } = this.props;
        const { open, currentText, currentType } = this.state;
        const latest = notifications[notifications.length - 1];
        let message: string;
        let notificationType: NotificationType;
        if (!latest) {
            message = currentText;
            notificationType = currentType;
        }
        else {
            ({ message, notificationType } = latest);
        }

        return (
            <Notification
                message={message}
                open={open}
                type={notificationType}
                onNotificationClose={() => this.handleClose()}
                closeNotification={() => this.handleClose()}
            />
        );
    }

    private handleClose() {
        this.setState({
            open: false
        });
        setTimeout(() => {
            this.props.onNotificationClose();
        }, 500);
    }
}

export default NotificationManager;
export { NotificationManagerProps };
