import { ListMode } from 'utils/ListMode';

class PreferencesState {

    menuOpen: boolean;
    siteListMode: ListMode;
    userListMode: ListMode;

    constructor() {
        this.menuOpen = true;
    }

}

export { PreferencesState };
