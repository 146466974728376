import EnergyKind from 'models/EnergyKind';
import { RemoteSystem } from 'models/sdp/RemoteSystem';

class Metering {
    public remoteSystem?: RemoteSystem;
    public remoteId?: string;
    public remoteIdScde?: string;
    public remoteSystemConfig?: string;
    public useLocalStorage?: boolean;
    public ignoreTouFromStorage?: boolean;
    public firstRegister: Date;
    public endOfBillingPeriodDay?: number;
    public syncStageStart?: Date;
    public syncStageStartScde?: Date;
    public syncStageStorage?: Date;
    public energyKind?: EnergyKind;
}

export { Metering };
