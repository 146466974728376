import MuiSnackbar from 'material-ui/Snackbar';
import * as React from 'react';

interface SnackBarProps {
    message: React.ReactNode;
    open: boolean;
    style?: React.CSSProperties;
    action?: React.ReactNode;
    onActionClick?: (event: any) => void;
    onRequestClose?: (reason: string) => void;
}

interface SnackBarState {}

class SnackBar extends React.Component<SnackBarProps, SnackBarState> {
    render() {
        const { message, open, style, action, onActionClick, onRequestClose } = this.props;
        return (
            <MuiSnackbar
                message={message}
                open={open}
                bodyStyle={style}
                action={action}
                onActionClick={onActionClick}
                onRequestClose={onRequestClose}
            />
        );
    }
}

export default SnackBar;
export { SnackBarProps };
