import { Chip, Tooltip } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import { DashboardActions } from 'actions/Dashboard';
import AutoComplete, { AutoCompleteProps, DataSourceItem } from 'components/interfaces/AutoComplete';
import IconButton from 'components/interfaces/IconButton';
import { themeable, ThemeableProps } from 'components/interfaces/ThemeProvider';
import EnergyKind from 'models/EnergyKind';
import { MeteringType } from 'models/sdp/MeteringTypes';
import { Sdp } from 'models/sdp/Sdp';
import { Site } from 'models/site/Site';
import * as React from 'react';
import { connect } from 'react-redux';
import { generatePath, RouteComponentProps } from 'react-router';
import { NavLink } from 'react-router-dom';
import { Action, Dispatch } from 'redux';
import { AppState } from 'states/App';
import { EntityState } from 'states/Entity';
import { Routes } from 'utils/Routes';
import StringComparers from 'utils/StringComparers';
import StringUtils from 'utils/StringUtils';
import { Nullable } from 'utils/TypeUtils';

interface SiteAutoCompleteContainerProps extends RouteComponentProps<any>, ThemeableProps {
    iconClassName: string;
    className: string;
    inputContainerClassName?: string;
    inputContainerOi?: string;
    styleContainerOi?: React.CSSProperties;
    siteNameForced?: any;
}

function mapStateToProps(
    {
        user,
        sdps,
        sites: sitesAppState
    }: AppState,
    {
        match,
        iconClassName = '',
        muiTheme,
        siteNameForced
    }: SiteAutoCompleteContainerProps
): Partial<AutoCompleteProps> {
    const toDataSourceItem = ({name, id}: Site): DataSourceItem =>
        ({
            text: name,
            value: id
        });

    const compareItemText = (itemA: DataSourceItem, itemB: DataSourceItem) =>
        StringComparers.lexicalCompare(itemA.text, itemB.text);

    const subscription = StringUtils.toHyphenCase(user.subscriptionName);
    const sitesState = sitesAppState ? sitesAppState : new EntityState<Site>();

    const siteId = match.params.id;
    const sites = sitesState.listData();
    const site = sitesState.getData(siteId);
    const siteNameForcedValue = siteNameForced ? siteNameForced.siteName : '';
    const siteName = site ? site.name : siteNameForcedValue;

    const energySdp = getMainEnergySdp(sdps, site);

    const isSCDE = energySdp?.metering.energyKind === EnergyKind.SCDE;

    let dataSource: DataSourceItem[] = [];

    if (sites.length > 0) {
        dataSource = sites.map(toDataSourceItem).sort(compareItemText);
    }

    const configPath = generatePath(`/${subscription}/${Routes.SitesView}`, {
        id: siteId
    });

    const configPathOi = generatePath(`/${subscription}/sites`);

    const SCDEIcon = <InfoIcon style={{ color: muiTheme.palette.accent2Color }} />;
    const SCDEStyle: React.CSSProperties = {
        fontWeight: 'bold',
        fontSize: '0.9em'
    };

    const SCDETooltipStyle: React.CSSProperties = {
        fontSize: '0.8rem'
    };

    const SCDETooltipTitle = (
        <span>
            <p style={SCDETooltipStyle}>Site utiliza dados do SCDE ao invés de telemetria.</p>
            <p style={SCDETooltipStyle}>Os dados dos widgets podem aparecer atrasados pela coleta depender da disponibilização dos dados pela CCEE.</p>
        </span>
    );

    const SCDEIndicator = isSCDE ? (
        <Tooltip
            title={SCDETooltipTitle}
        >
            <Chip
                icon={SCDEIcon}
                style={SCDEStyle}
                label="SCDE"
            />
        </Tooltip>
    ) : null;

    const containerStyle: React.CSSProperties = {
        display: 'flex',
        alignItems: 'center'
    };

    const isSubscriptionOi = subscription === 'oi' || subscription === 'oi-treinamento';

    const leftIcon = (
        <span style={containerStyle}>
            {SCDEIndicator}
            <NavLink to={configPath} tabIndex={-1}>
                <IconButton
                    type={ isSubscriptionOi ? 'cog' : 'map-marker'}
                    tooltip="Configurações do site"
                    tooltipPosition="bottom-center"
                    className={iconClassName}
                    color={muiTheme.palette.accent2Color}
                />
            </NavLink>
        </span>
    );

    const leftIconOi = (
        <span style={containerStyle}>
            <NavLink to={configPathOi} tabIndex={-1}>
                <IconButton
                    type={ isSubscriptionOi ? 'map-marker' : 'cog'}
                    tooltip="Acessar lista de Sites e Grupos"
                    tooltipPosition="bottom-left"
                    className={iconClassName}
                    color={muiTheme.palette.accent2Color}
                />
            </NavLink>
        </span>
    );

    return {
        hintText: 'Ponto de medição',
        searchText: siteName,
        leftIcon,
        dataSource,
        subscription,
        leftIconOi
    };
}

function mapDispatchToProps(
    dispatch: Dispatch<Action, AppState>,
    {
        match,
        location,
        history
    }: SiteAutoCompleteContainerProps
): Partial<AutoCompleteProps> {
    return {
        onSelect(id: string) {
            const selectDashboardSite = DashboardActions.selectSite(id);
            dispatch(selectDashboardSite);

            const siteId = match.params.id;
            const selectedSitePath = location.pathname.replace(
                siteId,
                id
            );
            history.push(selectedSitePath);
        }
    };
}


const getMainEnergySdp = (
    sdps: EntityState<Sdp>,
    site: Nullable<Site>
) => site?.sdps.map(sdpId => sdps.getData(sdpId))
        .find(sdp =>
            sdp?.meteringType === MeteringType.EnergyProvider &&
            sdp?.mainSdp
        );


export default themeable()(connect(
    mapStateToProps,
    mapDispatchToProps
)(AutoComplete));

export { SiteAutoCompleteContainerProps };
