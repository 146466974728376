import { Actions } from 'actions/Actions';
import { MeasurementReport, MeasurementStatistics } from 'models/timeseries/MeasurementReport';
import { Action } from 'redux';
import { DataState } from 'states/Data';
import MeteringToolState from 'states/MeteringToolState';
import { DateTime } from 'utils/DateTime';
import { Measurement } from 'utils/Measurements';
import { TimePeriod } from 'utils/TimePeriods';

class MeteringToolReducer {
    public reduce = (
        previousState = new MeteringToolState(),
        action: Action
    ): MeteringToolState => {
        let nextState: MeteringToolState;

        switch (action.type) {
            case Actions.ReceiveIntraDayDemand:
                nextState = this.setData(
                    Measurement.Demand,
                    TimePeriod.Day,
                    previousState,
                    action
                );
                return nextState;

            case Actions.ReceiveDailyDemand:
                nextState = this.setData(
                    Measurement.Demand,
                    TimePeriod.Month,
                    previousState,
                    action
                );
                return nextState;

            case Actions.ReceiveMonthlyDemand:
                nextState = this.setData(
                    Measurement.Demand,
                    TimePeriod.Year,
                    previousState,
                    action
                );
                return nextState;

            case Actions.ReceiveIntraDayConsumption:
                nextState = this.setData(
                    Measurement.Energy,
                    TimePeriod.Day,
                    previousState,
                    action
                );
                return nextState;

            case Actions.ReceiveDailyConsumption:
                nextState = this.setData(
                    Measurement.Energy,
                    TimePeriod.Month,
                    previousState,
                    action
                );
                return nextState;

            case Actions.ReceiveMonthlyConsumption:
                nextState = this.setData(
                    Measurement.Energy,
                    TimePeriod.Year,
                    previousState,
                    action
                );
                return nextState;

            case Actions.ReceiveIntraDayPowerFactor:
                nextState = this.setData(
                    Measurement.PowerFactor,
                    TimePeriod.Day,
                    previousState,
                    action
                );
                return nextState;

            case Actions.ReceiveDailyPowerFactor:
                nextState = this.setData(
                    Measurement.PowerFactor,
                    TimePeriod.Month,
                    previousState,
                    action
                );
                return nextState;

            case Actions.ReceiveMonthlyPowerFactor:
                nextState = this.setData(
                    Measurement.PowerFactor,
                    TimePeriod.Year,
                    previousState,
                    action
                );
                return nextState;

            case Actions.RequestMeteringToolReport:
                nextState = this.setFetchingReport(previousState, action);
                return nextState;

            case Actions.ReceiveMeteringToolReport:
                nextState = this.setReportData(previousState, action);
                return nextState;

            case Actions.RequestMeteringToolStatistics:
                nextState = this.setFetchingStatistics(previousState, action);
                return nextState;

            case Actions.ReceiveMeteringToolStatistics:
                nextState = this.setStatisticsData(previousState, action);
                return nextState;

            default:
                return previousState;
        }
    };

    private setFetchingData(
        previousState: MeteringToolState,
        action: any
    ): MeteringToolState {
        const { sdpId, measurement, timePeriod, startDate, endDate } = action;

        const data = { ...previousState.data };

        data[sdpId] = {
            ...data[sdpId],
            measurement,
            timePeriod,
            isFetching: true,
            startDate: startDate ? (startDate as DateTime) : undefined,
            endDate: endDate ? (endDate as DateTime) : undefined
        };

        return { ...previousState, data };
    }

    private setData(
        measurement: Measurement,
        timePeriod: TimePeriod,
        previousState: MeteringToolState,
        action: any
    ): MeteringToolState {
        const { sdpId, startDate, endDate } = action;

        const data = { ...previousState.data };

        if (!data[sdpId]) {
            return previousState;
        }

        const isSameMeasurement = data[sdpId].measurement === measurement;
        const isSamePeriod = data[sdpId].timePeriod === timePeriod;
        const isSameDate =
            data[sdpId].startDate?.isSame(startDate) &&
            data[sdpId].endDate?.isSame(endDate);
        const isRequestedData = isSameMeasurement && isSamePeriod && isSameDate;

        data[sdpId] = {
            ...data[sdpId],
            isFetching: false,
            data: action.data,
            lastUpdated: new DateTime()
        };

        return isRequestedData ? { ...previousState, data } : previousState;
    }

    private setFetchingReport(
        previousState: MeteringToolState,
        action: any
    ): MeteringToolState {
        const { sdpId, startDate, endDate } = action;

        const report: DataState<MeasurementReport> = {
            ...previousState.report,
            isFetching: true,
            startDate,
            endDate
        };

        return { ...previousState, sdpId, report };
    }

    private setReportData(
        previousState: MeteringToolState,
        action: any
    ): MeteringToolState {
        const { sdpId, startDate, endDate } = action;

        const isSameSdp = previousState.sdpId === sdpId;

        const isSameDate =
            previousState.report.startDate?.isSame(startDate) &&
            previousState.report.endDate?.isSame(endDate);

        const isSameRequest = isSameSdp && isSameDate;

        const report: DataState<MeasurementReport> = {
            ...previousState.report,
            isFetching: false,
            data: action.data,
            lastUpdated: new DateTime()
        };

        return isSameRequest ? { ...previousState, report } : previousState;
    }

    private setFetchingStatistics(
        previousState: MeteringToolState,
        action: any
    ): MeteringToolState {
        const { sdpId } = action;

        const statistics: DataState<MeasurementStatistics> = {
            ...previousState.statistics,
            isFetching: true
        };

        return { ...previousState, sdpId, statistics };
    }

    private setStatisticsData(
        previousState: MeteringToolState,
        action: any
    ): MeteringToolState {
        const { sdpId } = action;

        const isSameSdp = previousState.sdpId === sdpId;

        const statistics: DataState<MeasurementStatistics> = {
            ...previousState.statistics,
            isFetching: false,
            data: action.data,
            lastUpdated: new DateTime()
        };

        return isSameSdp ? { ...previousState, statistics } : previousState;
    }
}

export { MeteringToolReducer };
