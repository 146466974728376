import { Actions } from 'actions/Actions';
import NotificationsActions from 'actions/NotificationsActions';
import { ServerAction } from 'actions/ServerAction';
import { CurrentUser } from 'models/system/CurrentUser';
import { Subscription, SubscriptionSettings } from 'models/system/Subscription';
import { Action, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { AppState } from 'states/App';
import { ApiClient } from 'utils/ApiClient';
import { LoginRequest } from 'utils/LoginRequest';
import StringUtils from 'utils/StringUtils';
import { isNonNullable, noop } from 'utils/TypeUtils';

interface UserAction extends Action {
    subscriptionId?: number | null;
    subscriptionName?: string | null;
    settings?: SubscriptionSettings | null;
    user?: CurrentUser;
    token?: string;
}

class UserActions extends ServerAction {

    public static logIn(request: LoginRequest): ThunkAction<Promise<any>, AppState, any> {
        return (dispatch: Dispatch<Action, AppState>, getState: () => AppState) => {
            const appState = getState();

            const action = UserActions.requestLogIn();
            dispatch(action);

            const validateSubdomain = (user: CurrentUser) => {
                const {subscriptionName} = user;
                const host = location.hostname;
                const [subdomain] = host.toLowerCase().split('.');
                const newSubdomains = process.env.NEW_SUBDOMAIN?.toLowerCase().split(',') || undefined;

                const generalSubdomains = [
                    'powerhub',
                    'www',
                    'pre',
                    'localhost',
                    'ph-consumer-frontend-dev',
                    'ph-consumer-frontend-qa',
                    'ph-consumer-frontend-legacy-qa',
                    'ph-consumer-frontend-legacy-dev',
                    'ph-consumer-frontend-legacy-prod',
                    'ph-consumer-front-legacy-dev',
                    'ph-consumer-front-legacy-qa',
                    'powerhub-legacy-qa',
                    'powerhub-legacy-dev'
                ];

                if (newSubdomains) {
                    newSubdomains.map(newSubdomain => generalSubdomains.push(newSubdomain.trim()));
                }

                const isSpecificSubdomain = !generalSubdomains.includes(subdomain);
                const userHasSubscription = isNonNullable(subscriptionName);

                if (isSpecificSubdomain && userHasSubscription) {
                    const simplifiedSubscriptionName = subscriptionName.replace(/[\s-]/g, '').toLowerCase();
                    const unaccentedSubscriptionName = StringUtils.removeAccents(simplifiedSubscriptionName);

                    const isValidSubdomain = subdomain.startsWith(unaccentedSubscriptionName);

                    if (!isValidSubdomain) {
                        return Promise.reject();
                    }
                }

                return Promise.resolve(user);
            };

            const dispatchLogIn = (user: CurrentUser) => {
                const receiveAction = UserActions.receiveLogIn(user);
                dispatch(receiveAction);
            };

            const dispatchError = () => {
                const errorMessage = 'Erro ao realizar login.';
                const notificationAction = NotificationsActions.notifyError(errorMessage);
                dispatch(notificationAction);
                const failAction = UserActions.failLogin();
                dispatch(failAction);
            };

            return new ApiClient(appState, dispatch)
                .logIn(request)
                .then(this.handleResponse)
                .then(this.transformToJson)
                .then(validateSubdomain)
                .then(dispatchLogIn)
                .catch(dispatchError);
        };
    }

    public static tokenLogIn(token: string): ThunkAction<void, AppState, any> {
        return (dispatch: Dispatch<Action, AppState>, getState: () => AppState) => {
            const appState = getState();

            const action = this.requestLogIn();
            dispatch(action);

            const dispatchLogIn = (user: CurrentUser) => {
                user.token = token;
                const receiveAction = UserActions.receiveLogIn(user);
                dispatch(receiveAction);
            };

            const dispatchError = () => {
                const errorMessage = 'Erro ao realizar login com token.';
                const notificationAction = NotificationsActions.notifyError(errorMessage);
                dispatch(notificationAction);
                const failAction = UserActions.failLogin();
                dispatch(failAction);
            };

            new ApiClient(appState, dispatch, token)
                .getUserSelf()
                .then(this.handleResponse)
                .then(this.transformToJson)
                .then(dispatchLogIn)
                .catch(dispatchError);
        };
    }

    public static getUserSelf(): ThunkAction<void, AppState, any> {
        return (dispatch: Dispatch<Action, AppState>, getState: () => AppState) => {
            const appState = getState();

            const action = this.requestUserSelf();
            dispatch(action);

            const dispatchUserSelf = (user: CurrentUser) => {
                const receiveAction = UserActions.receiveUserSelf(user);
                dispatch(receiveAction);
            };

            new ApiClient(appState, dispatch)
                .getUserSelf()
                .then(this.handleResponse)
                .then(this.transformToJson)
                .then(dispatchUserSelf)
                .catch(noop);
        };
    }

    public static setSubscription(subscription: Subscription): UserAction {
        return {
            type: Actions.SetUserSubscription,
            subscriptionId: subscription.id,
            subscriptionName: subscription.name,
            settings: subscription.settings
        };
    }

    public static setToken(token: string): UserAction {
        return {
            type: Actions.SetUserToken,
            token
        };
    }

    public static logOut(): Action {
        return { type: Actions.LogOut };
    }

    private static requestUserSelf(): Action {
        return {
            type: Actions.RequestUserSelf
        };
    }

    private static receiveUserSelf(user: CurrentUser): UserAction {
        return {
            type: Actions.ReceiveUserSelf,
            user
        };
    }

    private static requestLogIn(): Action {
        return {
            type: Actions.RequestLogIn
        };
    }

    private static receiveLogIn(user: CurrentUser): UserAction {
        return {
            type: Actions.ReceiveLogIn,
            user
        };
    }

    private static failLogin(): UserAction {
        return {
            type: Actions.FailLogIn
        };
    }

}

export { UserActions };
