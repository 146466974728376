import { PreferencesActions } from 'actions/Preferences';
import MenuToggle, { MenuToggleProps } from 'components/base/MenuToggle';
import { connect } from 'react-redux';
import { Action, Dispatch } from 'redux';
import { AppState } from 'states/App';

function mapDispatchToProps(dispatch: Dispatch<Action, AppState>): MenuToggleProps {
    return {
        onClick(): void {
            const toggleMenu = PreferencesActions.toggleMenu();
            dispatch(toggleMenu);
        }
    };
}

export default connect(
    null,
    mapDispatchToProps
)(MenuToggle);
