import IconButton from 'components/interfaces/IconButton';
import { themeable, ThemeableProps } from 'components/interfaces/ThemeProvider';
import * as React from 'react';
import * as styles from 'styles/components/base/MenuToggle.module.scss';

interface MenuToggleProps {
    onClick?: React.MouseEventHandler<any>;
}

type ThemeableMenuToggleProps = MenuToggleProps & ThemeableProps;

const MenuToggle: React.StatelessComponent<ThemeableMenuToggleProps> = (props) => (
    <IconButton
        type="bars"
        className={styles.menuIconButton}
        color={props.muiTheme.palette.primary1Color}
        hoverColor={props.muiTheme.palette.accent2Color}
        onClick={props.onClick}
    />
);

export default themeable()(MenuToggle);
export { MenuToggleProps };
