import 'moment/locale/pt-br';

import * as moment from 'moment';
import { isNullable, Nullable } from 'utils/TypeUtils';

const defaultMask = 'YYYY-MM-DDTHH:mm:ssZ';
moment.updateLocale('pt-BR', undefined);

class DateTime {
    private moment: moment.Moment;

    public constructor(date?: any, format?: any) {
        if (!date && !format) {
            this.moment = moment();
        } else if (date instanceof Date) {
            this.moment = moment(date);
        } else if (!format) {
            this.moment = moment(date, defaultMask);
        } else {
            this.moment = moment(date, format);
        }
    }

    public static weekdaysNames(): string[] {
        return moment.weekdays();
    }

    public static weekdaysNamesShort(index?: number): string[] | string {
        if (index === undefined) {
            return moment.weekdaysShort();
        }

        return moment.weekdaysShort(index);
    }

    public static months(): string[] {
        return moment.months();
    }

    public static isDateTime(object: any): boolean {
        return typeof object === 'object' && object instanceof DateTime;
    }

    public static isDateTimeType(object: any): object is DateTime {
        return DateTime.isDateTime(object);
    }

    public static sameDate(dateA: DateTime, dateB: DateTime) {
        return (
            (DateTime.isDateTime(dateA) && dateA.isSameDay(dateB)) ||
            (!DateTime.isDateTime(dateA) &&
                new DateTime(dateA.moment).isSameDay(dateB))
        );
    }

    public addSeconds(seconds: number): DateTime {
        this.moment.add(seconds, 'seconds');
        return this;
    }

    public addMinutes(minutes: number): DateTime {
        this.moment.add(minutes, 'minutes');
        return this;
    }

    public addHours(hours: number): DateTime {
        this.moment.add(hours, 'hours');
        return this;
    }

    public addDays(days: number): DateTime {
        this.moment.add(days, 'days');
        return this;
    }

    public addWeeks(weeks: number): DateTime {
        this.moment.add(weeks, 'weeks');
        return this;
    }

    public addMonths(months: number): DateTime {
        this.moment.add(months, 'months');
        return this;
    }

    public addYears(years: number): DateTime {
        this.moment.add(years, 'years');
        return this;
    }

    public compareTo(dateTime: DateTime): 1 | -1 | 0 {
        if (!dateTime) {
            return 1;
        }
        if (this.isSame(dateTime)) {
            return 0;
        }
        if (this.isBefore(dateTime)) {
            return -1;
        }
        return 1;
    }

    public difference(dateTime: DateTime): number {
        if (!dateTime) {
            return Number.MAX_SAFE_INTEGER;
        }
        return this.moment.diff(dateTime.moment);
    }

    public differenceInMinutes(dateTime: DateTime): number {
        if (!dateTime) {
            return Number.MAX_SAFE_INTEGER;
        }
        return this.moment.diff(dateTime.moment, 'minutes');
    }

    public differenceInDays(dateTime: DateTime): number {
        if (!dateTime) {
            return Number.MAX_SAFE_INTEGER;
        }
        return this.moment.diff(dateTime.moment, 'days');
    }

    public differenceInWeeks(dateTime: DateTime): number {
        if (!dateTime) {
            return Number.MAX_SAFE_INTEGER;
        }
        return this.moment.diff(dateTime.moment, 'weeks');
    }

    public differenceInMonths(dateTime: DateTime): number {
        if (!dateTime) {
            return Number.MAX_SAFE_INTEGER;
        }
        return this.moment.diff(dateTime.moment, 'months');
    }

    public format(format: string = defaultMask): string {
        return this.moment.format(format);
    }

    public fromNow() {
        return this.moment.fromNow();
    }

    public from(dateStart: DateTime) {
        return this.moment.from(dateStart.moment);
    }

    public getYear(): number {
        return this.moment.year();
    }

    public getMonth(): number {
        return this.moment.month();
    }

    public getDay(): number {
        return this.moment.date();
    }

    public getHour(): number {
        return this.moment.hour();
    }

    public getMinutes(): number {
        return this.moment.minutes();
    }

    public getDaysInMonth(): number {
        return this.moment.daysInMonth();
    }

    public isAfter(dateTime: DateTime): boolean {
        if (!dateTime) {
            return false;
        }
        return this.moment.isAfter(dateTime.moment);
    }

    public isSameOrAfter(dateTime: DateTime): boolean {
        if (!dateTime) {
            return false;
        }
        return this.moment.isSameOrAfter(dateTime.moment);
    }

    public isAfterDay(day: any): boolean {
        const dateTimeClone = this.clone();
        const targetDay = dateTimeClone.setDay(day);
        return targetDay.isBefore(this);
    }

    public isBefore(dateTime: DateTime): boolean {
        if (!dateTime) {
            return false;
        }
        return this.moment.isBefore(dateTime.moment);
    }

    public isBeforeDay(day: number): boolean {
        const dateTimeClone = this.clone();
        const targetDay = dateTimeClone.setDay(day);
        return this.isBefore(targetDay);
    }

    public isSame(dateTime: Nullable<DateTime>): boolean {
        if (isNullable(dateTime)) {
            return false;
        }
        return this.moment.isSame(dateTime.moment);
    }

    public isSameDay(dateTime: Nullable<DateTime>): boolean {
        if (isNullable(dateTime)) {
            return false;
        }
        return (
            this.isSameMonth(dateTime) && this.getDay() === dateTime.getDay()
        );
    }

    public isSameMonth(dateTime: Nullable<DateTime>): boolean {
        if (isNullable(dateTime)) {
            return false;
        }
        return (
            this.isSameYear(dateTime) && this.getMonth() === dateTime.getMonth()
        );
    }

    public isSameYear(dateTime: Nullable<DateTime>): boolean {
        if (isNullable(dateTime)) {
            return false;
        }
        return this.getYear() === dateTime.getYear();
    }

    public isToday(): boolean {
        return this.startOfDay()
            .clone()
            .isSame(new DateTime().startOfDay());
    };

    public isYesterday(): boolean {
        return this.startOfDay()
            .clone()
            .isSame(new DateTime().subtractDays(1).startOfDay());
    };

    public isDifferent(dateTime: DateTime): boolean {
        return !this.isSame(dateTime);
    }

    public endOfDay(): DateTime {
        this.moment.endOf('day');
        return this;
    }

    public endOfWeek(): DateTime {
        this.moment.endOf('week');
        return this;
    }

    public endOfMonth(): DateTime {
        this.moment.endOf('month');
        return this;
    }

    public endOfYear(): DateTime {
        this.moment.endOf('year');
        return this;
    }

    public setDay(day: number): DateTime {
        this.moment.date(day);
        return this;
    }

    public startOfDay(): DateTime {
        this.moment.startOf('day');
        return this;
    }

    public startOfWeek(): DateTime {
        this.moment.startOf('week');
        return this;
    }

    public setMonth(month: number | string): DateTime {
        this.moment.month(month);
        return this;
    }

    public startOfMonth(): DateTime {
        this.moment.startOf('month');
        return this;
    }

    public startOfYear(): DateTime {
        this.moment.startOf('year');
        return this;
    }

    public startOfHour(): DateTime {
        this.moment.startOf('hour');
        return this;
    }

    public setYear(year: number): DateTime {
        this.moment.year(year);
        return this;
    }

    public setHour(hour: number): DateTime {
        this.moment.hour(hour);
        return this;
    }

    public setMinutes(minutes: number): DateTime {
        this.moment.minutes(minutes);
        return this;
    }

    public subtractDays(days: number): DateTime {
        this.moment.subtract(days, 'days');
        return this;
    }

    public subtractMinutes(minutes: number): DateTime {
        this.moment.subtract(minutes, 'minutes');
        return this;
    }

    public subtractMonths(months: number): DateTime {
        this.moment.subtract(months, 'months');
        return this;
    }

    public subtractYears(years: number): DateTime {
        this.moment.subtract(years, 'years');
        return this;
    }

    public toDate(): Date {
        return this.moment.toDate();
    }

    public toISOString(): string {
        return this.moment.toISOString();
    }

    public removeUTCOffset(): DateTime {
        this.moment.utcOffset(0, true);
        return this;
    }

    public isValid(): boolean {
        return this.moment.isValid();
    }

    public equalsTo(other: any): boolean {
        return this.format(defaultMask) === other.format(defaultMask);
    }

    public clone(): DateTime {
        const newDateTime = new DateTime();
        newDateTime.moment = this.moment.clone();

        return newDateTime;
    }

    public weekNumber(): number {
        return this.moment.week();
    }

    public getIsoWeekday(): number {
        return this.moment.isoWeekday();
    }

    public isWeekend(): boolean {
        return [6, 7].includes(this.moment.isoWeekday());
    }

    public countWeekendsDays(): number {
        const startMonth = this.clone().startOfMonth();
        const endMonth = this.clone().endOfMonth();

        const saturdayIso = 6;
        const sundayIso = 7;

        const countSaturdays = this.countDayOccurrencesBetweenDates(
            startMonth,
            endMonth,
            saturdayIso
        );
        const countSundays = this.countDayOccurrencesBetweenDates(
            startMonth,
            endMonth,
            sundayIso
        );

        return countSaturdays + countSundays;
    }

    private countDayOccurrencesBetweenDates(
        startDate: DateTime,
        endDate: DateTime,
        isoWeekday: number
    ) {
        const daysToAdd = (7 + isoWeekday - startDate.getIsoWeekday()) % 7;
        const nextDay = startDate.clone().addDays(daysToAdd);

        if (nextDay.isAfter(endDate)) {
            return 0;
        }

        const weeksBetween = endDate.differenceInWeeks(nextDay);
        return weeksBetween + 1;
    }
}

export { DateTime };
