import gql from 'graphql-tag';

const GetNewExperienceToggle = gql`
  query NewExperienceIsActivated{
    users{
      userActivatedNewExperienceUse
    }
  }
`;

export default GetNewExperienceToggle;