import { Actions } from 'actions/Actions';
import { DashboardActions } from 'actions/Dashboard';
import NotificationsActions from 'actions/NotificationsActions';
import { SdpActions } from 'actions/Sdp';
import { ServerAction } from 'actions/ServerAction';
import { Metering } from 'models/sdp/Metering';
import { Sdp, SdpCreate } from 'models/sdp/Sdp';
import PagedList from 'models/server/PagedList';
import { Site, SiteCreate, SiteEdit } from 'models/site/Site';
import { Roles } from 'models/system/Roles';
import { Action, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { AppState } from 'states/App';
import { DataState } from 'states/Data';
import { ApiClient } from 'utils/ApiClient';

class SiteAction extends ServerAction {
    public static list(): ThunkAction<Promise<any>, AppState, any> {
        return (dispatch: Dispatch<Action, AppState>, getState: () => AppState) => {
            const appState = getState();

            const action = this.requestSites();
            dispatch(action);

            const selectSiteIfNotSelected = (sites: PagedList<Site>) => {
                const { dashboard } = appState;
                const dashboardId = dashboard.id ?? '';

                const { items } = sites;

                const hasSites = items?.length > 0;
                const hasSiteId = hasSites ? this.hasSiteSelected(items, dashboardId) : false;

                if (hasSites && !hasSiteId) {
                    const site = this.getFirstSiteWithSdp(items);
                    if (site) {
                        const siteAction = DashboardActions.selectSite(site.id);
                        dispatch(siteAction);
                    }
                }

                return sites;
            };

            const dispatchSdps = (sites: PagedList<Site>) => {
                const siteAction = SdpActions.receiveFromSites(sites.items);
                dispatch(siteAction);
                return sites;
            };

            const dispatchSites = (response: PagedList<Site>) => {
                const { total, items } = response;

                const sitesDataStates = items.map(this.toDataState);
                const sitesResponse = {
                    total,
                    items: sitesDataStates
                };

                const siteAction = this.receiveSites(sitesResponse);
                dispatch(siteAction);
            };

            const dispatchError = (error: Error) => {
                const notificationAction = NotificationsActions.notifyError(error.message);
                dispatch(notificationAction);
            };

            return new ApiClient(appState, dispatch)
                .listSites()
                .then(this.transformToJson)
                .then(response => this.handleServerResponse<PagedList<Site>>(response))
                .then(selectSiteIfNotSelected)
                .then(dispatchSdps)
                .then(dispatchSites)
                .catch(dispatchError);
        };
    }

    public static listPagination(
        searchString: string,
        index: number,
        size: number,
        orderByField: string,
        sortDirection: string
    ): ThunkAction<Promise<void>, AppState, void> {
        return (dispatch: Dispatch<Action, AppState>, getState: () => AppState) => {
            const appState = getState();

            const action = this.requestSites();
            dispatch(action);

            const selectSiteIfNotSelected = (sites: PagedList<Site>) => {
                const { dashboard } = appState;
                const dashboardId = dashboard.id ?? '';

                const { items } = sites;

                const hasSites = items?.length > 0;
                const hasSiteId = hasSites ? this.hasSiteSelected(items, dashboardId) : false;

                if (hasSites && !hasSiteId) {
                    const site = this.getFirstSiteWithSdp(items);
                    if (site) {
                        const siteAction = DashboardActions.selectSite(site.id);
                        dispatch(siteAction);
                    }
                }

                return sites;
            };

            const dispatchSdps = (sites: PagedList<Site>) => {
                const siteAction = SdpActions.receiveFromSites(sites.items);
                dispatch(siteAction);
                return sites;
            };

            const dispatchSites = (response: PagedList<Site>) => {
                const { total, items, pageIndex, pageSize } = response;

                const sitesDataStates = items.map(this.toDataState);
                const sitesResponse = {
                    total,
                    items: sitesDataStates,
                    pageSize,
                    pageIndex
                };

                const siteAction = this.receiveSites(sitesResponse);
                dispatch(siteAction);
            };

            const dispatchError = (error: Error) => {
                const notificationAction = NotificationsActions.notifyError(error.message);
                dispatch(notificationAction);
            };

            return new ApiClient(appState, dispatch)
                .listSitesPagination(searchString, size, index, orderByField, sortDirection)
                .then(this.transformToJson)
                .then(response => this.handleServerResponse<PagedList<Site>>(response))
                .then(selectSiteIfNotSelected)
                .then(dispatchSdps)
                .then(dispatchSites)
                .catch(dispatchError);
        };
    }

    public static get(id: string): ThunkAction<Promise<any>, AppState, any> {
        return (dispatch: Dispatch<Action, AppState>, getState: () => AppState) => {
            const appState = getState();

            const action = SiteAction.requestSite(id);
            dispatch(action);

            const dispatchSdps = (site: Site) => {
                const siteAction = SdpActions.receiveFromSites([site]);
                dispatch(siteAction);
                return site;
            };

            const dispatchSite = (site: Site) => {
                const siteAction = SiteAction.receiveSite(id, site);
                dispatch(siteAction);
            };

            return new ApiClient(appState, dispatch)
                .getSite(id)
                .then(this.transformToJson)
                .then(dispatchSdps)
                .then(dispatchSite);
        };
    }

    public static create(
        site: SiteCreate,
        sdpCreate: SdpCreate,
        metering: Metering
    ): ThunkAction<Promise<Site | null>, AppState, any> {
        return (dispatch: Dispatch<Action, AppState>, getState: () => AppState) => {
            const appState = getState();
            const apiClient = new ApiClient(appState, dispatch);
            const { role } = appState.user;

            const action = this.requestCreateSite();
            dispatch(action);

            const dispatchError = (error: Error): null => {
                let errorMessage = 'Erro ao criar o site.';
                if (role === Roles.GlobalAdmin) {
                    errorMessage += `\n${error}`;
                }

                const notificationAction = NotificationsActions.notifyError(errorMessage);
                dispatch(notificationAction);

                return null;
            };

            const createSdp = (siteCreate: Site) => {
                sdpCreate.siteId = siteCreate.id;

                const updateSdpMetering = (sdp: Sdp) => {
                    const dispatchSdp = () => {
                        sdp.metering = metering;
                        siteCreate.sdps = [sdp];

                        const siteAction = SdpActions.receiveFromSites([siteCreate]);
                        dispatch(siteAction);
                    };

                    const dispatchSite = () => {
                        const state = new DataState<Site>(siteCreate);

                        const successMessage = 'Site criado com sucesso.';
                        const notificationAction = NotificationsActions.notifySuccess(successMessage);
                        dispatch(notificationAction);

                        const siteAction = this.receiveCreateSite(
                            siteCreate.id,
                            state
                        );
                        dispatch(siteAction);

                        return siteCreate;
                    };

                    return apiClient
                        .editSdpMetering(sdp.id, metering)
                        .then(dispatchSdp)
                        .then(dispatchSite);
                };

                return apiClient
                    .createSdp(sdpCreate)
                    .then(this.transformToJson)
                    .then(updateSdpMetering);
            };

            return apiClient
                .createSite(site)
                .then(this.transformToJson)
                .then(createSdp)
                .catch(dispatchError);
        };
    }

    public static edit(
        id: string,
        site: SiteEdit
    ): ThunkAction<Promise<any>, AppState, void> {
        return (dispatch: Dispatch<Action, AppState>, getState: () => AppState) => {
            const appState = getState();
            const { role } = appState.user;

            const action = this.requestEditSite(id, site);
            dispatch(action);

            const dispatchEditSite = (siteEdit: Site) => {
                siteEdit.sdps = siteEdit.sdps.map((sdp) => sdp.id);

                const successMessage = 'Site salvo com sucesso.';
                const notificationAction = NotificationsActions.notifySuccess(
                    successMessage
                );
                dispatch(notificationAction);

                const siteAction = this.receiveEditSite(id, siteEdit);
                dispatch(siteAction);
            };

            const dispatchError = (error: Error) => {
                let errorMessage = 'Erro ao salvar o site.';

                if (role === Roles.GlobalAdmin) {
                    errorMessage += `\n${error}`;
                }

                const notificationAction = NotificationsActions.notifyError(errorMessage);
                dispatch(notificationAction);
            };

            return new ApiClient(appState, dispatch)
                .editSite(id, site)
                .then(this.transformToJson)
                .then(dispatchEditSite)
                .catch(dispatchError);
        };
    }

    public static delete(
        id: string
    ): ThunkAction<Promise<any>, AppState, void> {
        return (dispatch: Dispatch<Action, AppState>, getState: () => AppState) => {
            const appState = getState();
            const site = appState.sites.getData(id) as Site;

            const action = this.requestDeleteSite(id);
            dispatch(action);

            const dispatchDeleteSite = () => {
                const siteAction = this.receiveDeleteSite(id);
                dispatch(siteAction);
            };

            const dispatchDeleteSdps = () => {
                const dispatchDeleteSdp = (sdpId: string) => {
                    const siteAction = this.receiveDeleteSdp(sdpId);
                    dispatch(siteAction);
                };

                site.sdps.forEach(dispatchDeleteSdp);
            };

            return new ApiClient(appState, dispatch)
                .deleteSite(id)
                .then(dispatchDeleteSite)
                .then(dispatchDeleteSdps);
        };
    }

    private static getFirstSiteWithSdp(sites: Site[]): Site | null {
        for (const site of sites) {
            if (site.sdps.length > 0) {
                return site;
            }
        }

        return null;
    }

    private static hasSiteSelected(sites: Site[], siteId: string): boolean {
        for (const site of sites) {
            if (site.id === siteId) {
                return true;
            }
        }
        return false;
    }

    private static requestSites() {
        return { type: Actions.RequestSites };
    }

    private static receiveSites(data: PagedList<DataState<Site>>) {
        return { type: Actions.ReceiveSites, data };
    }

    private static requestSite(id: string) {
        return { type: Actions.RequestSite, id };
    }

    private static receiveSite(id: string, data: Site) {
        return { type: Actions.ReceiveSite, id, data };
    }

    private static requestCreateSite() {
        return { type: Actions.RequestCreateSite };
    }

    private static receiveCreateSite(id: string, state: DataState<Site>) {
        return { type: Actions.ReceiveCreateSite, id, state };
    }

    private static requestEditSite(id: string, data: SiteEdit) {
        return { type: Actions.RequestEditSite, id, data };
    }

    private static receiveEditSite(id: string, data: Site) {
        return { type: Actions.ReceiveEditSite, id, data };
    }

    private static requestDeleteSite(id: string) {
        return { type: Actions.RequestDeleteSite, id };
    }

    private static receiveDeleteSite(id: string) {
        return { type: Actions.ReceiveDeleteSite, id };
    }

    private static receiveDeleteSdp(id: string) {
        return { type: Actions.ReceiveDeleteSdp, id };
    }
}

export { SiteAction };
