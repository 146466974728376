import { UserActions } from 'actions/UserActions';
import { Router, RouterProps } from 'components/base/Router';
import { connect } from 'react-redux';
import { Action, Dispatch } from 'redux';
import { AppState } from 'states/App';
import StringUtils from 'utils/StringUtils';

function mapStateToProps(appState: AppState, ownProps: {toggleStateGraphql: boolean}): Partial<RouterProps> {
    const { user } = appState;
    const {toggleStateGraphql} = ownProps;
    const { subscriptionName, subscriptionId } = user;

    const subscription = subscriptionName ?
        StringUtils.toHyphenCase(subscriptionName) :
        null;

    return { user, subscription, subscriptionId, toggleStateGraphql };
}

function mapDispatchToProps(dispatch: Dispatch<Action, AppState>): Partial<RouterProps> {
    return {
        onLogout(): void {
            const logOut = UserActions.logOut();
            dispatch(logOut);
        }
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Router) as any;
