import { Actions } from 'actions/Actions';
import { ForecastMeasurements } from 'models/aggregatedMeasurements/ForecastMeasurements';
import { EntityAction, EntityReducer } from 'reducers/Entity';
import { EntityState } from 'states/Entity';

class ForecastMeasurementsReducer {

    private entityReducer = new EntityReducer<ForecastMeasurements[]>({
        requestGet: Actions.RequestForecastMeasurements,
        receiveGet: Actions.ReceiveForecastMeasurements
    });

    public reduce = (
        previousState = new EntityState<ForecastMeasurements[]>(),
        action: EntityAction<ForecastMeasurements[]>
    ): EntityState<ForecastMeasurements[]> => {
        switch (action.type) {
            case Actions.RequestForecastMeasurements:
            case Actions.ReceiveForecastMeasurements:
                return this.entityReducer.reduce(previousState, action);
            default:
                return previousState;
        }
    };
}

export default ForecastMeasurementsReducer;
