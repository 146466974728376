import { Actions } from 'actions/Actions';
import { NotificationsAction } from 'actions/NotificationsActions';
import NotificationsState from 'states/NotificationsState';

class NotificationReducer {
    reduce = (previousState = new NotificationsState(), action: NotificationsAction): NotificationsState => {
        let nextState: NotificationsState;
        switch (action.type) {
            case Actions.AddNotification:
                nextState = this.setNotification(previousState, action);
                return nextState;

            case Actions.RemoveLastNotification:
                nextState = this.removeLastNotification(previousState, action);
                return nextState;

            default: return previousState;
        }
    }

    private setNotification(previousState: NotificationsState, action: NotificationsAction): NotificationsState {
        const { notifications } = previousState;
        return {
            notifications:  [action.notification, ...(notifications || [])]
        };
    }

    private removeLastNotification(previousState: NotificationsState, action: NotificationsAction): NotificationsState {
        const { notifications } = previousState;
        return {
            notifications:  notifications.slice(0, -1)
        };
    }
}

export default NotificationReducer;
