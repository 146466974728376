import { SelectFieldOption } from 'components/interfaces/SelectField';
import MeasurementUnit from 'models/MeasurementUnit';
import { isNonNullable } from 'utils/TypeUtils';

enum MeteringType {
    EnergyProvider = 1,
    SubMetering = 2,
    Water = 3,
    Gas = 4,
    Oil = 5
}

export type SecondaryMeteringTypes = MeteringType.Gas | MeteringType.Water | MeteringType.Oil;

const getMeteringTypeName = (type: MeteringType) => {
    switch (type) {
        case MeteringType.EnergyProvider:
            return 'Energia';
        case MeteringType.Water:
            return 'Água';
        case MeteringType.SubMetering:
            return 'Submedição';
        case MeteringType.Gas:
            return 'Gás';
        case MeteringType.Oil:
            return 'Diesel';
        default:
            console.error(`Metering Type not recognized: ${type}`);
            return null;
    }
};

const getEnergyMeteringTypeName = (type: MeteringType) => {
    switch (type) {
        case MeteringType.EnergyProvider:
            return 'Principal';
        case MeteringType.SubMetering:
            return 'Submedição';
        default:
            console.error(`Energy Metering Type not recognized: ${type}`);
            return null;
    }
};

const meteringTypeSelectFieldOptions: SelectFieldOption[] = [
    MeteringType.EnergyProvider,
    MeteringType.Water,
    MeteringType.Gas,
    MeteringType.Oil
].map(meteringType => ({
    text: getMeteringTypeName(meteringType),
    value: meteringType
})
).filter(({ text }) => isNonNullable(text));

const energyMeteringTypeSelectFieldOptions: SelectFieldOption[] = [
    MeteringType.EnergyProvider,
    MeteringType.SubMetering
].map(energyMeteringType => ({
    text: getEnergyMeteringTypeName(energyMeteringType),
    value: energyMeteringType
})
).filter(({ text }) => isNonNullable(text));

const getVolumeUnitFromSecondaryMeteringTypes = (meteringType: SecondaryMeteringTypes) =>
    meteringType === MeteringType.Oil ? MeasurementUnit.Liters : MeasurementUnit.CubicMeters;

export {
    getMeteringTypeName,
    MeteringType,
    meteringTypeSelectFieldOptions,
    energyMeteringTypeSelectFieldOptions,
    getVolumeUnitFromSecondaryMeteringTypes
};
