import MuiDrawer from 'material-ui/Drawer';
import * as React from 'react';

export interface DrawerProps {
    className: string;
    containerClassName?: string;
    mobile: boolean;
    open: boolean;
    onRequestChange?: (open: boolean, reason: string) => void;
    style?: React.CSSProperties;
}

const Drawer: React.FunctionComponent<DrawerProps> = ({
    className,
    containerClassName = '',
    mobile,
    open,
    onRequestChange,
    children
}) => (
    <MuiDrawer
        containerClassName={className}
        className={`container-${className} ${containerClassName}`}
        docked={!mobile}
        disableSwipeToOpen={!mobile}
        open={!mobile || open}
        onRequestChange={onRequestChange}
    >
        {children}
    </MuiDrawer>
);

export { Drawer };
