import { EnergyProvider } from 'models/EnergyProvider';
import { Metering } from 'models/sdp/Metering';
import { MeteringType } from 'models/sdp/MeteringTypes';
import { SuppliedVoltageType } from 'models/SuppliedVoltageType';

class Sdp {
    public id: string;
    public name: string;
    public meteringType: MeteringType;
    public producesEnergy: boolean;
    public siteName: string;
    public siteId: string;
    public metering: Metering;
    public energyProvider: EnergyProvider;
    public suppliedVoltageTypeName?: SuppliedVoltageType;
    public ke: number;
    public uc: string;
    public mainSdp: boolean;

    public constructor() {
        this.metering = new Metering();
    }
}

interface SdpCreate {
    name: string;
    siteId?: string;
    remoteId: string;
    meteringType: MeteringType;
    producesEnergy: boolean;
    ke?: number;
    energyProviderId?: number;
    suppliedVoltageTypeName?: SuppliedVoltageType;
    uc?: string;
    mainSdp?: boolean;
}

interface SdpEdit {
    name?: string;
    meteringType?: MeteringType;
    producesEnergy?: boolean;
    remoteId?: string;
    ke?: number;
    energyProviderId?: number;
    suppliedVoltageTypeName?: SuppliedVoltageType;
    uc?: string;
    mainSdp?: boolean;
}

export { Sdp, SdpCreate, SdpEdit };
