import { Actions } from 'actions/Actions';
import { PreferencesAction } from 'actions/Preferences';
import { PreferencesState } from 'states/Preferences';

class PreferencesReducer {

    public reduce = (previousState = new PreferencesState(), action: PreferencesAction): PreferencesState => {
        let nextState;
        switch (action.type) {
            case Actions.ToggleMenu:
                nextState = this.toggleMenu(previousState);
                return nextState;
            case Actions.ChangeSiteListMode:
                nextState = this.changeSiteListMode(previousState, action);
                return nextState;
            case Actions.ChangeUserListMode:
                nextState = this.changeUserListMode(previousState, action);
                return nextState;
            case Actions.LogOut:
                nextState = this.eraseSession();
                return nextState;
            default:
                return previousState;
        }
    };

    private toggleMenu(prevState: PreferencesState): PreferencesState {
        return {
            ...prevState,
            menuOpen: !prevState.menuOpen
        };
    }

    private changeSiteListMode(prevState: PreferencesState, action: PreferencesAction): PreferencesState {
        return {
            ...prevState,
            siteListMode: action.listMode
        };
    }

    private changeUserListMode(prevState: PreferencesState, action: PreferencesAction): PreferencesState {
        return {
            ...prevState,
            userListMode: action.listMode
        };
    }

    private eraseSession(): PreferencesState {
        return new PreferencesState();
    }

}

export { PreferencesReducer };
