import { Actions } from 'actions/Actions';
import AlarmConfig from 'models/alarms/AlarmConfigModel';
import Alarm from 'models/alarms/AlarmModel';
import { EnergyBill } from 'models/bills/EnergyBill';
import { ContractTemplate } from 'models/ContractTemplate';
import { DashboardTemplate } from 'models/DashboardOptions';
import { DEVEC } from 'models/DEVEC';
import { EnergyProvider } from 'models/EnergyProvider';
import { EnergyProviderTax } from 'models/EnergyProviderTax';
import EnergyTariffFlag from 'models/EnergyTariffFlagModel';
import ConsumptionGoal from 'models/sdp/ConsumptionGoal';
import { DemandContractState } from 'models/sdp/DemandContract';
import { EnergyContract } from 'models/sdp/EnergyContract';
import { Sdp } from 'models/sdp/Sdp';
import SitesGroup from 'models/site/SitesGroup';
import { SiteStateTax } from 'models/SiteStateTax';
import { StateTax } from 'models/StateTax';
import { Subscription } from 'models/system/Subscription';
import { User } from 'models/system/User';
import AggregatedMeasurementsReducer from 'reducers/AggregatedMeasurementsReducer';
import ComparisonReducer from 'reducers/ComparisonReducer';
import { ConfigReducer } from 'reducers/Config';
import CustomizableDashboardReducer from 'reducers/CustomizableDashboardReducer';
import { DashboardReducer } from 'reducers/Dashboard';
import { DataReducer } from 'reducers/Data';
import EnergyBillAlertsReducer from 'reducers/EnergyBillAlertsReducer';
import EnergyProviderTariffsReducer from 'reducers/EnergyProviderTariffsReducer';
import { EntityReducer } from 'reducers/Entity';
import { ExpensesToolReducer } from 'reducers/ExpensesToolReducer';
import ForecastMeasurementsReducer from 'reducers/ForecastMeasurementsReducer';
import { MeteringToolReducer } from 'reducers/MeteringTool';
import NotificationReducer from 'reducers/NotificationsReducer';
import { PasswordReducer } from 'reducers/Password';
import { PreferencesReducer } from 'reducers/Preferences';
import { SitesReducer } from 'reducers/Sites';
import { UserReducer } from 'reducers/UserReducer';
import { combineReducers, Reducer } from 'redux';
import { AppState } from 'states/App';

type Reducers<T> = { [P in keyof T]: Reducer<T[P]> };

const configReducer = new ConfigReducer();

const contractTemplatesReducer = new EntityReducer<ContractTemplate>({
    requestList: Actions.RequestContractTemplates,
    receiveList: Actions.ReceiveContractTemplates
});

const energyProvidersReducer = new DataReducer<EnergyProvider[]>({
    request: Actions.RequestEnergyProviders,
    receive: Actions.ReceiveEnergyProviders
});

const subscriptionBillsReducer = new DataReducer<EnergyBill[]>({
    request: Actions.RequestAllEnergyBills,
    receive: Actions.ReceiveAllEnergyBills
});

const forecastMeasurementsReducer = new ForecastMeasurementsReducer();

const aggregatedMeasurementsReducer = new AggregatedMeasurementsReducer();

const consumptionGoalReducer = new EntityReducer<ConsumptionGoal>({
    requestList: Actions.RequestConsumptionGoals,
    receiveList: Actions.ReceiveConsumptionGoals,
    requestCreate: Actions.RequestCreateConsumptionGoal,
    receiveCreate: Actions.ReceiveCreateConsumptionGoal,
    failCreate: Actions.FailCreateConsumptionGoal,
    requestDelete: Actions.RequestDeleteConsumptionGoal,
    receiveDelete: Actions.ReceiveDeleteConsumptionGoal,
    requestEdit: Actions.RequestEditConsumptionGoal,
    receiveEdit: Actions.ReceiveEditConsumptionGoal
});

const energyContractReducer = new EntityReducer<EnergyContract>({
    requestList: Actions.RequestEnergyContracts,
    receiveList: Actions.ReceiveEnergyContracts,
    requestCreate: Actions.RequestCreateEnergyContract,
    receiveCreate: Actions.ReceiveCreateEnergyContract,
    failCreate: Actions.FailCreateEnergyContract,
    requestDelete: Actions.RequestDeleteEnergyContract,
    receiveDelete: Actions.ReceiveDeleteEnergyContract,
    requestEdit: Actions.RequestEditEnergyContract,
    receiveEdit: Actions.ReceiveEditEnergyContract
});

const dashboardReducer = new DashboardReducer();
const expensesToolReducer = new ExpensesToolReducer();
const meteringToolReducer = new MeteringToolReducer();
const preferencesReducer = new PreferencesReducer();
const passwordReducer = new PasswordReducer();

const sdpsReducer = new EntityReducer<Sdp>({
    requestCreate: Actions.RequestCreateSdp,
    receiveCreate: Actions.ReceiveCreateSdp,
    requestGet: Actions.RequestSdp,
    receiveGet: Actions.ReceiveSdp,
    requestList: Actions.RequestSdps,
    receiveList: Actions.ReceiveSdps,
    requestEdit: Actions.RequestEditSdp,
    receiveEdit: Actions.ReceiveEditSdp,
    requestDelete: Actions.RequestDeleteSdp,
    receiveDelete: Actions.ReceiveDeleteSdp
});

const sitesReducer = new SitesReducer();

const subscriptionReducer = new EntityReducer<Subscription>({
    requestList: Actions.RequestSubscriptions,
    receiveList: Actions.ReceiveSubscriptions
});

const userReducer = new UserReducer();

const usersReducer = new EntityReducer<User>({
    requestList: Actions.RequestUsers,
    receiveList: Actions.ReceiveUsers,
    requestGet: Actions.RequestUser,
    receiveGet: Actions.ReceiveUser,
    requestCreate: Actions.RequestCreateUser,
    receiveCreate: Actions.ReceiveCreateUser,
    failCreate: Actions.FailCreateUser,
    requestEdit: Actions.RequestEditUser,
    receiveEdit: Actions.ReceiveEditUser,
    requestDelete: Actions.RequestDeleteUser,
    receiveDelete: Actions.ReceiveDeleteUser
});

const notificationReducer = new NotificationReducer();

const stateTaxesReducer = new EntityReducer<StateTax>({
    requestList: Actions.RequestStateTaxes,
    receiveList: Actions.ReceiveStateTaxes,
    requestCreate: Actions.RequestCreateStateTax,
    receiveCreate: Actions.ReceiveCreateStateTax,
    requestDelete: Actions.RequestDeleteStateTax,
    receiveDelete: Actions.ReceiveDeleteStateTax
});

const energyProviderTaxesReducer = new EntityReducer<EnergyProviderTax>({
    requestList: Actions.RequestEnergyProviderTaxes,
    receiveList: Actions.ReceiveEnergyProviderTaxes,
    requestCreate: Actions.RequestCreateEnergyProviderTax,
    receiveCreate: Actions.ReceiveCreateEnergyProviderTax,
    requestDelete: Actions.RequestDeleteEnergyProviderTax,
    receiveDelete: Actions.ReceiveDeleteEnergyProviderTax
});

const energyProviderTariffsReducer = new EnergyProviderTariffsReducer();

const energyBillAlertsReducer = new EnergyBillAlertsReducer();

const alarmReducer = new DataReducer<Alarm[]>({
    request: Actions.RequestAlarms,
    receive: Actions.ReceiveAlarms
});

const alarmConfigsReducer = new EntityReducer<AlarmConfig>({
    requestList: Actions.RequestAlarmsConfigs,
    receiveList: Actions.ReceiveAlarmsConfigs,
    requestGet: Actions.RequestAlarmsConfig,
    receiveGet: Actions.ReceiveAlarmsConfig,
    requestCreate: Actions.RequestCreateAlarmsConfig,
    receiveCreate: Actions.ReceiveCreateAlarmsConfig,
    requestEdit: Actions.RequestEditAlarmsConfig,
    receiveEdit: Actions.ReceiveEditAlarmsConfig,
    requestDelete: Actions.RequestDeleteAlarmsConfig,
    receiveDelete: Actions.ReceiveDeleteAlarmsConfig
});

const energyTariffFlagsReducer = new EntityReducer<EnergyTariffFlag>({
    requestList: Actions.RequestEnergyTariffFlags,
    receiveListAdditive: Actions.ReceiveEnergyTariffFlags
});

const sitesGroupsReducer = new EntityReducer<SitesGroup>({
    requestList: Actions.RequestSitesGroups,
    receiveList: Actions.ReceiveSitesGroups,
    requestGet: Actions.RequestSitesGroup,
    receiveGet: Actions.ReceiveSitesGroup,
    requestCreate: Actions.RequestCreateSitesGroup,
    receiveCreate: Actions.ReceiveCreateSitesGroup,
    requestEdit: Actions.RequestEditSitesGroup,
    receiveEdit: Actions.ReceiveEditSitesGroup,
    requestDelete: Actions.RequestDeleteSitesGroup,
    receiveDelete: Actions.ReceiveDeleteSitesGroup
});

const dashboardTemplatesReducer = new EntityReducer<DashboardTemplate>({
    requestList: Actions.RequestDashboardTemplates,
    receiveList: Actions.ReceiveDashboardTemplates,
    requestGet: Actions.RequestDashboardTemplate,
    receiveGet: Actions.ReceiveDashboardTemplate,
    requestCreate: Actions.RequestCreateDashboardTemplate,
    receiveCreate: Actions.ReceiveCreateDashboardTemplate,
    requestEdit: Actions.RequestEditDashboardTemplate,
    receiveEdit: Actions.ReceiveEditDashboardTemplate,
    requestDelete: Actions.RequestDeleteDashboardTemplate,
    receiveDelete: Actions.ReceiveDeleteDashboardTemplate
});

const customizableDashboardReducer = new CustomizableDashboardReducer();

const comparisonReducer = new ComparisonReducer();

const demandContractsReducer = new EntityReducer<DemandContractState>({
    requestList: Actions.RequestDemandContracts,
    receiveList: Actions.ReceiveDemandContracts,
    requestCreate: Actions.RequestCreateDemandContract,
    receiveCreate: Actions.ReceiveCreateDemandContract,
    failCreate: Actions.FailCreateDemandContract,
    requestDelete: Actions.RequestDeleteDemandContract,
    receiveDelete: Actions.ReceiveDeleteDemandContract
});

const siteStateTaxesReducer = new EntityReducer<SiteStateTax>({
    requestList: Actions.RequestSiteStateTaxes,
    receiveList: Actions.ReceiveSiteStateTaxes,
    requestCreate: Actions.RequestSiteCreateStateTax,
    receiveCreate: Actions.ReceiveSiteCreateStateTax,
    requestDelete: Actions.RequestSiteDeleteStateTax,
    receiveDelete: Actions.ReceiveSiteDeleteStateTax
});

const siteDEVECTaxesReducer = new EntityReducer<DEVEC>({
    requestList: Actions.RequestSiteDEVECTaxes,
    receiveList: Actions.ReceiveSiteDEVECTaxes,
    requestEdit: Actions.RequestEditSiteDEVECTax,
    receiveEdit: Actions.ReceiveEditSiteDEVECTax
});

const reducers: Reducers<AppState> = {
    config: configReducer.reduce,
    consumptionGoals: consumptionGoalReducer.reduce,
    contractTemplates: contractTemplatesReducer.reduce,
    dashboard: dashboardReducer.reduce,
    demandContracts: demandContractsReducer.reduce,
    energyContracts: energyContractReducer.reduce,
    energyBillAlerts: energyBillAlertsReducer.reduce,
    energyProviders: energyProvidersReducer.reduce,
    forecastMeasurements: forecastMeasurementsReducer.reduce,
    energyProvidersTariffs: energyProviderTariffsReducer.reduce,
    energyProviderTaxes: energyProviderTaxesReducer.reduce,
    energyTariffFlags: energyTariffFlagsReducer.reduce,
    expensesTool: expensesToolReducer.reduce,
    meteringTool: meteringToolReducer.reduce,
    notifications: notificationReducer.reduce,
    password: passwordReducer.reduce,
    preferences: preferencesReducer.reduce,
    sdps: sdpsReducer.reduce,
    sites: sitesReducer.reduce,
    siteStateTaxes: siteStateTaxesReducer.reduce,
    siteDEVECTaxes: siteDEVECTaxesReducer.reduce,
    stateTaxes: stateTaxesReducer.reduce,
    subscriptionBills: subscriptionBillsReducer.reduce,
    subscriptions: subscriptionReducer.reduce,
    user: userReducer.reduce,
    users: usersReducer.reduce,
    alarms: alarmReducer.reduce,
    alarmConfigs: alarmConfigsReducer.reduce,
    sitesGroups: sitesGroupsReducer.reduce,
    dashboardTemplates: dashboardTemplatesReducer.reduce,
    customizableDashboard: customizableDashboardReducer.reduce,
    comparison: comparisonReducer.reduce,
    aggregatedMeasurements: aggregatedMeasurementsReducer.reduce
};

export default combineReducers<AppState>(reducers);
