import { Actions } from 'actions/Actions';
import NotificationsActions from 'actions/NotificationsActions';
import { ServerAction } from 'actions/ServerAction';
import { Metering } from 'models/sdp/Metering';
import { Sdp, SdpCreate, SdpEdit } from 'models/sdp/Sdp';
import PagedList from 'models/server/PagedList';
import { Site } from 'models/site/Site';
import { Roles } from 'models/system/Roles';
import { Action, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { AppState } from 'states/App';
import { DataState } from 'states/Data';
import { ApiClient } from 'utils/ApiClient';

class SdpActions extends ServerAction {
    public static receiveFromSites(
        sites: Site[] = []
    ): ThunkAction<void, AppState, void> {
        return (dispatch: Dispatch<Action, AppState>, _getState: () => AppState) => {
            const requestAction = this.requestSdps();
            dispatch(requestAction);

            let sdpsState: DataState<Sdp>[] = [];

            sites.forEach(site => {
                const states = site.sdps.map(sdp => new DataState<Sdp>(sdp));
                sdpsState = sdpsState.concat(states);

                const mainSdp = site.sdps.find(({ mainSdp: main }) => main);
                site.mainSdpMeteringType = mainSdp?.meteringType;
                site.sdps = site.sdps.map((sdp: Sdp) => sdp.id);
            });

            const sdps = {
                items: sdpsState,
                total: sdpsState.length
            };

            const action = this.receiveSdps(sdps);
            return dispatch(action);
        };
    }

    public static list(): ThunkAction<Promise<any>, AppState, any> {
        return (dispatch: Dispatch<Action, AppState>, getState: () => AppState) => {
            const appState = getState();

            const action = this.requestSdps();
            dispatch(action);

            const dispatchSdps = (sdps: PagedList<Sdp>) => {
                const sdpStates = sdps.items.map(
                    sdp => new DataState<Sdp>(sdp)
                );
                const pagedSpdStates: PagedList<DataState<Sdp>> = {
                    total: sdps.total,
                    items: sdpStates
                };

                const sdpAction = this.receiveSdps(pagedSpdStates);
                dispatch(sdpAction);
            };

            return new ApiClient(appState, dispatch)
                .listSdps()
                .then(this.transformToJson)
                .then(dispatchSdps);
        };
    }

    public static get(id: string): ThunkAction<Promise<any>, AppState, any> {
        return (dispatch: Dispatch<Action, AppState>, getState: () => AppState) => {
            const appState = getState();

            const action = this.requestSdp(id);
            dispatch(action);

            const dispatchSdp = (sdp: Sdp) => {
                const sdpAction = this.receiveSdp(sdp.id, sdp);
                dispatch(sdpAction);
            };

            return new ApiClient(appState, dispatch)
                .getSdp(id)
                .then(this.transformToJson)
                .then(dispatchSdp);
        };
    }

    public static create(
        sdpCreate: SdpCreate,
        metering: Metering
    ): ThunkAction<Promise<any>, AppState, any> {
        return (dispatch: Dispatch<Action, AppState>, getState: () => AppState) => {
            const appState = getState();
            const apiClient = new ApiClient(appState, dispatch);
            const role = appState.user.role;

            const dispatchError = (error: Error) => {
                let errorMessage = 'Erro ao criar o ponto de medição.';
                if (role === Roles.GlobalAdmin) {
                    errorMessage += `\n${error}`;
                }

                const notificationAction = NotificationsActions.notifyError(
                    errorMessage
                );
                dispatch(notificationAction);
            };

            const requestCreateSdp = this.requestCreateSdp();
            dispatch(requestCreateSdp);

            const updateSdpMetering = (sdp: Sdp) => {
                const dispatchReceiveSdp = () => {
                    sdp.metering = metering;
                    const state = new DataState<Sdp>(sdp);

                    const successMessage =
                        'Ponto de medição criado com sucesso.';
                    const notificationAction = NotificationsActions.notifySuccess(
                        successMessage
                    );
                    dispatch(notificationAction);

                    const sdpAction = this.receiveCreateSdp(sdp.id, state);
                    dispatch(sdpAction);
                };

                return apiClient
                    .editSdpMetering(sdp.id, metering)
                    .then(dispatchReceiveSdp)
                    .then(() => sdp);
            };

            return apiClient
                .createSdp(sdpCreate)
                .then(this.transformToJson)
                .then(updateSdpMetering)
                .catch(dispatchError);
        };
    }

    public static edit(
        id: string,
        sdpEdit: SdpEdit
    ): ThunkAction<Promise<any>, AppState, any> {
        return (dispatch: Dispatch<Action, AppState>, getState: () => AppState) => {
            const appState = getState();
            const role = appState.user.role;

            const action = this.requestEditSdp(id, sdpEdit);
            dispatch(action);

            const dispatchEditSdp = (sdp: Sdp) => {
                const successMessage = 'Ponto de medição salvo com sucesso.';
                const notificationAction = NotificationsActions.notifySuccess(
                    successMessage
                );
                dispatch(notificationAction);

                const sdpAction = this.receiveEditSdp(id, sdp);
                dispatch(sdpAction);
            };

            const dispatchError = (error: Error) => {
                let errorMessage = 'Erro ao salvar o ponto de medição.';
                if (role === Roles.GlobalAdmin) {
                    errorMessage += `\n${error}`;
                }

                const notificationAction = NotificationsActions.notifyError(
                    errorMessage
                );
                dispatch(notificationAction);
            };

            return new ApiClient(appState, dispatch)
                .editSdp(id, sdpEdit)
                .then(this.transformToJson)
                .then(dispatchEditSdp)
                .catch(dispatchError);
        };
    }

    public static editMetering(
        id: string,
        metering: Metering
    ): ThunkAction<void, AppState, any> {
        return (dispatch: Dispatch<Action, AppState>, getState: () => AppState) => {
            const appState = getState();
            const sdp = appState.sdps.getData(id) || new Sdp();

            sdp.metering = {
                ...sdp.metering,
                ...metering
            };

            const action = this.requestEditSdp(id, sdp);
            dispatch(action);

            const dispatchEditSdpMetering = () => {
                const sdpAction = this.receiveEditSdp(id, sdp);
                dispatch(sdpAction);
            };

            new ApiClient(appState, dispatch)
                .editSdpMetering(id, metering)
                .then(dispatchEditSdpMetering);
        };
    }

    public static delete(
        sdpId: string
    ): ThunkAction<Promise<any>, AppState, any> {
        return (dispatch: Dispatch<Action, AppState>, getState: () => AppState) => {
            const appState = getState();

            const action = this.requestDeleteSdp(sdpId);
            dispatch(action);

            const dispatchDeleteSdp = () => {
                const sdpAction = this.receiveDeleteSdp(sdpId);
                dispatch(sdpAction);
            };

            return new ApiClient(appState, dispatch)
                .deleteSdp(sdpId)
                .then(dispatchDeleteSdp);
        };
    }

    private static requestSdp(id: string) {
        return {
            type: Actions.RequestSdp,
            id
        };
    }

    private static receiveSdp(id: string, data: any) {
        return {
            type: Actions.ReceiveSdp,
            id,
            sdpId: id,
            data
        };
    }

    private static requestSdps() {
        return {
            type: Actions.RequestSdps
        };
    }

    private static receiveSdps(data: any) {
        return {
            type: Actions.ReceiveSdps,
            data
        };
    }

    private static requestCreateSdp() {
        return {
            type: Actions.RequestCreateSdp
        };
    }

    private static receiveCreateSdp(id: string, state: DataState<Sdp>) {
        return {
            type: Actions.ReceiveCreateSdp,
            id,
            state
        };
    }

    private static requestEditSdp(id: string, data: SdpEdit) {
        return {
            type: Actions.RequestEditSdp,
            id,
            data
        };
    }

    private static receiveEditSdp(id: string, data: Sdp) {
        return {
            type: Actions.ReceiveEditSdp,
            id,
            data
        };
    }

    private static receiveDeleteSdp(id: string) {
        return {
            type: Actions.ReceiveDeleteSdp,
            id
        };
    }

    private static requestDeleteSdp(id: string) {
        return {
            type: Actions.RequestDeleteSdp,
            id
        };
    }
}

export { SdpActions };
