import ErrorBoundary from 'components/utils/ErrorBoundary';
import { LinearProgress } from 'material-ui';
import { getRoleValue, Roles } from 'models/system/Roles';
import * as React from 'react';
import { Redirect, Route, RouteComponentProps } from 'react-router-dom';
import { UserState } from 'states/User';
import { Routes } from 'utils/Routes';

const { withLastLocation } = require('react-router-last-location');

interface PrivateRouteProps {
    path?: string | string[];
    user: UserState;
    subscription: string;
    isAuthenticated: boolean;
    requiredRole: Roles;
    component: React.ComponentType<any | RouteComponentProps<any>>;
    hasSubscription?: any;
    location?: any;
    lastLocation?: any;
    token?: string;
    subscriptionId?: string;
    onLogout?: () => any;
    toggleStateGraphql: boolean;
}

class PrivateRoute extends React.Component<PrivateRouteProps> {
    public state = {
        isSubAllowed: false,
        toggleState: false
    };


    public componentDidMount() {
        this.fetchSubBasicInfo();
        this.fetchUserToggle();
    }

    public componentDidUpdate(prevProps: PrivateRouteProps) {
        if (this.props.toggleStateGraphql !== prevProps.toggleStateGraphql) {
            this.fetchUserToggle();
        }
    }

    public render(): React.ReactNode {
        const { path, isAuthenticated, user, hasSubscription, location, token, subscriptionId, onLogout, toggleStateGraphql } = this.props;
        const { isSubAllowed, toggleState } = this.state;

        const handleToggle = toggleStateGraphql || toggleState;
        const isGlobalAdmin = user.role === Roles.GlobalAdmin;

        const baseNextUrl = process.env.BASE_URL_NEXT_POWERHUB || '';

        const isAmbientAllowed = process.env.FLUX_TRANSITION_ALLOWED === 'true';

        const pathSplit: string[] = location.pathname.toString().split('/');
        const siteSlug = pathSplit[pathSplit.length - 1];

        const loginRoute = {
            pathname: `/${Routes.Login}`,
            state: { from: location }
        };

        const subscriptionRoute = {
            pathname: `/${Routes.Subscriptions}`,
            state: { from: location }
        };

        if (!isAuthenticated) {
            return <Redirect to={loginRoute} />;
        }

        if (!hasSubscription && path !== subscriptionRoute.pathname) {
            return <Redirect to={subscriptionRoute} />;
        }

        if (handleToggle && subscriptionId && isSubAllowed && isAmbientAllowed && !isGlobalAdmin) {
            const routes = [
                { path: 'sites', redirect: `sites?tab=Sites&subId=${subscriptionId}&toggleFlux=${handleToggle}` },
                { path: 'grupos', redirect: `sites?tab=Sites&subId=${subscriptionId}&toggleFlux=${handleToggle}` },
                { path: 'visao-geral', redirect: `visao-geral&subId=${subscriptionId}&toggleFlux=${handleToggle}` },
                { path: 'dashboard', redirect: `dashboard/${siteSlug}&subId=${subscriptionId}&toggleFlux=${handleToggle}` },
                { path: 'faturas', redirect: `faturas/${siteSlug}&subId=${subscriptionId}&toggleFlux=${handleToggle}` },
                { path: 'multas', redirect: `multas&subId=${subscriptionId}&toggleFlux=${handleToggle}` },
                { path: 'auditoria', redirect: `auditoria&subId=${subscriptionId}&toggleFlux=${handleToggle}` },
                { path: 'exportacao', redirect: `exportacao&subId=${subscriptionId}&toggleFlux=${handleToggle}` },
                { path: 'calendario', redirect: `calendario/${siteSlug}&subId=${subscriptionId}&toggleFlux=${handleToggle}` },
                { path: 'alarmes', redirect: `alarmes&subId=${subscriptionId}&toggleFlux=${handleToggle}` },
                { path: 'potenciais-de-ganho', redirect: `potenciais-ganhos&subId=${subscriptionId}&toggleFlux=${handleToggle}` },
                { path: 'usuarios', redirect: `usuarios&subId=${subscriptionId}&toggleFlux=${handleToggle}` },
                { path: 'sobre', redirect: `sobre&subId=${subscriptionId}&toggleFlux=${handleToggle}` }
              ];

              const matchingRoute = routes.find(route => path && (path.includes(route.path) || path[0].includes(route.path) || path[1].includes(route.path)));

              if (matchingRoute && onLogout) {
                const handleRedirect = () => {
                    setTimeout(() => {
                        onLogout();
                    }, 100);
                    return <LinearProgress />;
                };

                const redirectUrl = `${baseNextUrl}/login?token=${token}&redirect=${matchingRoute.redirect}`;
                window.open(redirectUrl, '_self');
                return handleRedirect();
              }
        }

        return <Route exact={true} path={path} render={this.renderComponent} />;
    }

    private renderComponent = (props: any) => {
        const {
            user,
            requiredRole,
            component: Component,
            lastLocation,
            subscription
        } = this.props;

        const { history } = props;

        if (getRoleValue(requiredRole) > getRoleValue(user.role)) {
            if (lastLocation) {
                history.replace(lastLocation.pathname);
                return null;
            }
            return <Redirect to={`/${subscription}/${Routes.SitesList}`} />;
        }

        return (
            <ErrorBoundary>
                <Component {...props} />
            </ErrorBoundary>
        );
    };

    private fetchSubBasicInfo = async () => {
        const { token, user } = this.props;

        const response = await fetch(`${process.env.BASE_URL}/api/${user.subscriptionId}/subscriptions/basic-info`, {
            method: 'GET',
            mode: 'cors',
            headers: {
                Authorization: `Bearer ${token}`,
                'Accept': 'application/json',
                'api-version': '2'
            }
        });

        const {isNewExperiencieAllowed} = await response.json();
        this.setState({ isSubAllowed: isNewExperiencieAllowed ?? false });
    };

    private fetchUserToggle = async () => {
        const { token } = this.props;

        const response = await fetch(`${process.env.BASE_URL}/api/users/me/new-experience/is-active`, {
            method: 'GET',
            mode: 'cors',
            headers: {
                Authorization: `Bearer ${token}`,
                'Accept': 'application/json',
                'api-version': '2'
            }
        });

        const toggleState = await response.json();
        this.setState({ toggleState: toggleState ?? false });
    };
}

export default withLastLocation(PrivateRoute);
export { PrivateRouteProps };
