import { Palette } from 'components/interfaces/ThemeProvider';
import {
    amber300,
    amber500,
    amberA700,
    blueGrey300,
    blueGrey50,
    blueGrey500,
    blueGrey600,
    blueGrey700,
    darkBlack,
    fullBlack,
    white
} from 'material-ui/styles/colors';
import { fade } from 'material-ui/utils/colorManipulator';

const fallbackPalette: Palette = {
    primary1Color: blueGrey500,
    primary2Color: blueGrey600,
    primary3Color: blueGrey700,
    primary4Color: blueGrey300,
    accent1Color: amber500,
    accent2Color: amberA700,
    accent3Color: amber300,
    textColor: darkBlack,
    secondaryTextColor: '#757575',
    alternateTextColor: white,
    alternateMenuTextColor: white,
    canvasColor: white,
    borderColor: '#BDBDBD',
    disabledColor: fade(darkBlack, 0.3),
    pickerHeaderColor: blueGrey500,
    clockCircleColor: fade(darkBlack, 0.07),
    shadowColor: fullBlack,
    topBarColor: blueGrey50
};

export { fallbackPalette };
